import React, { useEffect, useState } from 'react';
import { Strings } from '../constants/Strings';
import { Colors } from '../constants/Colors';
import { makeStyles } from 'tss-react/mui';
import { QrReader } from 'react-qr-reader';
import { Typography, CircularProgress } from '@mui/material';

interface ScannerProps {
  onCompleteScan: (result: any) => void;
  isLoading: boolean;
  isError?: boolean;
}

const Scanner = ({ onCompleteScan, isLoading, isError }: ScannerProps) => {
  const { classes, cx } = useStyles();
  const [scanResult, setScanResult] = useState<string | null>(null);
  const [sleep, setSleep] = useState<boolean>(false);

  const videoConstraints = {
    facingMode: 'environment',
    aspectRatio: 1,
  };

  useEffect(() => {
    if (scanResult) {
      onCompleteScan(scanResult);
    }
  }, [scanResult]);

  const validateScan = (result: any) => {
    const text = result?.text as string;
    if (text && !scanResult && !sleep) {
      setSleep(true);
      setScanResult(text);
    }
  };

  useEffect(() => {
    if (isError) {
      setTimeout(() => resetState(), 6000);
    }
  }, [isError]);

  const resetState = () => {
    setSleep(false);
    setScanResult(null);
  };

  useEffect(() => {
    resetState();
    return () => {
      resetState();
    };
  }, []);

  return (
    <>
      {isLoading ||
        (sleep && (
          <div className={classes.circularProgressContainer}>
            <CircularProgress />
          </div>
        ))}

      <QrReader
        onResult={validateScan}
        scanDelay={500}
        containerStyle={{
          height: window.innerHeight - 40,
        }}
        videoContainerStyle={{
          height: window.innerHeight - 40,
          paddingTop: 0,
        }}
        videoStyle={{
          objectFit: 'cover',
        }}
        //@ts-ignore
        constraints={videoConstraints}
      />
      <div className={classes.backdropContainer}>
        <div className={classes.blackTransparent} />
        <div
          className={cx(
            classes.blackTransparent,
            classes.bgTransparent,
            classes.opacity08,
            classes.blackCenterContainer
          )}
        >
          <div className={classes.blackCenteredItem} />
          <div className={cx(classes.blackCenteredItem, classes.widthCenter, classes.bgTransparent, classes.border)} />
          <div className={classes.blackCenteredItem} />
        </div>
        <div className={cx(classes.blackTransparent, classes.bottomBlack)} />
      </div>
      <Typography className={classes.alignTextQrCode}>{Strings.alignQrCodeScan}</Typography>
    </>
  );
};

export default Scanner;

const useStyles = makeStyles()({
  backdropContainer: {
    position: 'absolute',
    top: 70,
    height: window.innerHeight - 40,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  blackTransparent: {
    backgroundColor: Colors.black,
    opacity: 0.4,
    display: 'flex',
    height: '15vh',
    width: '100%',
  },
  opacity08: {
    opacity: 0.8,
  },
  bgTransparent: {
    backgroundColor: 'transparent',
  },
  widthCenter: {
    width: '60%',
  },
  blackCenterContainer: {
    height: '35vh',
  },
  blackCenteredItem: {
    backgroundColor: Colors.black,
    width: '20%',
    opacity: 0.5,
    display: 'flex',
    flexDirection: 'row',
  },
  bottomBlack: {
    height: '44vh',
    '@media (min-height: 896px)': {
      height: '46vh',
    },
    '@media (min-height: 844px)': {
      height: '46vh',
    },
  },
  border: {
    borderWidth: '2px',
    borderStyle: 'dashed',
    borderColor: Colors.white,
  },
  alignTextQrCode: {
    color: Colors.white,
    position: 'absolute',
    bottom: 80,
    textAlign: 'center',
    fontSize: 13,
    padding: '0 45px',
  },
  circularProgressContainer: {
    position: 'absolute',
    top: window.innerHeight / 2 - 90,
    zIndex: 999,
    left: window.innerWidth / 2 - 20,
  },
});
