import React from 'react';
import { Button, Typography } from '@mui/material';
import { ReactComponent as PlusIcon } from '../assets/images/plus-icon.svg';
import { makeStyles } from 'tss-react/mui';
import { Colors } from '../constants/Colors';
import { Strings } from '../constants/Strings';

interface PrimaryButtonProps {
  buttonTitle: string;
  buttonStartIcon: any;
  containerStyle?: string;
  buttonTitleStyle?: string;
  disabled?: boolean;
  onClick: () => void;
}

const PrimaryButton = ({
  buttonTitle,
  buttonStartIcon,
  containerStyle,
  buttonTitleStyle,
  disabled,
  onClick,
}: PrimaryButtonProps) => {
  const { classes, cx } = useStyles();
  return (
    <Button
      variant="contained"
      disabled={disabled}
      startIcon={buttonStartIcon ? buttonStartIcon : <PlusIcon className={classes.plusicon} />}
      className={cx(classes.container, containerStyle, disabled && classes.disabled)}
      onClick={onClick}
    >
      <Typography className={cx(classes.buttonTitle, buttonTitleStyle)}>{buttonTitle}</Typography>
    </Button>
  );
};

PrimaryButton.defaultProps = {
  buttonTitle: Strings.pressHereToAddPass,
  buttonStartIcon: false,
};

const useStyles = makeStyles()({
  container: {
    backgroundColor: `${Colors.lightRed} !important`,
    padding: '0 25px',
    height: 40,
  },
  buttonTitle: {
    fontSize: 14,
  },
  plusicon: {
    width: 30,
    height: 20,
  },
  disabled: {
    backgroundColor: `${Colors.lightGrey} !important`,
    color: 'white'
  }
});

export default PrimaryButton;
