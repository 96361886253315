import React, { useState } from 'react';
import { InfoWindow, Marker, MarkerProps } from '@react-google-maps/api';
import { Button, Grid, Typography } from '@mui/material';
import '../cssFiles/InfoWindowCss.css';
import { makeStyles } from 'tss-react/mui';
import { Colors } from '../constants/Colors';
import customMarker from '../assets/images/customMarker/customMarker.png';
import { usefulLinks } from '../constants/UsefulLinks';
import PrimaryButton from './PrimaryButton';
import { Strings } from '../constants/Strings';

interface InfoWindowMarkerProps {
  imageUrl: string;
  title: string;
  description: string;
  website: string;
}

const InfoWindowMarker = (props: MarkerProps & InfoWindowMarkerProps) => {
  const { title, imageUrl, description, position, website } = props;
  const [infoWindowOpen, setInfoWindowOpen] = useState<boolean>(false);
  const { classes } = useStyles();

  return (
    <Marker onClick={() => setInfoWindowOpen(!infoWindowOpen)} {...props} icon={customMarker}>
      {infoWindowOpen && (
        <InfoWindow onCloseClick={() => setInfoWindowOpen(false)}>
          <div role="presentation" className={classes.container}>
            <img src={imageUrl} alt={'location'} className={classes.image} />
            <div className={classes.textContainer}>
              <Typography variant={'body1'}>{title}</Typography>
              <Typography className={classes.description} variant={'body1'}>
                {description}
              </Typography>
            </div>
            <Grid container className={classes.actionWindow}>
              <Grid item xs={8}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    window.open(website, '_blank', 'noreferrer');
                  }}
                  className={classes.moreInformationButton}
                  disabled={!website}
                >
                  {Strings.moreInformation}
                </Button>
              </Grid>
              <Grid item xs={4}>
                <PrimaryButton
                  onClick={() => {
                    window.open(`${usefulLinks.googleMaps}?saddr=My+Location&daddr=${position.lat},${position.lng}`);
                  }}
                  containerStyle={classes.routeButton}
                  buttonTitle={'Route'}
                  buttonStartIcon={true}
                />
              </Grid>
            </Grid>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
};

const useStyles = makeStyles()({
  routeButton: {
    height: 36,
  },
  moreInformationButton: {
    color: Colors.lightRed,
    border: `1px solid ${Colors.lightRed} !important`,
    borderRadius: 0,
    minWidth: 135,
  },
  actionWindow: {
    display: 'flex',
    padding: '0 10px',
    justifyContent: 'space-between',
    marginBottom: 15,
    '.MuiGrid-root': {
      justifyContent: 'flex-start',
      display: 'flex',
      '&:first-child': {
        '.MuiButtonBase-root': {
          padding: 0,
        },
      },
    },
  },
  container: {
    border: `1px solid ${Colors.gray94}`,
  },
  description: {
    color: Colors.darkGrey,
    padding: 0,
    margin: 0,
    lineHeight: '23px',
  },
  textContainer: {
    padding: '8px',
  },
  image: {
    width: '100%',
    height: 100,
    objectFit: 'cover',
  },
});

export default InfoWindowMarker;
