import React from 'react';
import { Typography, Grid } from '@mui/material';
import { Colors } from '../constants/Colors';
import { makeStyles } from 'tss-react/mui';
import { ThingsTodoDataType } from '../types/thingsTodoDataTypes';

const ThingsToDoCard = (data: ThingsTodoDataType) => {
  const { classes, cx } = useStyles();
  return (
    <Grid
      onClick={data.onClick}
      item
      xs={5}
      className={classes.slideCardItem}
      style={{ minWidth: data?.width, width: data?.width }}
    >
      <Grid container className={classes.slideCardItemImageContainer}>
        <img
          src={data.image}
          alt={data.title}
          className={classes.slideCardItemImage}
          style={{ width: data?.width, height: data?.height }}
        />
      </Grid>
      <Grid container className={classes.slideCardItemTitleContainer}>
        <Grid item xs={12} className={classes.slideCardTitleTextContainer}>
          <Typography className={cx(classes.slideCardItemText, classes.autoWidth)}>{data.title}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography className={classes.slideCardItemStatusText}>{data.status}</Typography>
        </Grid>
        <Grid item xs={4} className={classes.slideCardItemTypeContainer}>
          <Typography className={classes.slideCardItemTypeText}>{data.type}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ThingsToDoCard;

const useStyles = makeStyles()({
  slideCardItemImageContainer: {
    width: '100%',
  },
  autoWidth: {
    width: 'auto',
  },
  slideCardItemImage: {
    width: '215px',
    height: '122px',
    objectFit: 'cover',
  },
  slideCardItemTitleContainer: {
    padding: '10px 15px 5px',
    border: `1px solid ${Colors.darkBorderGrey}`,
  },
  slideCardTitleTextContainer: {
    height: 45,
    overflow: 'hidden',
  },
  slideCardItemText: {
    width: '140px',
    '@media (max-width: 355px)': {
      width: '130px',
    },
    fontSize: 14,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  slideCardItemStatusText: {
    fontSize: 14,
    color: Colors.tourismBlue,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  slideCardItemTypeContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  slideCardItemTypeText: {
    fontSize: 12,
  },
  slideCardItem: {
    minWidth: '215px',
    margin: '0 10px',
    '@media (max-width: 355px)': {
      width: '160px',
    },
    '@media (min-width: 355px)': {
      width: '190px',
    },
    backgroundColor: Colors.white,
    boxShadow: `5px 6px ${Colors.lightGrey}`,
    '&:first-child': {
      marginLeft: 0,
    },
    marginLeft: '4px',
  },
});
