import React from 'react';
import { Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import AddPassPage from '../pages/AddPassPage';
import SignInPage from '../pages/SignInPage';
import SignUpPage from '../pages/SignUpPage';
import PrivateRoutes from './ProtectedRoutes';
import App from '../App';
import PublicRoutes from './PublicRoutes';
import HomePageScreen from '../pages/HomePage';
import MapPage from '../pages/MapPage';
import LegalPage from '../pages/LegalPage';
import GeneralPage from '../pages/GeneralPage';
import FaqPage from '../pages/FaqPage';
import AboutPage from '../pages/AboutPage';
import UsePassDetailsPage from '../pages/UsePassDetailsPage';
import ThingsToDoPage from '../pages/ThingsToDoPage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import PrivateRoutesNoHeader from './PrivateRoutesNoHeader';
import ChangePasswordPage from '../pages/ChangePasswordPage';
import ScanPage from '../pages/ScanPage';
import NotFoundPage from '../pages/NotFoundPage';
import ActivationPassPage from '../pages/ActivationPassPage';
import LocationScanPage from '../pages/LocationScanPage';
import AttractionRedeemedPage from '../pages/AttractionRedeemedPage';
import HistoryPage from '../pages/HistoryPage';
import HistoryPass from '../pages/HistoryPass';
import CheckoutPage from '../pages/CheckoutPage';
import PaymentPage from '../pages/PaymentPage';
import PaymentSuccessPage from '../pages/PaymentSuccess';

export const RootRouter = () => (
  <Routes>
    <Route element={<PublicRoutes />}>
      <Route path="/" element={<SignInPage />} />
      <Route path={'/sign-up'} element={<SignUpPage />} />
      <Route path={'/forgot-password'} element={<ForgotPasswordPage />} />
      <Route path={'/change-password'} element={<ChangePasswordPage />} />
    </Route>
    <Route element={<PrivateRoutes />}>
      <Route path={'/log-out'} element={<App />} />
      <Route path={'/home'} element={<HomePageScreen />} />
      <Route path={'/legal'} element={<LegalPage />} />
      <Route path={'/general'} element={<GeneralPage />} />
      <Route path={'/faq'} element={<FaqPage />} />
      <Route path={'/about'} element={<AboutPage />} />
      <Route path={'/attraction-details/:id'} element={<UsePassDetailsPage />} />
      <Route path={'/attraction-redeemed/:id'} element={<AttractionRedeemedPage />} />
      <Route path={'/history'} element={<HistoryPage />} />
      <Route path={'/history-pass'} element={<HistoryPass />} />
      <Route path={'/checkout/:hours'} element={<CheckoutPage />} />
      <Route path={'/payment'} element={<PaymentPage />} />
      <Route path={'/payment/success'} element={<PaymentSuccessPage />} />
    </Route>
    <Route element={<PrivateRoutesNoHeader />}>
      <Route path={'/maps'} element={<MapPage />} />
      <Route path={'/pass/:id/activation'} element={<ActivationPassPage />} />
      <Route path={'/add-pass/:id/:validity'} element={<AddPassPage />} />
      <Route path={'/scan'} element={<ScanPage />} />
      <Route path={'/attraction-scan/:id'} element={<LocationScanPage />} />
      <Route path={'/things-to-do'} element={<ThingsToDoPage />} />
    </Route>
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);
