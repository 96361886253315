import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Colors } from '../constants/Colors';
import Countdown from 'react-countdown';
import { getValidityDate } from '../utils/helpers';

interface CountDownType {
  hours: number;
  minutes: number;
  seconds: number;
  days: number;
}

interface CountDownValitidyDateProps {
  activation_date: string | undefined | Date;
  validity: number | undefined;
  countDownClass?: string;
}

const CountDownValidityDate = ({ activation_date, validity, countDownClass }: CountDownValitidyDateProps) => {
  const { classes, cx } = useStyles();
  const renderCountDown = ({ days, hours, minutes, seconds }: CountDownType) => {
    const daysInHour = days * 24 + hours;
    return (
      <span className={cx(classes.countDown, countDownClass)}>
        {daysInHour}:{minutes}:{seconds}
      </span>
    );
  };
  return (
    <Countdown
      date={getValidityDate(activation_date, validity).format('MM/DD/YYYY hh:mm a')}
      renderer={renderCountDown}
    />
  );
};

export default CountDownValidityDate;

const useStyles = makeStyles()({
  countDown: {
    color: Colors.tourismBlue,
    fontSize: 14,
  },
});
