import React, { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Colors } from '../constants/Colors';
import { Button, Grid, Typography } from '@mui/material';
import { Strings } from '../constants/Strings';
import imageBg from '../assets/images/bg-login2.jpg'; 
import Input from '../components/Input';
import { Link, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useLoginMutation } from '../services/apiSlice';
import { UserCredentialsType } from '../types/userTypes';
import { generateFormData } from '../utils/helpers';
import { useDispatch } from 'react-redux';
import { authenticationActions } from '../services/authSlice';
import HeaderMenu from '../components/HeaderMenu';


const SignInScreen = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [login, { data, isSuccess, isLoading, isError, error }] = useLoginMutation();
  const location = useLocation();

  //@ts-ignore
  const errorMessage = error?.data.error as string;

  useEffect(() => {
    if (isSuccess) {
      const userData = {
        id: data?.user?.id,
        email: data?.user?.email,
      };
      localStorage.setItem('userData', JSON.stringify(userData));
      localStorage.setItem('authToken', (data?.token as string) || '');


    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      dispatch(authenticationActions.setError({ error: errorMessage, isError }));
    }
  }, [isError]);

  const validationSchema = yup.object({
    email: yup.string().email().required(Strings.requiredField),
    password: yup.string().min(8).required(Strings.requiredField),
  });

  const onLogin = (values: UserCredentialsType) => {
    const user = generateFormData(values as object);
    login(user);
  };

  // @ts-ignore
  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    //@ts-ignore
    onSubmit: onLogin,
  });

  return (
    <>
      <Grid container>
        <HeaderMenu />
        <Grid item xs={12} md={6}>
          <form onSubmit={formik.handleSubmit}>
            <div className={classes.root}>
              <Typography className={classes.title}>{Strings.signIn}</Typography>
              <Typography className={classes.description}>{Strings.enterLoginDetails}</Typography>
              <Input
                name={'email'}
                isMandatory
                label={Strings.registeredEmail}
                type={'email'}
                value={formik.values.email}
                onChange={formik.handleChange}
                helperText={formik.errors.email}
              />
              <Input
                name={'password'}
                isMandatory
                label={Strings.password}
                type={'password'}
                value={formik.values.password}
                onChange={formik.handleChange}
                helperText={formik.errors.password}
              />
              <div className={classes.rowContainer}>
                <div className={classes.row}>
                  <input className={classes.checkbox} type={'checkbox'} />
                  <Typography className={classes.description}>{Strings.stayLoggedIn}</Typography>
                </div>
                <Link to="/forgot-password" 
                  state={{ referrer: location?.state?.referrer }} 
                  className={classes.linkButton}>
                  <Typography className={classes.textButton}>{Strings.forgotPassword}</Typography>
                </Link>
              </div>
              <Link to="/sign-up" 
                state={{ referrer: location?.state?.referrer }} 
                className={classes.linkButton}>
                <Typography className={classes.textButton}>
                  {Strings.dontHaveAccount}{" "}
                  <span className={classes.textButtonAction}>{Strings.dontHaveAccountAction}</span>
                </Typography>
              </Link>
              <Button disabled={isLoading} className={classes.button} variant="contained" fullWidth type={'submit'}>
                {Strings.login}
              </Button>
            </div>
          </form>
        </Grid>
        <Grid item md={6}>
          <div className={classes.bg}>
            <div className={classes.logo}>
              <p>{Strings.headerTitle}</p>
            </div>
            <p>{Strings.makeTheMostOf}</p>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default SignInScreen;

const useStyles = makeStyles()({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    paddingTop: '90px'
  },
  bg: {
    '@media (max-width: 900px)': {
      display: 'none',
    },
    background: `url(${imageBg}) no-repeat 37vw center fixed`,
    backgroundSize: '80vw 100vh',
    width: '100%',
    height: '110vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',

    p: {
      color: 'white',
      fontSize: '20px',
      fontWeight: 'bold',
    },
  },
  logo: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    p: {
      fontSize: `35px !important`,
      color: Colors.white,
    },
  },
  title: {
    color: Colors.tourismBlue,
    marginBottom: '9px',
    fontSize: 25,
    fontWeight: '700',
    marginTop: '80px',
  },
  description: {
    fontSize: 12,
    marginBottom: 50,
  },
  button: {
    margin: '20px 0',
    width: '90%',
  },
  rowContainer: {
    width: '90%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '10px',
  },
  linkButton: {
    textDecoration: 'none',
  },
  textButton: {
    fontSize: 12,
    color: Colors.tourismBlue,
  },
  textButtonAction: {
    fontSize: 12,
    color: Colors.tourismBlue,
    fontWeight: '700',
  },
  checkbox: {
    accentColor: Colors.tourismBlue,
    alignSelf: 'flex-start',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  outlineButton: {
    width: '80%',
    marginTop: '40px',
    height: 'auto',
  },
});
