import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
// import BackHeader from '../components/BackHeader';
// import { Strings } from '../constants/Strings';
// import { Colors } from '../constants/Colors';
// import { useNavigate } from 'react-router-dom';
import { Container, Grid, Typography } from '@mui/material';
import BreadCrumbs from '../components/BreadCrumbs';
import { CHECKOUT_STEPS, PASS_PER_HOURS, TAX_PER_PASS } from '../constants/Options';
import { Colors } from '../constants/Colors';
import Header from '../components/Header';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Strings } from '../constants/Strings';
import { useParams, useNavigate } from 'react-router-dom';
import PrimaryButton from '../components/PrimaryButton';

const CheckoutPage = () => {
  const { classes } = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  const { hours } = params;

  const [numberOfPasses, setNumberOfPasses] = useState<number>(1)
  const [selectedHours, setSelectedHours] = useState<number>(24)
  useEffect(() => {
    if (hours && PASS_PER_HOURS.map(h=> h.hour).includes(Number(hours))) {
      setSelectedHours(Number(hours));
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setSelectedHours(PASS_PER_HOURS[0].hour)
    }
  }, [hours])

  const calcSubtotal = () => {
    const subTotal = numberOfPasses * PASS_PER_HOURS.filter(d => d.hour === selectedHours)[0].price
    return `${Strings.euro}${subTotal}`;
  }

  const calcTotal = () => {
    const total = numberOfPasses * PASS_PER_HOURS.filter(d => d.hour === selectedHours)[0].price
    return `${Strings.euro}${total}`;
  }

  const onSelectPaymentMethod = () => {
    navigate(`/payment?hours=${selectedHours}&qty=${numberOfPasses}`)
  }
  
  const handleBack = () => {
    backtoLP();
  }

  const onLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userEmail');

    backtoLP();
  }

  const backtoLP = () => {
    const currentUrl = window.location.href;

    switch(true){
      case currentUrl.includes("localhost"):
        window.location.assign(`/`);
        break;
      case currentUrl.includes("dev"):
        window.location.assign(`https://www.dev.acp.unboxuniverse.io/`)
        break;
      case currentUrl.includes("uat"):
        window.location.assign(`https://www.uat.acp.unboxuniverse.io/`)
        break;
      default:
        window.location.assign(`http://www.antwerpcitypass.be/`)
    }
  }

  return (
    <>
    <Container maxWidth="lg" className={classes.checkoutContainer}>
      <div className={classes.top}>
          <button onClick={handleBack}>Back</button>
          <BreadCrumbs
            items={CHECKOUT_STEPS}
            size={2}
            lineSize={3}
            active={0}
            containerStyle={classes.breadCrumbContainerStyle}
          />
          <button onClick={onLogout}>Log out</button>
        </div>
      <Grid container className={classes.contentTitle}>
        <Grid item xs={12}>
          <Typography className={classes.title}>{Strings.checkout}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.description}>{Strings.thisIsLastPage}</Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.product}>
        <Grid item xs={12} md={6} className={classes.selectPassProduct}>
          <Typography className={classes.subTitle}>{Strings.selectPassOnly}</Typography>
          <Grid container xs={12}>
            <Grid item xs={12}>
              <div className={classes.headerReadCardContainer}>
                <Header noLogo containerClass={classes.headerRedCard}/>
                <div className={classes.headerHours}>
                  <Typography>{selectedHours} {Strings.hours}</Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} className={classes.howManyWould}>
              <Typography>
                {Strings.howManyWouldYouPurchase}
              </Typography>
            </Grid>
            <Grid item xs={12} md={10} className={classes.hoursDropdown}>
            <FormControl sx={{ m: 1 }} fullWidth>
              <InputLabel id="demo-multiple-name-label">Name</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                displayEmpty
                value={numberOfPasses}
                onChange={(v) => setNumberOfPasses(Number(v.target.value))}
                inputProps={{ 'aria-label': 'Without label' }}
                input={<OutlinedInput />}
              >
                {
                  Array.from(Array(5).keys()).map((d, index) => d > 0 &&
                  <MenuItem
                    key={index}
                    value={d}
                  >
                    {d} {Strings.passes}
                  </MenuItem>
                  )
                }
              </Select>
            </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} className={classes.orderBox}>
          <div className={classes.titleOrder}>
            <Typography className={classes.subTitle}>{Strings.yourOrder}</Typography>
          </div>
          <Grid container>
            <Grid item xs={3} className={classes.cardOrderContainer}>
              <Header noLogo containerClass={classes.headerRedCardOrder}/>
            </Grid>
            <Grid item xs={7} className={classes.purhcaseDetail}>
              <div className={classes.purchaseTitle}>
                <Typography>
                  {Strings.headerTitle} {selectedHours}h
                </Typography>
              </div>
              <div className={classes.purchaseAmount}>
                <Typography>
                  {Strings.quantity}{numberOfPasses}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <div className={classes.amountSubTotal}>
            <Grid container>
              <Grid item xs={8}>
                <Typography>{Strings.subTotal}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>{calcSubtotal()}</Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={8}>
                <Typography>{Strings.tax}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  {TAX_PER_PASS}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={8}>
                <Typography>{Strings.total}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>{calcTotal()}</Typography>
              </Grid>
            </Grid>
          </div>

          <PrimaryButton
            onClick={onSelectPaymentMethod}
            buttonStartIcon={true}
            buttonTitle={Strings.selectPaymentMethod}
            containerStyle={classes.buttonFullWidth}
          />

        </Grid>
      </Grid>
    </Container>
    </>
  );
};

export default CheckoutPage;

const useStyles = makeStyles()({
  top: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '40px 0',

    button: {
      border: 'solid 1px #0058B3',
      background: 'none',
      fontSize: 16,
      color: '#0058B3',
      cursor: 'pointer',
      padding: '10px 20px',
      whiteSpace: 'nowrap',
    },
  },
  buttonFullWidth: {
    width: '100%',
  },
  amountSubTotal: {
    '.MuiGrid-root': {
      margin: '5px 0',
      p: {
        fontSize: 14,
      },
      '&:nth-child(3)': {
        borderTop: `1px solid ${Colors.grayB3}`,
        paddingTop: 5,
      }
    },
    '.MuiGrid-grid-xs-4': {
      display: 'flex',
      justifyContent: 'right',
    }
  },
  cardOrderContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  purhcaseDetail: {
    padding: 10,
  },
  purchaseTitle: {

  },
  purchaseAmount: {
    color: Colors.grayB3,
    p: {
      fontSize: 14,
    }
  },
  orderBox: {
    border: `1px solid ${Colors.grayDa}`,
    padding: 15,
  },
  titleOrder: {},
  howManyWould: {
    marginTop: 20,
  },
  hoursDropdown: {
    marginTop: 8,
    '.MuiFormControl-root': {
      margin: 0,
      width: '100%',
      '.MuiSelect-select': {
        paddingLeft: 15,
        paddingTop: 8,
        paddingBottom: 8,
      },
      '.MuiFormLabel-root': {
        display: 'none',
      },
    }
  },
  headerHours: {
    padding: '10px 8px 10px',
    border: `1px solid ${Colors.grayDa}`,
    p: {
      fontWeight: 'bold',
      fontSize: 14,
    }
  },
  headerReadCardContainer: {
    width: '50%',
    overflow: 'hidden',
  },
  headerRedCardOrder: {
    backgroundColor: Colors.redCheckoutCard,
    '.MuiTypography-root': {
      color: Colors.white,
      fontSize: 10,
      '@media (max-width: 510px)': {
        fontSize: 7,
      }
    },
    svg: {
      width: 12,
    }
  },
  headerRedCard: {
    backgroundColor: Colors.redCheckoutCard,  
    height: 125,
    '.MuiTypography-root': {
      color: Colors.white,
      '@media (max-width: 510px)': {
        fontSize: 12,
      }
    }
  },
  redCard: {
    border: `1px solid ${Colors.greyBorder}`
  },
  selectPassProduct: {
    marginBottom: 20,
  },
  product: {
    margin: '45px 0',
  },
  subTitle: {
    fontSize: 20,
    marginBottom: 20,
  },
  title: {
    fontSize: 30,
  },
  description: {
    fontSize: 14,
    lineHeight: '20px',
    marginTop: 15,
    color: Colors.gray47,
  },
  contentTitle: {
    marginTop: 30,
  },
  breadCrumbContainerStyle: {
    width: '100%',
  },
  checkoutContainer: {
    margin: '0 auto',
  },
});
