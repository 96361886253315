import { Button, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Colors } from '../constants/Colors';
import { Strings } from '../constants/Strings';
import { makeStyles } from 'tss-react/mui';

const NotFoundPage = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  return (
    <>
      <Typography className={classes.oops}>{Strings.oops}</Typography>
      <Typography className={classes.wrongText}>{Strings.errorMessage}</Typography>
      <Button variant="contained" className={classes.logoutButton} onClick={() => navigate('/home')}>
        {Strings.backToHome}
      </Button>
    </>
  );
};

export default NotFoundPage;

const useStyles = makeStyles()({
  logoutButton: {
    color: Colors.white,
    width: '90vw',
    borderRadius: 0,
    marginLeft: '5vw',
  },
  oops: {
    fontSize: '33px',
    fontWeight: '700',
    padding: '22px',
    marginTop: '30px',
  },
  wrongText: {
    fontSize: '16px',
    padding: '22px',
  },
});
