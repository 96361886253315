import React from 'react';
import { Grid, Skeleton } from '@mui/material';
import { Colors } from '../constants/Colors';
import { makeStyles } from 'tss-react/mui';

interface SkeletonCardProps {
  width?: string | number;
  height?: string | number;
}

const SkeletonCard = (data: SkeletonCardProps) => {
  const { classes } = useStyles();
  return (
    <Grid item xs={5} className={classes.slideCardItem} style={{ minWidth: data?.width, width: data?.width }}>
      <Skeleton variant="rectangular" className={classes.slideCardSkeleton} />
    </Grid>
  );
};
export default SkeletonCard;

const useStyles = makeStyles()({
  slideCardItem: {
    minWidth: '215px',
    margin: '0 10px',
    '@media (max-width: 355px)': {
      width: '160px',
    },
    '@media (min-width: 355px)': {
      width: '190px',
    },
    backgroundColor: Colors.white,
    boxShadow: `5px 6px ${Colors.lightGrey}`,
    '&:first-child': {
      marginLeft: 0,
    },
    marginLeft: '4px',
  },
  slideCardSkeleton: {
    minHeight: 100,
  },
});
