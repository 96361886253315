export const Strings = {
  headerTitle: 'Antwerp City Pass',
  headerSubTitle: 'Tourist',
  headerSubTitleUnlock: 'Unlock The City!',
  signIn: 'Sign In',
  scan: 'Scan',
  enterLoginDetails: 'Please enter your login details.',
  password: 'Password',
  registeredEmail: 'Your registered e-mail',
  login: 'Login',
  stayLoggedIn: 'Stay logged in',
  forgotPassword: 'Forgot your password?',
  noWorriesForgotPassword: 'No worries! Enter your e-mail address & we’ll send you a link to reset your password.',
  emailAddress: 'Email Address',
  emailPlaceholder: 'e.g youremail@here.com',
  backToLogin: 'Back to login',
  adminContactUs: 'Forgot your email address? Please contact us at',
  adminContactUsEmail: 'adminemail@here.com',
  loading: 'Loading...',
  ifThisEmailExists:
    'If this email address exists, you will receive a link via email to \
  reset your password. Didn’t receive the email? Please',
  ifThisEmailExistsBold: ' check your spam folder, click to resend the email or try another email address. ',
  yourEntrySubmitted: 'Your entry has been submitted.',
  whereDoIGet: 'Where do I get Username & Password?',
  dontHaveAccount: 'Don’t have an account yet?',
  dontHaveAccountAction: 'Click here to register',
  alreadyHaveAccount: 'Already have an account?',
  alreadyHaveAccountAction: 'Click here to sign in',
  registerAccount: 'Register your account',
  submit: 'Submit',
  yourName: 'Your name',
  confirmPassword: 'Confirm Password',
  confirmEmail: 'Confirm your registered e-mail',
  agree: 'I agree to the',
  termsOfUse: 'Terms of Use',
  privacyPolicy: 'Privacy Policy.',
  requiredField: 'Required field',
  fieldChecked: 'Field must be checked',
  addPassTitle: 'Pass addition',
  unlockCity: 'Unlock The City!',
  days: 'Days',
  addName: 'Add Name here...',
  passType: 'Vouchers, One time pass & Unlimited Pass',
  descriptionPass:
    'With this Antwerp City Pass you can go to more than 45 sights, \
    use the bus for free and save on your souvenirs and belgian delicacies at selected stores.*',
  asterixPass: '*These privileges can only be used for the duration of your pass',
  readyToBeActivated: 'Your Antwerp City Pass \
      is now ready to be activated .',
  add: 'Add',
  continue: 'Continue',
  passAddedTitle: 'Pass added',
  welcome: 'Welcome!',
  passOneOfTwo: 'Pass _ of _',
  amountDayPass: '_ Days Pass',
  inactive: 'inactive',
  dummyName: 'John Doe',
  amountDays: 3,
  activate: 'Activate',
  voucherOneTime: 'vouchers, one time pass && unlimited pass',
  thePerfectWayTo:
    'The perfect way to explore Antwerp! A pass that provides discounts, free travel on public transport, \
     and free entrance to many of Antwerp’s most famous attractions!',
  useYourVoucher: 'Use your Vouchers!',
  seeMore: 'See More',
  findLocalMerchant: 'Find local merchants and sights on the map!',
  youCanUseYourPass: 'You can use your pass at 16 places nearby.',
  thingsToDo: 'Things To Do',
  aboutAntwerp: 'About Antwerp City Pass',
  about: 'About',
  details: 'Details',
  pressHereToAddPass: 'Press here to add a pass',
  maps: 'Maps',
  legal: 'Legal',
  faq: 'FAQ',
  logout: 'Log out',
  version: 'version',
  unboxUniverse: 'UNBOX Universe',
  aboutFooterDescription:
    'We ensure efficient and targetable fund distribution with\
  full transparency – reinforcing positive behaviors and preventing corruption to make the world a better place.',
  visitwantwerpenLink: 'visit.antwerpen.be',
  whatIsAntwerp: 'What is Antwerp City Pass?',
  loremIpsum:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse nulla \
  porttitor mauris volutpat nunc. Erat ornare id pulvinar dui cras quam. Pellentesque \
  fringilla sed mauris tincidunt neque, ullamcorper. Elit aliquam, ut velit egestas \
  cursus porta dignissim.',
  theAntwerpIs:
    'The Antwerp City Pass is your guide to discovering Antwerp. The pass opens the most\
  beautiful, delicious and surprising doors in town for you.',
  poweredBy: 'Powered by',
  fomuAntwerp: 'FOMU Antwerp',
  included: 'Included',
  voucher: 'Voucher',
  fomuDescription:
    'Enjoy one of the most extensive photography museum in Europe. \
  Be amazed by pictures from local and foreign artists alike.',
  scanQR: 'Scan QR',
  scanQRCode: 'Scan QR Code',
  findMore: 'Find Out More',
  ticketRedeemed: 'This ticket was redeemed on the ',
  footerThingsToDoDetails:
    'After redeeming the Voucher, you accept the \
  terms and conditions of this offer. The terms and conditions apply to the use of the Voucher',
  changePassword: 'Change Password',
  enterNewPassword: 'Enter your password below',
  newPassword: 'New Password',
  confirmNewPassword: 'Confirm New Password',
  passwordPlaceholder: '******',
  addPassMethod: 'How would you like to add a pass?',
  viaEmail: 'via E-mail',
  viaQr: 'via QR Code',
  errorMessage: 'Something went wrong!',
  yourPasswordMustContain: 'Your password must contain:',
  atLeast8: 'At least 8 characters',
  noCommon: 'No common words or passwords',
  passwordValidation:
    'Must Contain 8 Characters, One Uppercase,\
  One Lowercase, One Number and One Special Case Character',
  matchPassword: `Those passwords didn't match. Please try again.`,
  invalidResetPasswordCode: 'Invalid reset password code',
  passwordUpdated: 'Password Updated!',
  yourPasswordHasBeenChanged: 'Your password has been changed successfully. Please use your new password to login.',
  oops: 'Oops..',
  expiresOn: 'Expires on',
  expired: 'Expired',
  passActivationTitle: 'Pass activation',
  activation: 'Activation',
  activating: 'Activating...',
  passActive: 'Pass active',
  passReadyToUse: 'Your Antwerp City Pass is ready to use.',
  timeLeft: 'Time left: ',
  discount: 'Discount',
  backToHome: 'Back to Homepage',
  selectPass: 'Please select the pass you want to use for this voucher:',
  alignQrCodeScan: 'Align the QR code within the frame to scan.Your phone will automatically detect the QR Code.',
  redeemSuccess: 'The Pass was redeemed successfully',
  search: 'Search...',
  notFound: 'Not Found!.',
  history: 'History',
  dataEmpty: 'Data empty',
  historyPass: 'History Pass',
  fullAccessPass: 'Full Access Pass',
  tomorrow: 'Tomorrow',
  yesterday: 'Yesterday',
  last: 'Last',
  hyperlinkToProvider: 'hyper link to provider',
  findOutMore: 'Find Out More',
  schedule: 'Schedule',
  moreInformation: 'More Information',
  noRecord: 'No records',
  howManyWouldYouPurchase: 'How many would you purchase',
  hours: 'Hours',
  checkout: 'Checkout',
  thisIsLastPage: 'This is the last page before paying, make sure the information you enter is correct.',
  selectPassOnly: 'Select Pass',
  passes: 'Passes',
  yourOrder: 'Your Order',
  quantity: 'Quantity: ',
  subTotal: 'Sub total',
  tax: 'Tax',
  total: 'Total',
  euro: '€',
  selectPaymentMethod: 'Select Payment Method',
  paymentSuccess: 'Payment Successful',
  paymentError: 'Payment Error',
  summary: 'Summary',
  passesBeenSent: 'Your passes have been sent to the e-mail address you provided.',
  logoutToHomepage: 'Logout and return to Homepage',
  makeTheMostOf: 'Make the most of your visit, Antwerp is just a click away',
  pleaseSelectPM: 'Please select payment method!'
};
