import React from 'react';
import { Typography, Grid } from '@mui/material';
import { Strings } from '../constants/Strings';
import { Colors } from '../constants/Colors';
import { makeStyles } from 'tss-react/mui';
import mapPreviewImage from '../assets/images/mapPreview.png';
import { Link } from 'react-router-dom';

const MapPreview = () => {
  const { classes } = useStyles();
  return (
    <Grid container xs={12} className={classes.container}>
      <Grid item className={classes.borderContainer}>
        <Grid container className={classes.useYourVoucher}>
          <Grid item xs={6}>
            <Typography className={classes.useYourVoucherTitle}>{Strings.useYourVoucher}</Typography>
          </Grid>
          <Grid item xs={6} className={classes.seeMoreContainer}>
            <Link to="/maps" className={classes.seeMore}>
              {Strings.seeMore}
            </Link>
          </Grid>
        </Grid>
        <Grid container className={classes.imageContainer}>
          <Grid item className={classes.imageContainerItem}>
            <img src={mapPreviewImage} alt="map-preview" className={classes.image} />
          </Grid>
          <Grid item className={classes.findLocalMerchant}>
            <Typography className={classes.findLocalMerchantText}>{Strings.findLocalMerchant}</Typography>
          </Grid>
        </Grid>
        <Grid item className={classes.useYourPassContainer}>
          <Typography className={classes.useYourPassText}>{Strings.youCanUseYourPass}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MapPreview;

const useStyles = makeStyles()({
  borderContainer: {
    padding: '10px 15px',
    border: `1px solid ${Colors.darkBorderGrey}`,
    boxShadow: `5px 6px ${Colors.lightGrey}`,
  },
  useYourVoucher: {
    marginBottom: 15,
  },
  useYourVoucherTitle: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  seeMoreContainer: {
    textAlign: 'right',
  },
  seeMore: {
    textDecoration: 'none',
    color: Colors.tourismBlue,
  },
  imageContainer: {
    border: `1px solid ${Colors.darkBorderGrey}`,
    textAlign: 'center',
    justContent: 'left',
  },
  imageContainerItem: {
    width: '100%',
  },
  image: {
    width: '100%',
  },
  findLocalMerchant: {
    padding: '15px 8px 10px',
  },
  findLocalMerchantText: {
    fontSize: 14,
  },
  useYourPassContainer: {
    padding: '15px 0 5px',
  },
  useYourPassText: {
    fontSize: 14,
  },
  container: {
    padding: '15px 20px 10px',
    marginBottom: 30,
  },
});
