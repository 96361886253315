export const GOOGLE_MAPS_MAP_OPTIONS = {
  disableDefaultUI: true,
  styles: [{ elementType: 'labels', featureType: 'poi.business', stylers: [{ visibility: 'off' }] }],
};

export const CHECKOUT_STEPS: string[] = ['Checkout','Payment']

export interface PassPerHoursTypes {
  hour: number;
  price: number;
}

export const PASS_PER_HOURS: PassPerHoursTypes[] = [{
  hour: 24,
  price: 45,
},{
  hour: 48,
  price: 55,
},{
  hour: 72,
  price: 65,
},]

export const TAX_PER_PASS: string = 'Incl'