import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Colors } from '../constants/Colors';
import { List, ListItemButton, ListItemText, Collapse, Typography, Grid } from '@mui/material';
import { FaqItemType } from '../types/faqTypes';
import { ReactComponent as ArrowCollapseIcon } from '../assets/images/arrowGreyIcon.svg';

const AboutCollapseItem = ({ question, answer, items }: FaqItemType) => {
  const { classes, cx } = useStyles();
  const [open, setOpen] = React.useState<boolean>(false);
  return (
    <>
      <ListItemButton onClick={() => setOpen(!open)} className={cx(classes.container, open && classes.noMarginBottom)}>
        <ListItemText primary={question} className={classes.primaryQuestion} />
        <ArrowCollapseIcon
          className={cx(classes.iconWidth, open ? classes.transformIcon90Deg : classes.transformNormal)}
        />
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className={classes.listCollapseContainer}>
          <ListItemButton>
            <ListItemText primary={answer} />
          </ListItemButton>
          <Grid className={classes.placeContainer}>
            {items?.map((item, index) => (
              <Grid container xs={12} key={index} className={classes.placeParentContainer}>
                <Grid item xs={12}>
                  <Typography>{item.title}</Typography>
                </Grid>
                {item.items.map((place, idx) => (
                  <Grid item key={idx} xs={12}>
                    <Typography>{place}</Typography>
                  </Grid>
                ))}
              </Grid>
            ))}
          </Grid>
        </List>
      </Collapse>
    </>
  );
};
export default AboutCollapseItem;

const useStyles = makeStyles()({
  primaryQuestion: {
    color: Colors.tourismBlue,
  },
  placeContainer: {
    padding: '0 15px',
    marginBottom: 30,
    marginTop: 25,
  },
  placeParentContainer: {
    marginBottom: 25,
  },
  listCollapseContainer: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: Colors.gray94,
    boxShadow: `5px 5px 5px ${Colors.greyBorder}`,
  },
  noMarginBottom: {
    marginBottom: 0,
    borderBottomWidth: 0,
  },
  iconWidth: {
    minWidth: 30,
  },
  transformNormal: {
    transform: 'rotate(0)',
  },
  transformIcon90Deg: {
    transform: 'rotate(180deg)',
  },
  container: {
    padding: '5px 13px',
    backgroundColor: Colors.white,
    borderColor: Colors.gray94,
    borderWidth: 1,
    borderStyle: 'solid',
    margin: '20px 0',
    '&:first-child': {
      marginTop: 0,
    },
    ':hover': {
      backgroundColor: Colors.white,
    },
  },
  mainTitle: {
    color: Colors.tourismRed,
    fontWeight: 'bold',
  },
});
