import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Colors } from '../constants/Colors';
import { Button, Grid, Typography } from '@mui/material';
import { Strings } from '../constants/Strings';
import imageBg from '../assets/images/bg-login2.jpg';
import Input from '../components/Input';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useRegisterMutation } from '../services/apiSlice';
import { FormikUserType } from '../types/userTypes';
import { generateFormData } from '../utils/helpers';
import { usefulLinks } from '../constants/UsefulLinks';
import { useDispatch } from 'react-redux';
import { authenticationActions } from '../services/authSlice';
import FriendlyCaptchaWidget from '../components/FriendlyCaptchaWidget';
import HeaderMenu from '../components/HeaderMenu';

const SignUpPage = () => {
  const { classes } = useStyles();
  const navigation = useNavigate();
  const [register, { isSuccess, isLoading, isError, error }] = useRegisterMutation();
  const dispatch = useDispatch();
  const [solution, setSolution] = useState<any>('');
  const location = useLocation();

  //@ts-ignore
  const errors = error?.data?.errors;
  const errorMessage = (errors?.email[0] || errors?.password[0] || errors?.password_confirmation[0]) as string;

  useEffect(() => {
    if (isSuccess) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      navigation('/', { state: { referrer: location?.state?.referrer } });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      dispatch(authenticationActions.setError({ isError, error: errorMessage }));
    }
  }, [isError, errorMessage]);

  const validationSchema = yup.object({
    name: yup.string().required(Strings.requiredField),
    email: yup.string().email().required(Strings.requiredField),
    confirmEmail: yup
      .string()
      .email()
      .oneOf([yup.ref('email'), null], 'Emails must match')
      .required(Strings.requiredField),
    password: yup.string().min(8).required(Strings.requiredField),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required(Strings.requiredField),
    agreeTerms: yup.bool().oneOf([true], Strings.fieldChecked),
    newsletter: yup.bool(),
  });

  const onRegisterUser = (userValues: FormikUserType) => {
    const { name, email, password, confirmPassword, agreeTerms, newsLetter } = userValues;

    const consent = agreeTerms ? 1 : 0;
    const marketingConsent = newsLetter ? 1 : 0;

    const userJSON = {
      name,
      email,
      password,
      password_confirmation: confirmPassword,
      consent,
      marketing_consent: marketingConsent,
      'frc-captcha-solution': solution,
    };

    const userFormData = generateFormData(userJSON);
    register(userFormData);
  };

  // @ts-ignore
  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      name: '',
      email: '',
      confirmEmail: '',
      password: '',
      confirmPassword: '',
      agreeTerms: false,
      newsLetter: false,
    },
    validationSchema: validationSchema,
    //@ts-ignore
    onSubmit: onRegisterUser,
  });

  return (
    <>
      <Grid container>
        <HeaderMenu />
        <Grid item xs={12} md={6}>
          <form onSubmit={formik.handleSubmit}>
            <div className={classes.root}>
              <Typography className={classes.title}>{Strings.registerAccount}</Typography>
              <Input
                isMandatory
                name={'name'}
                label={Strings.yourName}
                value={formik.values.name}
                onChange={formik.handleChange}
                helperText={formik.errors.name}
              />
              <Input
                name={'email'}
                isMandatory
                label={Strings.registeredEmail}
                type={'email'}
                value={formik.values.email}
                onChange={formik.handleChange}
                helperText={formik.errors.email}
              />
              <Input
                name={'confirmEmail'}
                isMandatory
                label={Strings.confirmEmail}
                type={'email'}
                value={formik.values.confirmEmail}
                onChange={formik.handleChange}
                helperText={formik.errors.confirmEmail}
              />
              <Input
                name={'password'}
                isMandatory
                label={Strings.password}
                type={'password'}
                value={formik.values.password}
                onChange={formik.handleChange}
                helperText={formik.errors.password}
              />
              <Input
                name={'confirmPassword'}
                isMandatory
                label={Strings.confirmPassword}
                type={'password'}
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                helperText={formik.errors.confirmPassword}
              />
              <div className={classes.checkBoxContainer}>
                <div className={classes.row}>
                  <input
                    name={'agreeTerms'}
                    className={classes.checkbox}
                    type={'checkbox'}
                    checked={formik.values.agreeTerms}
                    onChange={formik.handleChange}
                  />
                  <div className={classes.agreeContainer}>
                    <Typography className={classes.description}>{Strings.agree}</Typography>
                    <Typography
                      className={classes.descriptionBold}
                      onClick={() => window.open(usefulLinks.visitAntwerpLink, '_blank', 'noreferrer')}
                    >
                      {Strings.termsOfUse} & {Strings.privacyPolicy}
                    </Typography>
                  </div>
                </div>
              </div>
              <FriendlyCaptchaWidget sendCallbackSolution={(value)=> {
                setSolution(value?.solution || '')
              }} />
              <Link to="/" state={{ referrer: location?.state?.referrer }} className={classes.linkButton}>
                <Typography className={classes.textButton}>
                  {Strings.alreadyHaveAccount}{" "}
                  <span className={classes.textButtonAction}>{Strings.alreadyHaveAccountAction}</span>
                </Typography>
              </Link>
              {
                solution && 
                <Button disabled={isLoading} className={classes.button} variant="contained" fullWidth type={'submit'}>
                  {Strings.submit}
                </Button>
              }
            </div>
          </form>
        </Grid>
        <Grid item md={6}>
          <div className={classes.bg}>
            <div className={classes.logo}>
              <p>{Strings.headerTitle}</p>
            </div>
            <p>{Strings.makeTheMostOf}</p>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default SignUpPage;

const useStyles = makeStyles()({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh',
    paddingBottom: '50px',
  },
  bg: {
    '@media (max-width: 900px)': {
      display: 'none',
    },
    background: `url(${imageBg}) no-repeat 37vw center fixed`,
    backgroundSize: '80vw 100vh',
    width: '100%',
    height: '110vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',

    p: {
      color: 'white',
      fontSize: '20px',
      fontWeight: 'bold',
    }
  },
  logo: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    p: {
      fontSize: `35px !important`,
      color: Colors.white,
    },
  },
  title: {
    color: Colors.tourismBlue,
    marginBottom: '9px',
    fontSize: 25,
    fontWeight: '700',
    marginTop: '80px',
    width: '50%',
    textAlign: 'center',
  },
  button: {
    marginTop: 30,
    width: '90%',
  },
  checkbox: {
    accentColor: Colors.tourismBlue,
    alignSelf: 'flex-start',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    marginLeft: '20px',
  },
  agreeContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  description: {
    fontSize: 12,
    alignSelf: 'center',
  },
  descriptionBold: {
    fontSize: 12,
    alignSelf: 'center',
    fontWeight: 'bold',
    color: Colors.tourismBlue,
    padding: '0px 2px 0px 2px',
  },
  checkBoxContainer: {
    marginTop: '18px',
    width: '100%',
  },
  linkButton: {
    textDecoration: 'none',
    marginTop: '8px'
  },
  textButton: {
    fontSize: 12,
    color: Colors.tourismBlue,
  },
  textButtonAction: {
    fontSize: 12,
    color: Colors.tourismBlue,
    fontWeight: '700',
  },
});
