import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectToken } from '../services/authSlice';
import HeaderMenu from '../components/HeaderMenu';

const PrivateRoutes = () => {
  const token = useSelector(selectToken);
  const path = window.location.pathname;

  return token ? (
    <>
      <HeaderMenu />
      <Outlet />
    </>
  ) : (
    <Navigate to="/" state={{ referrer: path  }} replace />
  );
};
export default PrivateRoutes;
