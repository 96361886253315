import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Colors } from '../constants/Colors';
import { Button, Typography } from '@mui/material';
import { Strings } from '../constants/Strings';
import Header from '../components/Header';
import Input from '../components/Input';
import { useNavigate, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useForgotPasswordMutation } from '../services/apiSlice';
import { EmailUserType } from '../types/userTypes';
import { generateFormData } from '../utils/helpers';
import { useDispatch } from 'react-redux';
import { authenticationActions } from '../services/authSlice';
import FriendlyCaptchaWidget from '../components/FriendlyCaptchaWidget';

const ForgotPasswordPage = () => {
  const { classes, cx } = useStyles();
  const navigation = useNavigate();
  const location = useLocation();
  const [forgotPassword, { isSuccess, isLoading, isError, error }] = useForgotPasswordMutation();
  const dispatch = useDispatch();
  const [solution, setSolution] = useState<any>('')

  //@ts-ignore
  const errors = error?.data?.errors;
  const errorMessage = errors?.email[0] as string;

  useEffect(() => {
    if (isError) {
      dispatch(authenticationActions.setError({ error: errorMessage, isError }));
    }
  }, [isError]);

  const validationSchema = yup.object({
    email: yup.string().email().required(Strings.requiredField),
  });

  const onForgotPassword = (userValues: EmailUserType) => {
    const { email } = userValues;

    const userJSON = {
      email,
      'frc-captcha-solution': solution,
    };
    const userFormData = generateFormData(userJSON);
    forgotPassword(userFormData);
  };

  // @ts-ignore
  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    //@ts-ignore
    onSubmit: onForgotPassword,
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className={classes.root}>
          <Header hasBackButton onBackClick={() => 
            navigation('/', { state: { referrer: location?.state?.referrer } })} />
          <Typography className={classes.title}>{Strings.forgotPassword}</Typography>
          <Typography className={classes.subtitle}>{Strings.noWorriesForgotPassword}</Typography>
          <Input
            isMandatory
            name={'email'}
            label={Strings.emailAddress}
            value={formik.values.email}
            onChange={formik.handleChange}
            helperText={isSuccess ? Strings.yourEntrySubmitted : formik.errors.email}
            placeholder={Strings.emailPlaceholder}
            isSuccessHelperText={isSuccess}
          />
          {isSuccess && (
            <div className={classes.sucessDescription}>
              <Typography className={classes.sucessDescriptionText}>
                {Strings.ifThisEmailExists}
                <span className={classes.textBold}>{Strings.ifThisEmailExistsBold}</span>
              </Typography>
            </div>
          )}

          <FriendlyCaptchaWidget sendCallbackSolution={(value)=> {
            setSolution(value?.solution || '')
          }} />
          {
            solution && 
            <Button disabled={isLoading} className={classes.button} variant="contained" fullWidth type={'submit'}>
              {Strings.submit}
            </Button>
          }
          <Button 
            onClick={() => navigation('/', { state: { referrer: location?.state?.referrer } })} 
            className={classes.buttonBackToLogin} variant="outlined" fullWidth>
            {Strings.backToLogin}
          </Button>
          <Typography className={cx(classes.subtitle, classes.adminContactUs)}>{Strings.adminContactUs}</Typography>
          <a href={`mailto:${Strings.adminContactUsEmail}`} target="_blank" rel="noreferrer">
            <Typography className={classes.adminContactUsEmail}>{Strings.adminContactUsEmail}</Typography>
          </a>
        </div>
      </form>
    </>
  );
};

export default ForgotPasswordPage;

const useStyles = makeStyles()({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh',
    paddingBottom: '50px',
  },
  adminContactUs: {
    marginBottom: 0,
    paddingBottom: 0,
    fontSize: 12,
  },
  adminContactUsEmail: {
    fontSize: 12,
    color: Colors.blueLink,
  },
  sucessDescription: {
    margin: '20px 20px 0',
    border: `1px solid ${Colors.tourismBlue}`,
    backgroundColor: Colors.greenLight,
    padding: '10px 20px',
    borderRadius: 20,
  },
  sucessDescriptionText: {
    fontSize: 14,
  },
  textBold: {
    fontWeight: 'bold',
  },
  title: {
    color: Colors.tourismBlue,
    marginBottom: '9px',
    fontSize: 25,
    fontWeight: '700',
    marginTop: '80px',
    width: '50%',
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 15,
    textAlign: 'center',
    padding: '15px 35px',
    marginBottom: 30,
  },
  button: {
    marginTop: 30,
    width: '90%',
  },
  buttonBackToLogin: {
    marginTop: 15,
    width: '90%',
    borderRadius: 0,
    height: 46,
    borderWidth: 1,
    boxShadow: `2px 1px 5px ${Colors.gray94}`,
  },
  checkbox: {
    accentColor: Colors.tourismBlue,
    alignSelf: 'flex-start',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    marginLeft: '20px',
  },
  agreeContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  description: {
    fontSize: 12,
    alignSelf: 'center',
  },
  descriptionBold: {
    fontSize: 12,
    alignSelf: 'center',
    fontWeight: 'bold',
    color: Colors.tourismBlue,
    padding: '0px 2px 0px 2px',
  },
  checkBoxContainer: {
    marginTop: '18px',
    width: '100%',
  },
});
