import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { useLogoutMutation, useTestQuery } from './services/apiSlice';
import { useNavigate } from 'react-router-dom';
import { generateFormData } from './utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { authenticationActions, selectToken } from './services/authSlice';

const App = () => {
  const [logout, { isSuccess }] = useLogoutMutation();
  const { data, isSuccess: isTestSuccess, isError, error } = useTestQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);

  useEffect(() => {
    if (isTestSuccess) {
      console.log('SUCCESS', data);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      console.log('ERROR', error);
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      removeToken();
    }
  }, [isSuccess]);

  const removeToken = () => {
    localStorage.removeItem('authToken');
    dispatch(authenticationActions.resetToken());
    navigate('/');
  };

  const onLogoutPress = () => {
    const tokenFormData = generateFormData({ token: token });
    logout(tokenFormData);
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p onClick={onLogoutPress}>{'Logout'}</p>
        <a className="App-link" href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
          Learn React
        </a>
      </header>
    </div>
  );
};

export default App;
