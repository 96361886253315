import React from 'react';
import { Typography, Grid } from '@mui/material';
import { Strings } from '../constants/Strings';
import { Colors } from '../constants/Colors';
import { makeStyles } from 'tss-react/mui';
import 'react-spring-bottom-sheet/dist/style.css';
import { useNavigate } from 'react-router-dom';
import AddPassBottomSheet from './AddPassBottomSheet';

const PassCardEmpty = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  return (
    <>
      <Grid container xs={12} className={classes.passCardEmptyContainer}>
        <Grid container xs={12} className={classes.paddingCardContainer}>
          <AddPassBottomSheet onScanClick={() => navigate('/scan')} onMailClick={() => window.open('mailto:')} />
        </Grid>
        <Grid item xs={12} className={classes.voucherPass}>
          <Typography className={classes.voucherPassTitle}>{Strings.voucherOneTime}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

PassCardEmpty.defaultProps = {
  isEmpty: false,
};

export default PassCardEmpty;

const useStyles = makeStyles()({
  passCardEmptyContainer: {
    padding: '0 20px',
  },
  paddingCardContainer: {
    minHeight: 130,
    padding: '30px 25px 20px 30px',
    backgroundColor: Colors.lightGrey,
    borderTop: `1px solid ${Colors.darkBorderGrey}`,
    borderLeft: `1px solid ${Colors.darkBorderGrey}`,
    borderRight: `1px solid ${Colors.darkBorderGrey}`,
    boxShadow: `5px 5px 5px ${Colors.lightGrey}`,
    justifyContent: 'center',
    alignItems: 'center',
  },
  voucherPass: {
    textAlign: 'center',
    backgroundColor: Colors.darkPassGrey,
    padding: '20px 0',
    boxShadow: `5px 5px 5px ${Colors.lightGrey}`,
  },
  voucherPassTitle: {
    fontSize: 12,
    color: Colors.darkGrey,
    textTransform: 'capitalize',
  },
  bottomSheetContainer: {
    marginTop: '40px',
    padding: '0px 16px 0px 16px',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '24px 0px 35px',
    justifyContent: 'space-between',
  },
  bottomSheetButton: {
    width: '45%',
  },
});
