import React from 'react';
import { Typography, Grid } from '@mui/material';
import { Strings } from '../constants/Strings';
import { Colors } from '../constants/Colors';
import { makeStyles } from 'tss-react/mui';
import LogoTitle from './LogoTitle';
import { UserPassType } from '../types/userPassType';
import { Link } from 'react-router-dom';
import { hasExpiredValidity } from '../utils/helpers';

interface PassCardInactiveProps {
  card: UserPassType;
}

const PassCardInactive = ({ card }: PassCardInactiveProps) => {
  const { classes, cx } = useStyles();

  return (
    <Grid container xs={12} className={classes.passCardContainer}>
      <Grid container xs={12} className={cx(classes.paddingCardContainer)}>
        <Grid item xs={8}>
          <LogoTitle
            hasSubtitle={false}
            titleContainerClass={classes.logoTitleTextContainerClass}
            titleClass={classes.logoTitleTextClass}
            iconClass={classes.logoTitleIconClass}
          />
          <Typography className={classes.logoTitleSubText}>{Strings.headerSubTitleUnlock}</Typography>
          <div className={classes.cardUserContainer}>
            <Typography className={classes.cardUser}>{card.pass_name}</Typography>
          </div>
        </Grid>
        <Grid item xs={4} className={classes.cardDays}>
          <div className={classes.cardDaysGrey}>
            <div className={classes.cardGrey}>
              <Typography className={classes.amount}>{card.validity}</Typography>
              <Typography className={classes.days}>{Strings.days}</Typography>
            </div>
          </div>
          <div>
            {!hasExpiredValidity(card.activation_date, card.validity) && (
              <Link to={`/pass/${card.id}/activation`}>
                <Typography className={classes.activateTitle}>{Strings.activate}</Typography>
              </Link>
            )}
          </div>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        className={cx(
          classes.voucherPass,
          classes.greyGradientBg,
          hasExpiredValidity(card.activation_date, card.validity) && classes.bgExpired
        )}
      >
        <Typography className={classes.voucherPassTitle}>
          {hasExpiredValidity(card.activation_date, card.validity) ? Strings.expired : Strings.fullAccessPass}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PassCardInactive;

const useStyles = makeStyles()({
  bgExpired: {
    backgroundColor: Colors.gray47,
    backgroundImage: 'unset',
  },
  greyGradientBg: {
    backgroundImage: `linear-gradient(90deg, ${Colors.grayB3} 50%, ${Colors.grayDa} 100%)`,
  },
  passCardContainer: {
    margin: '0 20px',
    maxWidth: '90%',
    width: '90%',
  },
  logoTitleSubText: {
    fontSize: 14,
    marginTop: 5,
    color: Colors.black,
    textAlign: 'left',
  },
  logoTitleTextContainerClass: {
    paddingLeft: 3,
  },
  logoSubTitleClass: {
    color: Colors.black,
  },
  logoTitleTextClass: {
    fontSize: 16,
    paddingTop: 0,
    color: Colors.black,
    lineHeight: '20px',
    '@media (max-width: 355px)': {
      fontSize: 14,
    },
  },
  logoTitleIconClass: {
    display: 'flex',
    alignItems: 'flex-start',
    height: 20,
    width: 20,
  },
  buttonAddTitle: {
    color: Colors.white,
    fontSize: 15,
  },
  paddingCardContainer: {
    minHeight: 128,
    padding: '20px 20px 10px 20px',
    backgroundColor: Colors.white,
    borderTop: `1px solid ${Colors.darkBorderGrey}`,
    borderLeft: `1px solid ${Colors.darkBorderGrey}`,
    borderRight: `1px solid ${Colors.darkBorderGrey}`,
    boxShadow: `5px 5px 5px ${Colors.lightGrey}`,
  },
  cardUserContainer: {
    height: 50,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    display: 'flex',
  },
  cardUser: {
    color: Colors.black,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textAlign: 'left',
  },
  cardDays: {
    textAlign: 'right',
  },
  cardGrey: {
    textAlign: 'center',
    backgroundColor: Colors.lightGrey,
    display: 'inline-block',
    padding: '3px 10px',
  },
  amount: {
    color: Colors.black,
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: '17px',
    padding: '5px 0',
  },
  cardDaysGrey: {
    marginBottom: 5,
  },
  days: {
    color: Colors.black,
    fontSize: 14,
  },
  activateTitle: {
    display: 'inline-block',
    backgroundColor: Colors.lightRed,
    padding: '7px 18px',
    color: Colors.white,
    borderRadius: 5,
  },
  voucherPass: {
    textAlign: 'center',
    backgroundColor: Colors.gray94,
    padding: '15px 0',
    boxShadow: `5px 5px 5px ${Colors.lightGrey}`,
  },
  voucherPassTitle: {
    fontSize: 14,
    color: Colors.white,
    textTransform: 'capitalize',
  },
  greeting: {
    fontSize: 25,
    color: Colors.redWelcome,
    fontWeight: 'bold',
    lineHeight: '25px',
    minHeight: 40,
  },
  passOf: {
    fontSize: 13,
    fontWeight: 'bold',
  },
  dayPass: {
    textAlign: 'right',
  },
  dayAmount: {
    fontSize: 16,
  },
  dayActive: {
    fontSize: 15,
    color: Colors.red,
  },
});
