import React from 'react';
import { Skeleton, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface PassCardSkeletonProps {
  isGeneralPage?: boolean;
}

const PassCardSkeleton = ({ isGeneralPage }: PassCardSkeletonProps) => {
  const { classes, cx } = useStyles();
  return (
    <>
      <div className={classes.skeletonContainer}>
        {!isGeneralPage && (
          <Grid container xs={12}>
            <Grid item xs={6}>
              <Skeleton variant="rectangular" className={classes.greetingSkeleton} />
              <Skeleton variant="rectangular" className={classes.passCountOf} />
            </Grid>
            <Grid item xs={6} className={classes.rightSkeleton}>
              <Skeleton variant="rectangular" className={classes.greetingSkeleton} />
              <Skeleton variant="rectangular" className={cx(classes.passCountOf, classes.rightSkeletonPassCountOf)} />
            </Grid>
          </Grid>
        )}
        <Skeleton className={classes.skeleton} variant="rectangular" />
      </div>
    </>
  );
};

PassCardSkeleton.defaultProps = {
  isGeneralPage: false,
};

const useStyles = makeStyles()({
  skeletonContainer: {
    padding: '2px 20px 0',
  },
  skeleton: {
    height: 178,
    borderRadius: 5,
  },
  greetingSkeleton: {
    width: 130,
  },
  passCountOf: {
    margin: '10px 0',
    width: 80,
  },
  rightSkeleton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  rightSkeletonPassCountOf: {
    margin: '6px 0',
  },
});
export default PassCardSkeleton;
