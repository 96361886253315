import { InputAdornment, TextField } from '@mui/material';
import { ReactComponent as SearchIcon } from '../assets/images/searchIcon.svg';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { Strings } from '../constants/Strings';
import debounce from 'lodash.debounce';

interface SearchBarProps {
  changeHandle: (result: string) => void;
  paddingSearch: string;
  widthSearch: string;
}

type StylesPropsType = {
  paddingSearch: string;
  widthSearch: string;
};

const SearchBar = ({ changeHandle, paddingSearch, widthSearch }: SearchBarProps) => {
  const { classes } = useStyles({ paddingSearch, widthSearch });

  const logValue = debounce((val: string) => {
    changeHandle(val);
  }, 300);

  const onTypeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    logValue((event.target as HTMLInputElement).value);
  };
  return (
    <div>
      <TextField
        id="search-bar"
        className={classes.searchContainer}
        variant="outlined"
        size="small"
        placeholder={Strings.search}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={onTypeChanged}
      />
    </div>
  );
};

export default SearchBar;

const useStyles = makeStyles<StylesPropsType>()((_, { paddingSearch, widthSearch }) => ({
  searchContainer: {
    padding: paddingSearch,
    width: widthSearch,
  },
}));
