import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api';
import { CITY_CENTER_COORDINATES } from '../constants/Coordinates';
import MapControlButton from '../components/MapControlButton';
import { ReactComponent as ShowLocationsIcon } from '../assets/images/showLocationsIcon.svg';
import { ReactComponent as PositionsIcon } from '../assets/images/positionsIcon.svg';
import locationIcon from '../assets/images/locationIcon/locationIcon.png';
import HeaderMenu from '../components/HeaderMenu';
import { makeStyles } from 'tss-react/mui';
import BackHeader from '../components/BackHeader';
import { useNavigate } from 'react-router-dom';
import { Strings } from '../constants/Strings';
import { Colors } from '../constants/Colors';
import { GOOGLE_MAPS_MAP_OPTIONS } from '../constants/Options';
import { useMapLocationsListQuery } from '../services/apiSlice';
import InfoWindowMarker from '../components/InfoWindowMarker';

const MapPage = () => {
  //@ts-ignore
  const GOOGLE_MAPS_API_KEY = window?._env_?.REACT_APP_GOOGLE_MAPS_KEY || process.env.REACT_APP_GOOGLE_MAPS_KEY;
  const mapStyle = useMemo(() => ({ display: 'flex', minHeight: '100vh' }), []);
  const [center, setCenter] = useState<{ lat: number; lng: number }>(CITY_CENTER_COORDINATES);
  const [showUserLocation, setShowUserLocation] = useState<boolean>(false);
  const [showLocations, setShowLocations] = useState<boolean>(false);
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { data } = useMapLocationsListQuery();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  useEffect(() => {
    getUserLocation();
  }, []);

  const getUserLocation = () => {
    navigator.geolocation.getCurrentPosition(position => {
      if (position.coords) {
        setShowUserLocation(true);
        setCenter({ lat: position.coords.latitude, lng: position.coords.longitude });
      }
    });
  };

  const renderLocations = useCallback(() => {
    if (data && showLocations) {
      return data.map(item => {
        const { lat, lng, id } = item;
        const position: { lat: number; lng: number } = { lat: parseFloat(lat), lng: parseFloat(lng) };
        return (
          <InfoWindowMarker
            key={id}
            position={position}
            website={item.website}
            imageUrl={item.picture_path}
            title={item.name}
            description={item.address}
          />
        );
      });
    }
    return;
  }, [data, showLocations]);

  useEffect(() => {
    if (!!data?.length && showLocations) {
      setCenter({
        lat: Number(data?.[0]?.lat || center.lat),
        lng: Number(data?.[0]?.lng || center.lng),
      });
    }
  }, [data, showLocations]);

  return isLoaded ? (
    <>
      <div className={classes.topContainer}>
        <HeaderMenu />
        <BackHeader
          onBackPress={() => navigate(-1)}
          title={Strings.maps}
          backgroundColor={Colors.white}
          contentColor={Colors.tourismBlue}
        />
        <div className={classes.buttonsContainer}>
          <MapControlButton
            isSelected={showLocations}
            IconComponent={ShowLocationsIcon}
            onClick={() => setShowLocations(!showLocations)}
          />
          {showUserLocation && <MapControlButton IconComponent={PositionsIcon} onClick={getUserLocation} />}
        </div>
      </div>
      <GoogleMap options={GOOGLE_MAPS_MAP_OPTIONS} mapContainerStyle={mapStyle} center={center} zoom={15}>
        <>
          {showUserLocation && <Marker position={center} icon={{ url: locationIcon }} onClick={getUserLocation} />}
          {renderLocations()}
        </>
      </GoogleMap>
    </>
  ) : (
    <></>
  );
};

const useStyles = makeStyles()({
  topContainer: {
    width: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 2,
    flexDirection: 'column',
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
});

export default React.memo(MapPage);
