import React, { useEffect } from 'react';
import { Strings } from '../constants/Strings';
import BackHeader from '../components/BackHeader';
import { Colors } from '../constants/Colors';
import VenueDetail from '../components/VenueDetail';
import { makeStyles } from 'tss-react/mui';
import { useNavigate, useParams } from 'react-router-dom';
import PrimaryButton from '../components/PrimaryButton';
import { useRedeemTransactionsDetailQuery } from '../services/apiSlice';
import { CircularProgress } from '@mui/material';
import NotFoundPage from './NotFoundPage';
import { useDispatch } from 'react-redux';
import { authenticationActions } from '../services/authSlice';

const AttractionRedeemedPage = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { id } = useParams();
  const { data, isLoading: loadingTransactionDetail, isSuccess, error, isError } = useRedeemTransactionsDetailQuery(id);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccess) {
      dispatch(authenticationActions.setSuccess({ isSuccess, success: Strings.redeemSuccess }));
    }
  }, [isSuccess]);

  //@ts-ignore
  const errors = error?.data;
  const errorMessage = (errors?.error as string) || (errors?.message as string) || Strings.errorMessage;

  useEffect(() => {
    if (isError) {
      dispatch(authenticationActions.setError({ error: errorMessage, isError }));
    }
  }, [isError]);

  return (
    <>
      <BackHeader
        onBackPress={() => navigate(-2)}
        title={Strings.thingsToDo}
        backgroundColor={Colors.greenDark}
        contentColor={Colors.white}
      />
      {loadingTransactionDetail ? (
        <div className={classes.loadingCenter}>
          <CircularProgress />
        </div>
      ) : (
        <>
          {data?.location_id && data?.pass_id ? (
            <>
              <VenueDetail locationId={data?.location_id} passId={data?.pass_id} redeemedAt={data?.creation_date} />
              <div className={classes.alignCenter}>
                <PrimaryButton
                  buttonStartIcon={<></>}
                  onClick={() => navigate('/home')}
                  buttonTitle={Strings.backToHome}
                  containerStyle={classes.buttonMinWidth}
                />
              </div>
            </>
          ) : (
            <NotFoundPage />
          )}
        </>
      )}
    </>
  );
};

export default AttractionRedeemedPage;

const useStyles = makeStyles()({
  description: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '80px 28px',
    textAlign: 'center',
    fontSize: '12px',
    color: Colors.white,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingTop: '25px',
  },
  findButton: {
    textAlign: 'center',
    borderRadius: '0px',
    height: '30px',
    color: Colors.white,
    fontSize: '12px',
    border: '1px solid',
    width: '125px',
  },
  scanButton: {
    textAlign: 'center',
    borderRadius: '0px',
    height: '30px',
    backgroundColor: Colors.tourismRed,
    fontSize: '12px',
    width: '125px',
  },
  warningImage: {
    paddingTop: '25px',
  },
  textRedeem: {
    color: Colors.white,
    textAlign: 'center',
    padding: '45px 25px',
    fontSize: '12px',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '85vh',
  },
  selectPassTitle: {
    color: Colors.tourismBlue,
    marginBottom: '10px',
  },
  sheetContainer: {
    minHeight: '250px',
    backgroundColor: 'white',
    padding: '35px 27px 30px 27px',
    display: 'flex',
    flexDirection: 'column',
  },
  bottomContainer: {
    flexDirection: 'column',
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  disabled: {
    backgroundColor: 'blue',
  },
  alignCenter: {
    textAlign: 'center',
    margin: '45px 0',
  },
  buttonMinWidth: {
    minWidth: '91vw',
  },
  loadingCenter: {
    textAlign: 'center',
    marginTop: 30,
  },
});
