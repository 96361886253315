import React, { useEffect } from 'react';
import { Strings } from '../constants/Strings';
import { Colors } from '../constants/Colors';
import { Button, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useActivePassesQuery } from '../services/apiSlice';
import { BottomSheet } from 'react-spring-bottom-sheet';
import PrimaryButton from './PrimaryButton';
import { ReactComponent as ScanSvg } from '../assets/images/scan.svg';
import { useSelector } from 'react-redux';
import { getActivePasses } from '../services/passSlice';

interface BottomSheetScanVenueProps {
  scanButtonClaass?: string;
  scanTitle?: string;
}

const BottomSheetScanVenue = ({ scanButtonClaass, scanTitle }: BottomSheetScanVenueProps) => {
  const { classes, cx } = useStyles();
  const { data: activePasses, refetch: refetchActivePass } = useActivePassesQuery();
  const [value, setValue] = React.useState<string | null>(null);
  const [disabled, setDisable] = React.useState<boolean>(true);
  const [bottomSheetVisible, setBottomSheetVisible] = React.useState<boolean>(false);
  const newActivePasses = useSelector(getActivePasses);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    if (bottomSheetVisible || newActivePasses.length) {
      refetchActivePass();
    }
  }, [bottomSheetVisible, newActivePasses]);

  useEffect(() => {
    if (activePasses?.length) {
      setDisable(false);
    }
  }, [activePasses]);

  return (
    <>
      <PrimaryButton
        onClick={() => {
          try {
            navigator.mediaDevices.getUserMedia({ video: true })
            .then(() => {
              setBottomSheetVisible(true)
            })
            .catch(() => alert('Please allow the camera!')) 
          } catch (error: any) {
            alert(`Camera not supported!`)
          }
        }}
        disabled={disabled}
        containerStyle={cx(
          classes.scanButton,
          disabled && classes.scanButtonDisabled,
          scanButtonClaass && scanButtonClaass
        )}
        buttonStartIcon={<ScanSvg className={classes.scanSvgIcon} />}
        buttonTitle={scanTitle ? scanTitle : Strings.scan}
        buttonTitleStyle={classes.buttonTitle}
      />
      <BottomSheet open={bottomSheetVisible} onDismiss={() => setBottomSheetVisible(false)}>
        <div className={classes.sheetContainer}>
          <Typography className={classes.selectPassTitle}>{Strings.selectPass}</Typography>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={handleChange}
            >
              {activePasses &&
                activePasses.map((item, index) => (
                  <FormControlLabel key={index} value={item.pass_id} control={<Radio />} label={item.pass_name} />
                ))}
            </RadioGroup>
          </FormControl>
          <div className={classes.bottomContainer}>
            <Button disabled={!value} variant={'contained'}
              onClick={() => window.location.href = `/attraction-scan/${value}`}>
              {Strings.scanQRCode}
            </Button>
          </div>
        </div>
      </BottomSheet>
    </>
  );
};

export default BottomSheetScanVenue;

const useStyles = makeStyles()({
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingTop: '25px',
  },
  selectPassTitle: {
    color: Colors.tourismBlue,
    marginBottom: '10px',
  },
  sheetContainer: {
    minHeight: '250px',
    backgroundColor: 'white',
    padding: '35px 27px 30px 27px',
    display: 'flex',
    flexDirection: 'column',
  },
  bottomContainer: {
    flexDirection: 'column',
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  disabled: {
    backgroundColor: 'blue',
  },
  scanButton: {
    marginRight: 10,
    width: `90px !important`,
    height: `35px !important`,
  },
  scanButtonDisabled: {
    backgroundColor: `${Colors.greyScan} !important`,
    color: `${Colors.white} !important`,
  },
  scanSvgIcon: {
    height: 11,
    width: 13,
  },
  buttonTitle: {
    fontSize: 12,
  },
  buttonTourismRed: {
    backgroundColor: `${Colors.tourismRed} !important`,
  },
});
