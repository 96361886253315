export const usefulLinks = {
  instagram: `https://www.instagram.com/antwerpen/`,
  twitter: `https://twitter.com`,
  facebook: `https://www.facebook.com/visitantwerp`,
  linkedin: `https://www.linkedin.com/company/visit-antwerpen/`,
  visitAntwerpLink: 'https://visit.antwerpen.be/en/info/antwerp-city-pass-terms-conditions',
  visitAntwerpHomeLink: 'http://visit.antwerpen.be',
  googleMaps: `https://maps.google.com`,
  unbox: 'https://www.unboxuniverse.com/',
  homeUat: 'https://www.uat.acp.unboxuniverse.io/',
  homeProd: 'http://www.antwerpcitypass.be/',
  homeDev: 'https://www.dev.acp.unboxuniverse.io/',
};
