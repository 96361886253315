/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { ReactComponent as AntwerpLogoIcon } from '../assets/images/antwerpLogo.svg';
import { Strings } from '../constants/Strings';
// import { Colors } from '../constants/Colors';
import { useSearchParams } from 'react-router-dom';
import {
  Container, 
  Grid, 
  Typography,
} from '@mui/material';
import { PASS_PER_HOURS, TAX_PER_PASS } from '../constants/Options';
import { Colors } from '../constants/Colors';
import PrimaryButton from '../components/PrimaryButton';
/*
import { useConfirmPaymentIntentMutation } from '../services/apiSlice';
import { useDispatch } from 'react-redux';
import { authenticationActions } from '../services/authSlice';
*/
import { usefulLinks } from '../constants/UsefulLinks';


const PaymentSuccessPage = () => {
  const [numberOfPasses, setNumberOfPasses] = useState<number>(1)
  const [selectedHours, setSelectedHours] = useState<number>(24)
  const [summaryPass, setSummaryPass] = useState<any>('')
  const { classes } = useStyles();

  /*
  const dispatch = useDispatch();
  const [confirmPaymentIntent, {
    isSuccess,
    isLoading,
    isError,
    error,
  }] = useConfirmPaymentIntentMutation();
  */

  const [searchParams, setSearchParams] = useSearchParams();

  const hours = searchParams.get('hours');
  const qty = searchParams.get('qty');
  const paymentIntentId = searchParams.get('payment_intent');
  
  /*
  const errorMessage = error?.data.message as string || 'Something went wrong!';

  useEffect(() =>{
    if (isError) {

      dispatch(authenticationActions.setError({
        error: errorMessage,
        isError: isError,
      }))
    }

    if (isSuccess) {
      searchParams.delete('payment_intent');
      searchParams.delete('payment_intent_client_secret')
      searchParams.delete('redirect_status');
      setSearchParams(searchParams);
    }
  }, [isError, isSuccess])
  */

  useEffect(() => {
    if (hours && PASS_PER_HOURS.map(h=> h.hour).includes(Number(hours))) {
      setSelectedHours(Number(hours));
    } else {
      setSelectedHours(PASS_PER_HOURS[0].hour)
    }
    
    if(qty) {
      setNumberOfPasses(Number(qty))
    }

    /*
    if (hours && qty && paymentIntentId) {
      handleSubmitGeneratePassesByPaymentIntent();
    }
    */
  }, [hours, qty, paymentIntentId])

  useEffect(() => {
    const summary = `${selectedHours}h x ${numberOfPasses}`
    setSummaryPass(summary)
  }, [selectedHours, numberOfPasses])

  /*
  const handleSubmitGeneratePassesByPaymentIntent = async () => {
    await confirmPaymentIntent({
      pi_id: paymentIntentId as string,
      validity: Number(hours) / 24,
      number_of_passes: Number(qty),
    })
  }
  */

  const calcSubtotal = () => {
    const total = numberOfPasses * PASS_PER_HOURS.filter(d => d.hour === selectedHours)[0].price
    return `${Strings.euro}${total}`;
  }

  const calcTotal = () => {
    const subtotal = numberOfPasses * PASS_PER_HOURS.filter(d => d.hour === selectedHours)[0].price
    return `${Strings.euro}${subtotal}`;
  }

  const onLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userEmail');

    backtoLP();
  }

  const backtoLP = () => {
    const currentUrl = window.location.href;

    switch(true){
      case currentUrl.includes("localhost"):
        window.location.assign(`/`);
        break;
      case currentUrl.includes("dev"):
        window.location.assign(usefulLinks.homeDev)
        break;
      case currentUrl.includes("uat"):
        window.location.assign(usefulLinks.homeUat)
        break;
      default:
        window.location.assign(usefulLinks.homeProd)
    }
  }

  return (
    <>
    <Container maxWidth="lg" className={classes.container}>
      <div className={classes.content}>
        <div className={classes.header}>
          <AntwerpLogoIcon />
          <Typography className={classes.paymentSuccess}>{Strings.paymentSuccess}</Typography>
        </div>
        <div className={classes.summaryBox}>
          <div className={classes.titleOrder}>
            <Typography className={classes.subTitle}>{Strings.summary}</Typography>
          </div>
          <div className={classes.amountSubTotal}>
            <Grid container>
              <Grid item xs={8}>
                <Typography>{Strings.headerTitle}{' '}{summaryPass}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>{calcSubtotal()}</Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={8}>
                <Typography>{Strings.tax}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  {TAX_PER_PASS}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={8}>
                <Typography className={classes.total}>{Strings.total}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.total}>{calcTotal()}</Typography>
              </Grid>
            </Grid>
          </div>
        </div>
        <div>
          <Typography className={classes.passesBeenSent}>{Strings.passesBeenSent}</Typography>
          <PrimaryButton
            onClick={onLogout}
            buttonStartIcon={true}
            buttonTitle={Strings.logoutToHomepage}
            containerStyle={classes.buttonLogout}
          />
        </div>
      </div>
    </Container>
    </>
  );
};

export default PaymentSuccessPage;

const useStyles = makeStyles()({
  subTitle: {
    fontSize: 20,
    marginBottom: '8px'
  },
  container: {
    margin: '90px auto',
    maxWidth: '420px',
  },
  header: {
    textAlign: 'center',
  },
  paymentSuccess: {
    fontSize: '32px',
    fontWeight: 500,
    textAlign: 'center',
    margin: '0 0 30px',
  },
  amountSubTotal: {
    '.MuiGrid-root': {
      margin: '5px 0',
      p: {
        fontSize: 14,
      },
      '&:nth-child(3)': {
        borderTop: `1px solid ${Colors.grayB3}`,
        paddingTop: 5,
      }
    },
    '.MuiGrid-grid-xs-4': {
      display: 'flex',
      justifyContent: 'right',
    }
  },
  summaryBox: {
    border: `1px solid ${Colors.grayB3}`,
    borderRadius: 5,
    padding: 15,
  },
  titleOrder: {},
  content: {
    maxWidth: 600,
    margin: '0 auto',
  },
  passesBeenSent: {
    textAlign: 'center',
    margin: "12px 0 28px",
    fontWeight: 500,
    color: Colors.gray47
  },
  total: {
    fontWeight: 500,
    fontSize: '20px',
  },
  buttonLogout: {
    width: '100%',
  },
});
