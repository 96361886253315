import React, { useCallback, useEffect, useState } from 'react';
import BackHeader from '../components/BackHeader';
import HeaderMenu from '../components/HeaderMenu';
import { Colors } from '../constants/Colors';
import { Strings } from '../constants/Strings';
import { useNavigate } from 'react-router-dom';
import { Grid, Tab, Tabs, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import TabPanel from '../components/TabPanel';
import { useLocationListMutation } from '../services/apiSlice';
import ThingsToDoCard from '../components/ThingsTodoCard';
import { VoucherType } from '../types/locationListTypes';
import SearchBar from '../components/SearchBar';
import SkeletonCard from '../components/SkeletonCard';
import { useDispatch } from 'react-redux';
import { authenticationActions } from '../services/authSlice';

const ThingsToDoPage = () => {
  const navigate = useNavigate();
  const { classes, cx } = useStyles();
  const [value, setValue] = React.useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [getLocationsList, { data: locationListData, isLoading, isError, error }] = useLocationListMutation();
  const dispatch = useDispatch();

  //@ts-ignore
  const errors = error?.data;
  const errorMessage =
    (errors?.errors?.key?.[0] as string) ||
    (errors?.error as string) ||
    (errors?.message as string) ||
    Strings.errorMessage;

  const handleChangeSearch = (searchText: string) => {
    setSearchTerm(searchText);
  };

  useEffect(() => {
    getLocationsList(searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    if (isError) {
      dispatch(authenticationActions.setError({ isError, error: errorMessage }));
    }
  }, [isError]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const a11yProps = useCallback((index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }, []);

  const renderList = (cards: VoucherType[], type: string) => (
    <Grid container rowSpacing={2} className={classes.gridContainer}>
      {cards.length ? (
        cards
          .filter(item => {
            if (searchTerm == '') {
              return item;
            }
            if (item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
              return item;
            }
          })
          .map(item => {
            let displayStatus = type === Strings.discount ? item?.discount || '%' : Strings.included;
            if (type === 'Voucher') {
              displayStatus = item?.discount && item?.discount !== '100%' ? item?.discount : Strings.included;
            }
            return (
              <Grid item sm={6} key={item.id}>
                <ThingsToDoCard
                  width={window.innerWidth / 2 - 18}
                  height={'70px'}
                  title={item.name}
                  status={displayStatus}
                  type={type === 'Included' ? Strings.voucher : type}
                  image={item.picture_path}
                  onClick={() => navigate(`/attraction-details/${item.id}`)}
                />
              </Grid>
            );
          })
      ) : (
        <div className={classes.notFound}>
          <Typography>{Strings.notFound}</Typography>
        </div>
      )}
    </Grid>
  );

  const renderSekelton = () => (
    <Grid container rowSpacing={2} className={cx(classes.gridContainer, classes.skeletonContainer)}>
      {Array.from(Array(7).keys()).map(index => (
        <Grid item sm={6} key={index}>
          <SkeletonCard width={window.innerWidth / 2 - 18} height={'70px'} />
        </Grid>
      ))}
    </Grid>
  );

  // @ts-ignore
  return (
    <>
      <HeaderMenu />
      <BackHeader
        title={Strings.thingsToDo}
        backgroundColor={Colors.greenDark}
        contentColor={Colors.white}
        onBackPress={() => navigate(-1)}
      />
      <SearchBar changeHandle={handleChangeSearch} paddingSearch={'10px'} widthSearch={'95vw'} />

      {isLoading || !locationListData ? (
        renderSekelton()
      ) : (
        <>
          {locationListData[0]?.Included ? (
            <>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant={'fullWidth'}>
                <Tab className={classes.tab} label={Strings.included} {...a11yProps(0)} />
                <Tab className={classes.tab} label={Strings.discount} {...a11yProps(1)} />
              </Tabs>
              <TabPanel value={value} index={0}>
                {
                  //@ts-ignore
                  // eslint-disable-next-line no-unsafe-optional-chaining
                  renderList(locationListData[0]?.Included || [], Strings.included)
                }
              </TabPanel>
              <TabPanel value={value} index={1}>
                {
                  //@ts-ignore
                  renderList(locationListData[1]?.Discount || [], Strings.discount)
                }
              </TabPanel>
            </>
          ) : (
            <>
              <div className={classes.searchList}>
                {
                  //@ts-ignore
                  renderList(locationListData || [], 'Voucher')
                }
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

const useStyles = makeStyles()({
  tab: {
    color: Colors.black,
    textDecoration: 'none',
    '&.Mui-selected': {
      color: Colors.tourismBlue,
    },
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '85vh',
  },
  searchList: { marginTop: '10px' },
  gridContainer: {
    justifyContent: 'space-between',
    marginLeft: '4px',
  },
  skeletonContainer: {
    marginTop: 15,
  },
  notFound: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
});

export default ThingsToDoPage;
