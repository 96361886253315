import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Colors } from '../constants/Colors';
import { List } from '@mui/material';
import { FaqType } from '../types/faqTypes';
import FaqListCollapseItem from './FaqListCollapseItem';

const FaqListItem = ({ items }: FaqType) => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <List>
        {items.map((item, index) => (
          <FaqListCollapseItem {...item} key={index} />
        ))}
      </List>
    </div>
  );
};
export default FaqListItem;

const useStyles = makeStyles()({
  container: {
    marginTop: 30,
    '&:first-child': {
      marginTop: 20,
    },
  },
  mainTitle: {
    color: Colors.tourismBlue,
    fontWeight: 'bold',
    paddingLeft: 15,
    marginBottom: 20,
  },
});
