import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { api } from '../services/apiSlice';
import authReducer from '../services/authSlice';
import passReducer from '../services/passSlice';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    authReducer,
    passReducer, 
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(api.middleware),
});

export type RootState = ReturnType<typeof store.getState>;

setupListeners(store.dispatch);
