import React, { ReactNode, useEffect, useState } from 'react';
import { Drawer, Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Colors } from '../constants/Colors';
import { makeStyles } from 'tss-react/mui';
import { ReactComponent as HomeSvg } from '../assets/images/HomeIconDrawer.svg';
import { ReactComponent as MapsSvg } from '../assets/images/maps-drawer.svg';
import { ReactComponent as ThingsToDoSvg } from '../assets/images/ThingsToDoDrawerIcon.svg';
import { ReactComponent as GeneralSvg } from '../assets/images/general-drawer.svg';
import { ReactComponent as HistorySvg } from '../assets/images/history-icon.svg';
import { HeaderMenuDrawer } from '../constants/Menu';
import { ReactComponent as HamburgerSvg } from '../assets/images/hamburger.svg';
import { Link, useLocation } from 'react-router-dom';

const DrawerMenu = () => {
  const { classes, cx } = useStyles();
  const [toggleDrawer, setToggleDrawer] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<string>('');
  const location = useLocation();

  useEffect(() => {
    setActivePage(location.pathname);
    setToggleDrawer(false);
  }, [location.pathname]);

  const renderIconMenuDrawer = (value: string): ReactNode => {
    switch (value) {
      case 'Home':
        return <HomeSvg className={classes.drawerIcon} />;
      case 'Things To Do':
        return <ThingsToDoSvg className={cx(classes.drawerIcon, classes.thingsTodoIcon)} />;
      case 'History':
        return <HistorySvg className={cx(classes.drawerIcon)} />;
      case 'Maps':
        return <MapsSvg className={cx(classes.drawerIcon, classes.mapIcon)} />;
      case 'General':
        return <GeneralSvg className={classes.drawerIcon} />;
    }
  };
  return (
    <>
      <HamburgerSvg className={classes.hamburgerIcon} onClick={() => setToggleDrawer(!toggleDrawer)} />
      <Drawer anchor="right" open={toggleDrawer} onClose={() => setToggleDrawer(false)}>
        <Box>
          <List>
            {HeaderMenuDrawer.map((header, index) => (
              <Link to={header.link} key={index} className={cx(classes.noTextDecoration, classes.textColor)}>
                <ListItem disablePadding className={classes.container}>
                  <ListItemButton className={classes.listItemButton}>
                    <ListItemIcon className={classes.listItemIcon}>{renderIconMenuDrawer(header.text)}</ListItemIcon>
                    <ListItemText
                      primary={header.text}
                      classes={{ primary: header.link === activePage ? classes.textBold : classes.textNormal }}
                    />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default DrawerMenu;

const useStyles = makeStyles()({
  textNormal: {
    fontWeight: 'normal',
  },
  textBold: {
    fontWeight: 'bold',
  },
  noTextDecoration: {
    textDecoration: 'none',
  },
  textColor: {
    color: Colors.darkGrey,
  },
  container: {
    borderBottom: `1px solid ${Colors.lightGrey}`,
  },
  hamburgerIcon: {
    width: 30,
    height: 35,
  },
  listItemButton: {
    padding: '15px 40px',
  },
  listItemIcon: {
    minWidth: 40,
  },
  mapIcon: {
    width: 19,
    height: 15,
  },
  thingsTodoIcon: {
    width: 25,
    height: 30,
  },
  drawerMenuPNG: {
    width: 30,
    height: 40,
  },
  drawerIcon: {
    width: 25,
    height: 20,
  },
});
