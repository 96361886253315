import { Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as BackIcon } from '../assets/images/backIcon.svg';
import { makeStyles } from 'tss-react/mui';

interface BackHeaderProps {
  onBackPress: () => void;
  title: string;
  backgroundColor: string;
  contentColor: string;
}

type StylesPropsType = {
  backgroundColor: string;
  contentColor: string;
};

const BackHeader = ({ title, contentColor, backgroundColor, onBackPress }: BackHeaderProps) => {
  const { classes } = useStyles({ backgroundColor, contentColor });

  return (
    <div className={classes.container}>
      <div className={classes.backButtonContainer}>
        <BackIcon onClick={onBackPress} fill={contentColor} />
      </div>
      <Typography className={classes.text}>{title}</Typography>
    </div>
  );
};

const useStyles = makeStyles<StylesPropsType>()((_, { backgroundColor, contentColor }) => ({
  container: {
    width: '100vw',
    backgroundColor: backgroundColor,
    height: '70px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  backButtonContainer: {
    position: 'absolute',
    left: '24px',
  },
  text: {
    fontWeight: '700',
    fontSize: '22px',
    color: contentColor,
  },
}));

export default BackHeader;
