import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Grid, Typography } from '@mui/material';
import { Colors } from '../constants/Colors';
import { Strings } from '../constants/Strings';

const DescriptionAddButton = () => {
  const { classes } = useStyles();
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.description}>
        <Typography className={classes.descriptionText}>{Strings.thePerfectWayTo}</Typography>
      </Grid>
    </Grid>
  );
};

export default DescriptionAddButton;

const useStyles = makeStyles()({
  container: {
    padding: '0 25px',
    marginTop: 5,
    marginBottom: 25,
    textAlign: 'center',
  },
  description: {
    marginTop: 20,
  },
  descriptionText: {
    fontSize: 14,
    lineHeight: '25px',
    color: Colors.darkGrey,
  },
});
