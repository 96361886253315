/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
// import BackHeader from '../components/BackHeader';
import { useDispatch, useSelector } from 'react-redux';
import { Strings } from '../constants/Strings';
// import { Colors } from '../constants/Colors';
import { useSearchParams } from 'react-router-dom';
import { 
  Checkbox, 
  Container, 
  FormControlLabel, 
  Grid, 
  Typography, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails, 
  Radio,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import Header from '../components/Header';
import BreadCrumbs from '../components/BreadCrumbs';
import { CHECKOUT_STEPS, PASS_PER_HOURS, TAX_PER_PASS } from '../constants/Options';
import { Colors } from '../constants/Colors';
import { loadStripe } from "@stripe/stripe-js";
import PrimaryButton from '../components/PrimaryButton';
import { useGeneratePassesAfterPaymentMutation } from '../services/apiSlice';
import { authenticationActions, selectUserEmail } from '../services/authSlice';
import {
  PaymentElement,
  useStripe,
  useElements,
  Elements as StripeElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  LinkAuthenticationElement,
} from "@stripe/react-stripe-js";
import imgVisa from '../assets/images/payment-visa.png';
import imgMastercard from '../assets/images/payment-mc.png';
import imgAmex from '../assets/images/payment-amex.png';
import imgJcb from '../assets/images/payment-jcb.png';
import imgPaypal from '../assets/images/payment-paypal.png';
import { environment } from '../config/environment';
import cardIcon from '../assets/images/credit-card.svg';

const CheckoutForm = forwardRef((props: any, ref) => {
  const { setStripeToken } = props;
  const stripe = useStripe();
  const elements = useElements();
  const { classes, cx } = useStyles();

  useImperativeHandle(ref, () => ({
    getStripeToken,
  }));

  const getStripeToken: any = async () => {
    const card: any = elements && elements.getElement(CardNumberElement);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const stripeToken: any= stripe && await stripe.createPaymentMethod({
      type: 'card',
      card: card,
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setStripeToken(stripeToken?.paymentMethod?.id);
  }
  
  return (
    <div className={classes.cardFormStripe}>
      <div className={classes.cardFormWrapper}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <div className={classes.cardFormContent}>
              <p className={classes.cardFormLabel}>Card Number</p>
              <div className={cx(classes.inputWrapper)}>
                <CardNumberElement onBlur={getStripeToken}
                  options={{
                    showIcon: true
                  }}
                  className={classes.cardNumber} />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.cardFormContent}>
              <p className={classes.cardFormLabel}>Expiration Date</p>
              <div className={cx(classes.inputWrapper)}>
                <CardExpiryElement onBlur={getStripeToken} className={classes.cardExpiry} />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.cardFormContent}>
              <p className={classes.cardFormLabel}>Security Code</p>
              <div className={cx(classes.inputWrapper)}>
                <CardCvcElement onBlur={getStripeToken} className={classes.cardCvc} />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.cardFormContent}>
              <p className={classes.cardFormLabel}>Cardholder Name</p>
              <div className={cx(classes.inputWrapperName, classes.inputWrapper)}>
                <input onBlur={getStripeToken} className={classes.cardName} />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
})

const PaymentIntentForm = forwardRef((props: any, ref) => {
  const stripe = useStripe();
  const elements = useElements();
  const {
    numberOfPasses,
    selectedHours,
    setLoading,
    setMessage,
    userEmail,
  } = props

  useImperativeHandle(ref, () => ({
    handleConfirmCardPayment,
  }));

  const handleConfirmCardPayment = async () => {
    if (!stripe || !elements) {
      console.log('stripe or elements not found');
    }
    setLoading(true);
    try {
      const response = await stripe?.confirmPayment({
        //@ts-ignore
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/payment/success?hours=${selectedHours}&qty=${numberOfPasses}`
        }
      })
  
      if (response?.error) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw {
          message: response?.error?.message
        }
      }
    } catch (error: any) {
      setMessage(error?.message as string);
      setLoading(false);
    }
  }

  return <>
    <LinkAuthenticationElement options={{
      defaultValues: {
        email: userEmail,
      }
    }}/>
    <PaymentElement />
  </>
})

const PaymentPage = () => {
  const [generatePasses, { 
    data: paymentIntentData, isSuccess, 
    isLoading, isError, error 
  }] = useGeneratePassesAfterPaymentMutation();
  const [numberOfPasses, setNumberOfPasses] = useState<number>(1)
  const [selectedHours, setSelectedHours] = useState<number>(24)
  const [snackbar, setSnackbar] = useState<any>({
    open: false,
    message: '',
    type: '',
  })
  const { classes } = useStyles();
  const [searchParams] = useSearchParams();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isAggree, setIsAggree] = useState<boolean>(true);
  const [expanded, setExpanded] = useState<string | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const stripePromise = loadStripe(environment?.REACT_APP_STRIPE_PK)
  const dispatch = useDispatch();
  
  const userEmail = useSelector(selectUserEmail);
  const disablePaymentCard = true;

  const ref = useRef<any>();
  const paymentElementRef = useRef<any>();


  const hours = searchParams.get('hours');
  const qty = searchParams.get('qty');
  //@ts-ignore
  const errorMessage = error?.data.message as string || 'Something went wrong!';

  useEffect(() => {
    if (expanded === 'stripe-element') {
      handlePaymentIntentInitialCreation()
    }
  }, [expanded])
  
  useEffect(() => {
    if (hours && PASS_PER_HOURS.map(h=> h.hour).includes(Number(hours))) {
      setSelectedHours(Number(hours));
    } else {
      setSelectedHours(PASS_PER_HOURS[0].hour)
    }
    
    if(qty) {
      setNumberOfPasses(Number(qty))
    }
  }, [hours, qty])

  useEffect(() => {

    if(isError){
      dispatch(authenticationActions.setError({
        error: errorMessage,
        isError: isError,
      }))
    }
  }, [isSuccess, isLoading, isError])

  const calcSubtotal = () => {
    return numberOfPasses * PASS_PER_HOURS.filter(d => d.hour === selectedHours)[0].price
  }

  const calcTotal = () => {
    const subtotal = numberOfPasses * PASS_PER_HOURS.filter(d => d.hour === selectedHours)[0].price
    return `${Strings.euro}${subtotal}`;
  }

  const handlePaymentIntentInitialCreation = async () => {
    setSnackbar(true);
    const passData = {
      checkout_currency: "eur",
      checkout_amount: calcSubtotal(),
      email: userEmail,
      validity: Number(hours) / 24,
      quantity: Number(qty),
    }
    await generatePasses(passData);
  }

  const onHideSnackbar = () => {
    setSnackbar({
      open: false,
      message: '',
      type: '',
    })
  }


  const onSetToken = (stripeToken: string) => {
    console.log(stripeToken)
  }

  const onConfirmPayment = () => {
    try {
      paymentElementRef.current.handleConfirmCardPayment(); 
    } catch (err: any) {
      dispatch(authenticationActions.setError({
        error: Strings.pleaseSelectPM,
        isError: true,
      }))
    }
  }

  return (
    <>
    <Container maxWidth="lg" className={classes.checkoutContainer}>
      <BreadCrumbs items={CHECKOUT_STEPS} size={2}
        lineSize={3}
        active={1} containerStyle={classes.breadCrumbContainerStyle}/>
      <Grid container className={classes.contentTitle}>
        <Grid item xs={12}>
          <Typography className={classes.title}>Payment method</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.description}>This is the last page before paying, 
          make sure the information you enter is correct.</Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.product} gap={2}>
        <Grid item xs={12} md={6}>
          <Typography className={classes.subTitle}>Select Method</Typography>
          <div>
            {
              !disablePaymentCard && 
            <Accordion 
              expanded={expanded === 'stripe'}
              className={classes.accordionWrapper}>
              <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className={classes.accordionItem}>
                  <FormControlLabel value="debit-credit" 
                    control={<Radio size='small' checked={expanded === 'stripe'}/>}
                    onChange={() => setExpanded('stripe')}
                    label="Debit Card/Credit Card" 
                  />
                  <div>
                    <img src={imgVisa} alt="visa" className={classes.imgPayment} />
                    <img src={imgMastercard} alt="mastercard" className={classes.imgPayment}  />
                    <img src={imgAmex} alt="amex" className={classes.imgPayment}  />
                    <img src={imgJcb} alt="jcb" className={classes.imgPayment}  />
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
              <StripeElements stripe={stripePromise}>
                <CheckoutForm ref={ref} setStripeToken={onSetToken} />
              </StripeElements>
              </AccordionDetails>
            </Accordion>
            }
            <Accordion 
              expanded={expanded === 'stripe-element'}
              className={classes.accordionWrapper}>
              <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className={classes.accordionItem}>
                  <FormControlLabel value="debit-credit" 
                    control={<Radio size='small' checked={expanded === 'stripe-element'}/>}
                    onChange={() => setExpanded('stripe-element')}
                    label="Debit Card/Credit Card" 
                  />
                  <div>
                    <img src={imgVisa} alt="visa" className={classes.imgPayment} />
                    <img src={imgMastercard} alt="mastercard" className={classes.imgPayment}  />
                    <img src={imgAmex} alt="amex" className={classes.imgPayment}  />
                    <img src={imgJcb} alt="jcb" className={classes.imgPayment}  />
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {
                  expanded === 'stripe-element' && isLoading && <div className={classes.loadIndicatorCard}>
                    <CircularProgress color="inherit" />
                  </div>
                }
                {
                  expanded === 'stripe-element' && paymentIntentData?.client_secret &&
                  <StripeElements stripe={stripePromise} options={{
                    clientSecret: paymentIntentData?.client_secret
                  }}>
                    <PaymentIntentForm
                      ref={paymentElementRef}
                      selectedHours={selectedHours}
                      numberOfPasses={numberOfPasses}
                      userEmail={userEmail}
                      setLoading={(val: boolean) => setIsSubmitting(val)}
                      setMessage={(message: string) => {
                        dispatch(authenticationActions.setError({
                          error: message,
                          isError: true,
                        }))
                      }}
                    />
                  </StripeElements>
                }
              </AccordionDetails>
            </Accordion>
            <Accordion 
              disabled
              className={classes.accordionWrapper} >
              <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <div className={classes.accordionItem}>
                  <FormControlLabel value="paypal" 
                    control={<Radio size='small' />} 
                    label="Paypal" 
                  />
                  <div>
                    <img src={imgPaypal} alt="paypal" className={classes.imgPayment}  />
                  </div>
                </div>
              </AccordionSummary>
            </Accordion>
          </div>
        </Grid>
        <Grid item xs={12} md={5}>
          <div className={classes.orderBox}>
            <div className={classes.titleOrder}>
              <Typography className={classes.subTitle}>{Strings.yourOrder}</Typography>
            </div>
            <Grid container>
              <Grid item xs={3} className={classes.cardOrderContainer}>
                <Header noLogo containerClass={classes.headerRedCardOrder}/>
              </Grid>
              <Grid item xs={7} className={classes.purhcaseDetail}>
                <div className={classes.purchaseTitle}>
                  <Typography>
                    {Strings.headerTitle} {selectedHours}
                  </Typography>
                </div>
                <div className={classes.purchaseAmount}>
                  <Typography>
                    {Strings.quantity}{numberOfPasses}
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <div className={classes.amountSubTotal}>
              <Grid container>
                <Grid item xs={8}>
                  <Typography>{Strings.subTotal}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography>{`${Strings.euro}${calcSubtotal()}`}</Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={8}>
                  <Typography>{Strings.tax}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography>
                    {TAX_PER_PASS}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={8}>
                  <Typography>{Strings.total}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography>{calcTotal()}</Typography>
                </Grid>
              </Grid>
            </div>
          </div>
          <div>
            <div className={classes.agreeCheckbox}>
              <FormControlLabel style={{ marginRight: 0 }} control={
                <Checkbox
                  onChange={(e) => setIsAggree(!e.target.checked)}
                />} label="" />
              <p>I agree to the 
                <a 
                  href='https://visit.antwerpen.be/en/info/antwerp-city-pass-terms-conditions' 
                  target='_blank' rel='noreferrer'>Terms of Use</a> 
                 &  
                <a href='https://visit.antwerpen.be/en/info/privacy-policy' 
                  target='_blank' rel='noreferrer'>
                Privacy Policy </a> 
              </p>
            </div>
            <PrimaryButton
              onClick={onConfirmPayment}
              buttonStartIcon={true}
              buttonTitle={!isSubmitting ? Strings.continue : Strings.loading}
              containerStyle={classes.buttonFullWidth}
              disabled={isAggree || isSubmitting}
            />
          </div>
        </Grid>
      </Grid>
    </Container>
    <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={onHideSnackbar}>
      <Alert onClose={onHideSnackbar} severity={snackbar.type} sx={{ width: '100%' }}>
        {snackbar.message}
      </Alert>
    </Snackbar>
    </>
  );
};

export default PaymentPage;

const useStyles = makeStyles()({
  loadIndicatorCard: {
    textAlign: 'center',
  },
  cardFormStripe: {
    width: '100%',
  },
  cardNumber: {},
  cardExpiry: {},
  cardCvc: {
    position: 'relative',
    '&:after': {
      content: "' '",
      position: 'absolute',
      top: -5,
      right: 0,
      width: 24,
      height: 23,
      backgroundImage: `url(${cardIcon})`,
      backgroundRepeat: 'no-repeat',
    }
  },
  cardName: {
    border: 'none',
    width: '100%',
    '&:focus': {
      outline: 'none',
    },
  },
  cardFormLabel: {
    marginBottom: 10,
    marginTop: 0,
    fontWeight: 500,
  },
  headerRedCard: {
    width: '50%',
    // backgroundImage: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #D0103B',
    height: 125,
    borderRadius: 5,
    '.MuiTypography-root': {
      color: Colors.white,
    }
  },
  redCard: {
    border: `1px solid ${Colors.greyBorder}`
  },
  product: {
    marginTop: 45,
  },
  subTitle: {
    fontSize: 20,
    marginBottom: '8px'
  },
  title: {
    fontSize: 30,
  },
  description: {
    fontSize: 14,
    lineHeight: '20px',
    marginTop: 15,
    color: Colors.gray47,
  },
  contentTitle: {
    marginTop: 30,
  },
  breadCrumbContainerStyle: {
    width: '100%',
  },
  checkoutContainer: {
    margin: '0 auto',
    marginBottom: 90,
  },
  amountSubTotal: {
    '.MuiGrid-root': {
      margin: '5px 0',
      p: {
        fontSize: 14,
      },
      '&:nth-child(3)': {
        borderTop: `1px solid ${Colors.grayB3}`,
        paddingTop: 5,
      }
    },
    '.MuiGrid-grid-xs-4': {
      display: 'flex',
      justifyContent: 'right',
    }
  },
  orderBox: {
    border: `1px solid ${Colors.grayDa}`,
    padding: 15,
    borderRadius: 0,
  },
  titleOrder: {},
  cardOrderContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headerRedCardOrder: {
    backgroundColor: Colors.redCheckoutCard,
    '.MuiTypography-root': {
      color: Colors.white,
      fontSize: 8,
    },
    svg: {
      width: 12,
    }
  },
  purhcaseDetail: {
    padding: 10,
  },
  purchaseTitle: {

  },
  purchaseAmount: {
    color: Colors.grayB3,
    p: {
      fontSize: 14,
    }
  },
  buttonFullWidth: {
    width: '100%',
  },
  cardFormWrapper: {
    display: 'flex',
    gap: 8,
    width: '100%',
  },
  cardFormContent: {
    width: '100%'
  },
  inputCardNumber: {
    border: `1px solid ${Colors.grayB3}`,
  },
  inputWrapper: {
    border: `1px solid ${Colors.grayB3}`,
    borderRadius: '5px',
    padding: '12px 16px', 
  },
  inputWrapperName: {
    padding: '11px 16px !important',
  },
  accordionItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  imgPayment: {
    width: 50,
  },
  accordionWrapper: {
    border: `1px solid ${Colors.grayB3}`,
    boxShadow: 'none',
    marginBottom: 20,
    borderRadius: '0 !important',
  },
  agreeCheckbox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
    p: {
      fontSize: 14,
      color: Colors.gray47,
      margin: 0,
    },
    a: {
      textDecoration: 'none',
      color: Colors.lightRed,
      margin: '0 4px'
    }
  }
});
