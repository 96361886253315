import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Colors } from '../constants/Colors';
import LogoTitleWithBack from '../components/LogoTitleWithBack';
import FaqListItem from '../components/FaqListItem';
import { faqData } from '../mocks/faqData';
import { Strings } from '../constants/Strings';

const FaqPage = () => {
  const { classes } = useStyles();
  return (
    <>
      <LogoTitleWithBack title={Strings.faq} />
      <div className={classes.content}>
        {faqData.map((data, index) => (
          <FaqListItem {...data} key={index} />
        ))}
      </div>
    </>
  );
};

export default FaqPage;

const useStyles = makeStyles()({
  container: {
    padding: '0 20px',
    marginTop: 20,
  },
  colorTourismRed: {
    backgroundColor: `${Colors.lightRed} !important`,
  },
  content: {
    backgroundColor: Colors.lightGrey,
    padding: 20,
    marginTop: 70,
    color: Colors.black,
  },
});
