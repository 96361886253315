import React from 'react';
import { Typography, Grid } from '@mui/material';
import { Colors } from '../constants/Colors';
import { makeStyles } from 'tss-react/mui';
import aboutImage from '../assets/images/about-card.png';
import { Link } from 'react-router-dom';
import LogoTitle from './LogoTitle';
import { Strings } from '../constants/Strings';

const About = () => {
  const { classes } = useStyles();
  return (
    <Grid container className={classes.container}>
      <Grid item className={classes.boxContainer}>
        <Grid item className={classes.bannerContainer}>
          <LogoTitle
            hasSubtitle={false}
            isWhiteHeaderIcon
            containerClass={classes.logoTitleContainer}
            iconClass={classes.logoTitleIconItemContainer}
            titleClass={classes.logoTitleText}
          />
        </Grid>
        <Grid container className={classes.aboutContainer} xs={12}>
          <Grid item xs={8} className={classes.aboutTextContainer}>
            <Typography>{Strings.aboutAntwerp}</Typography>
          </Grid>
          <Grid item xs={4} className={classes.linkDetailContainer}>
            <Link to="/about" className={classes.linkDetail}>
              {Strings.details}
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default About;

const useStyles = makeStyles()({
  container: {
    padding: '0 20px',
    marginBottom: 30,
    textAlign: 'center',
    justifyContent: 'center',
  },
  boxContainer: {
    width: '100%',
    border: `1px solid ${Colors.darkBorderGrey}`,
    boxShadow: `5px 6px ${Colors.lightGrey}`,
  },
  bannerContainer: {
    backgroundImage: `url(${aboutImage})`,
    height: 100,
    display: 'flex',
  },
  logoTitleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoTitleIconItemContainer: {
    width: 25,
    height: 30,
  },
  logoTitleImage: {
    height: 23,
  },
  logoTitleText: {
    color: Colors.white,
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: '20px',
    paddingTop: 0,
  },
  image: {
    width: '100%',
  },
  aboutContainer: {
    padding: '10px 15px',
  },
  aboutTextContainer: {
    textAlign: 'left',
  },
  linkDetailContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  linkDetail: {
    color: Colors.tourismBlue,
    textDecoration: 'none',
    fontWeight: 'bold',
  },
});
