export const aboutData = [
  {
    items: [{
      question: 'How to get around in the city?',
      answer: 'On foot, by bike or with public transport: choose your own way to explore Antwerp.',
      items: [{
        title: 'On foot',
        items: [`Antwerp is a pocket-sized metropolis, making it easy to explore the city on foot. Use the map
        in the app to see where you can use your Antwerp City Pass. `]
      }, {
        title: 'By bike',
        items: [`You can also explore Antwerp with one of the red city bikes, which are called Velos.
        Regardless of where you are in the city centre the nearest Velo station is only a 400-metre
        walk away. Use a Velo for a short trip. Book one through www.velo-antwerpen.be. If you
        need a bike for half a day or longer we recommend visiting another bike rental company.
        More information about other bike sharing systems: www.slimnaarantwerpen.be.`]
      }, {
        title: 'Public transport',
        // eslint-disable-next-line max-len
        items: [`Antwerp City Pass provides free access to the public transport facilities of De Lijn. Your pass
        serves as your ticket. You can find an overview of all the bus and tram services on their
        website: www.delijn.be.`]
      }]
    },{
      question: 'Voucher discount',
      answer: 'When you visit an attraction, use the app to scan the QR code. Your discount will be\
      applied to your purchase or entrance fee immediately. The City Pass discount will only\
      apply if your card is still valid.',
    },{
      question: 'Free entry',
      answer: 'When you visit an attraction, use the app to scan the QR code. Now you can enter the\
      attraction. You will need to book a time slot in advance to visit certain attractions. Always\
      check the site’s website beforehand to see if booking is required. Your free admission will\
      only apply if your card is still valid.',
    },]
  }
]

export const aboutWhatisAntwerpData = [
  'It gives access to 16 of Antwerp’s top museums, 3 historic churches and 3 attractions. \
  Temporary exhibitions included.',
  'All public transport of De Lijn is included.',
  'You have lots of discounts on attractions, sightseeing tours, souvenirs and sweets. ',
]