export const Colors = {
  white: '#FFFFFF',
  black: '#000000',
  tourismRed: '#BF5622',
  tourismBlue: '#003863',
  lightRed: '#0058B3',
  grayB3: '#B3B3B3',
  blue: '#2356B1',
  blueLink: '#0082BA',
  darkGrey: '#404040',
  lowGrey: '#E3E3E3',
  redWelcome: '#BE2845',
  red: '#A13434',
  redCard: '#D0103B',
  redCheckoutCard: '#b6072b',
  lightGrey: '#F3F3F3',
  darkBorderGrey: '#D9D9D9',
  darkPassGrey: '#D8D8D8',
  greenDark: '#2E6C39',
  greenLight: '#BCEFD7',
  gray94: '#949494',
  greyScan: '#6F6F6F',
  grayDa: '#DADADA',
  gray47: '#474747',
  greyVersion: '#657786',
  greyBorder: '#9b9b9b3b',
  danger500: '#EF4444',
  darkBlue: '#002853',
};
