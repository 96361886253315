import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Colors } from '../constants/Colors';
import { List, ListItemButton, ListItemText, Collapse } from '@mui/material';
import { FaqItemType } from '../types/faqTypes';
import { ReactComponent as ArrowCollapseIcon } from '../assets/images/arrowGreyIcon.svg';

const FaqListCollapseItem = ({ question, answer }: FaqItemType) => {
  const { classes, cx } = useStyles();
  const [open, setOpen] = React.useState<boolean>(false);
  return (
    <>
      <ListItemButton onClick={() => setOpen(!open)} className={cx(classes.container, open && classes.noMarginBottom)}>
        <ListItemText primary={question} />
        <ArrowCollapseIcon
          className={cx(classes.iconWidth, open ? classes.transformIcon90Deg : classes.transformNormal)}
        />
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className={classes.listCollapseContainer}>
          <ListItemButton className={classes.listItemButton}>
            <div className={classes.listItemContent} dangerouslySetInnerHTML={{ __html: answer }} />
          </ListItemButton>
        </List>
      </Collapse>
    </>
  );
};
export default FaqListCollapseItem;

const useStyles = makeStyles()({
  listItemContent: {
    lineHeight: '25px',
  },
  listItemButton: {
    paddingLeft: 12,
    ul: {
      paddingLeft: 20,
      marginTop: 20,
      listStyleType: 'disc',
      lineHeight: '25px',
    },
  },
  listCollapseContainer: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: Colors.grayB3,
    backgroundColor: Colors.white,
    borderTopWidth: 0,
  },
  noMarginBottom: {
    marginBottom: 0,
    borderBottomWidth: 0,
  },
  iconWidth: {
    minWidth: 30,
  },
  transformNormal: {
    transform: 'rotate(0)',
  },
  transformIcon90Deg: {
    transform: 'rotate(180deg)',
  },
  container: {
    padding: '5px 13px',
    backgroundColor: Colors.white,
    borderColor: Colors.grayB3,
    borderWidth: 1,
    borderStyle: 'solid',
    margin: '20px 0',
    '&:first-child': {
      marginTop: 0,
    },
    ':hover': {
      backgroundColor: Colors.white,
    },
  },
  mainTitle: {
    color: Colors.tourismRed,
    fontWeight: 'bold',
  },
});
