import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Colors } from '../constants/Colors';
import { Button, Typography } from '@mui/material';
import { Strings } from '../constants/Strings';
import Header from '../components/Header';
import Input from '../components/Input';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useResetPasswordMutation } from '../services/apiSlice';
import { ResetPasswordType } from '../types/userTypes';
import { generateFormData } from '../utils/helpers';
import { ReactComponent as EyeIcon } from '../assets/images/EyeIcon/EyeIcon.svg';
import { ReactComponent as EyeIconClosed } from '../assets/images/EyeIconClosed/EyeIconClosed.svg';
import { ReactComponent as ChecklistIcon } from '../assets/images/ChecklistIcon.svg';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useSearchParams } from 'react-router-dom';
import greenCheckMark from '../assets/images/greenCheckmark.gif';
import { passwordStrengthRegex } from '../utils/helpers';
import { useDispatch } from 'react-redux';
import { authenticationActions } from '../services/authSlice';
import FriendlyCaptchaWidget from '../components/FriendlyCaptchaWidget';

const ChangePasswordPage = () => {
  const { classes } = useStyles();
  const navigation = useNavigate();
  const [resetPassword, { isSuccess, isLoading, isError, error }] = useResetPasswordMutation();
  const [openText, setOpenText] = useState<boolean>(false);
  const [openTextConfirmPassword, setOpenTextConfirmPassword] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [solution, setSolution] = useState<any>('')
  //@ts-ignore
  const errors = error?.data?.errors;
  const errorMessage = (errors?.reset_password_code[0] as string) || Strings.invalidResetPasswordCode;

  useEffect(() => {
    if (isError) {
      dispatch(authenticationActions.setError({ error: errorMessage, isError }));
    }
  }, [isError]);

  const validationSchema = yup.object({
    password: yup.string().required(Strings.requiredField).matches(passwordStrengthRegex, Strings.passwordValidation),
    passwordConfirmation: yup.string().when('password', {
      is: (val: any) => val && val.length > 0,
      then: yup.string().oneOf([yup.ref('password')], Strings.matchPassword),
    }),
  });

  const onResetPassword = (userValues: ResetPasswordType) => {
    const { password, passwordConfirmation } = userValues;

    const userJSON = {
      password,
      password_confirmation: passwordConfirmation,
      reset_password_code: searchParams.get('reset'),
      'frc-captcha-solution': solution,
    };
    const userFormData = generateFormData(userJSON);
    resetPassword(userFormData);
  };

  // @ts-ignore
  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: true,
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: validationSchema,
    //@ts-ignore
    onSubmit: onResetPassword,
  });

  const isInputError: boolean =
    !!formik.errors.password ||
    !!formik.errors.passwordConfirmation ||
    !formik.values.password ||
    !formik.values.passwordConfirmation;

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className={classes.root}>
          <Header hasBackButton onBackClick={() => (isSuccess ? navigation('/') : navigation(-1))} />
          {!isSuccess ? (
            <>
              <Typography className={classes.title}>{Strings.changePassword}</Typography>
              <Typography className={classes.subtitle}>{Strings.enterNewPassword}</Typography>
              <Input
                isMandatory
                name={'password'}
                label={Strings.newPassword}
                value={formik.values.password}
                onChange={formik.handleChange}
                helperText={formik.errors.password}
                placeholder={Strings.passwordPlaceholder}
                type={!openText ? 'password' : 'text'}
                isError={formik.errors.password ? true : false}
                endAdornment={
                  !openText ? (
                    <EyeIcon className={classes.eyeIcon} onClick={() => setOpenText(true)} />
                  ) : (
                    <EyeIconClosed className={classes.eyeIcon} onClick={() => setOpenText(false)} />
                  )
                }
              />
              {formik.errors.password && (
                <div className={classes.warningBoxContainer}>
                  <div className={classes.warningBoxTitle}>
                    <Typography className={classes.textBold}>{Strings.yourPasswordMustContain}</Typography>
                    <div className={classes.warningTitleItemContainer}>
                      <div className={classes.warningTitleItem}>
                        <ChecklistIcon />
                        <Typography className={classes.warningItemText}>{Strings.atLeast8}</Typography>
                      </div>
                      <div className={classes.warningTitleItem}>
                        <ChecklistIcon />
                        <Typography className={classes.warningItemText}>{Strings.noCommon}</Typography>
                      </div>
                    </div>
                    <div className={classes.passwordStrengthContainer}>
                      <PasswordStrengthBar
                        className={classes.passwordStrengthScoreWordContainer}
                        password={formik.values.password}
                        scoreWordClassName={classes.passwordStrengthScoreWord}
                        minLength={8}
                      />
                    </div>
                  </div>
                </div>
              )}
              <Input
                isMandatory
                name={'passwordConfirmation'}
                label={Strings.confirmNewPassword}
                value={formik.values.passwordConfirmation}
                onChange={formik.handleChange}
                helperText={formik.errors.passwordConfirmation}
                placeholder={Strings.passwordPlaceholder}
                type={!openTextConfirmPassword ? 'password' : 'text'}
                isError={formik.errors.passwordConfirmation ? true : false}
                endAdornment={
                  !openTextConfirmPassword ? (
                    <EyeIcon className={classes.eyeIcon} onClick={() => setOpenTextConfirmPassword(true)} />
                  ) : (
                    <EyeIconClosed className={classes.eyeIcon} onClick={() => setOpenTextConfirmPassword(false)} />
                  )
                }
              />

              <FriendlyCaptchaWidget sendCallbackSolution={(value)=> {
                setSolution(value?.solution || '')
              }} />
              {
                solution && 
                <Button
                  disabled={isLoading || isInputError}
                  className={classes.button}
                  variant="contained"
                  fullWidth
                  type={'submit'}
                >
                  {Strings.changePassword}
                </Button>
              }
            </>
          ) : (
            <>
              <Typography className={classes.title}>{Strings.passwordUpdated}</Typography>
              <img src={greenCheckMark} alt={'check mark'} className={classes.greenCheckMark} />
              <Typography className={classes.subtitle}>{Strings.yourPasswordHasBeenChanged}</Typography>

              <Button
                className={classes.button}
                variant="contained"
                fullWidth
                onClick={() => navigation('/')}
                type={'button'}
              >
                {Strings.continue}
              </Button>
            </>
          )}
        </div>
      </form>
    </>
  );
};

export default ChangePasswordPage;

const useStyles = makeStyles()({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh',
    paddingBottom: '50px',
  },
  adminContactUs: {
    marginBottom: 0,
    paddingBottom: 0,
    fontSize: 12,
  },
  adminContactUsEmail: {
    fontSize: 12,
    color: Colors.blueLink,
  },
  passwordStrengthContainer: {
    position: 'relative',
  },
  passwordStrengthScoreWordContainer: {
    position: 'relative',
    paddingTop: 25,
  },
  passwordStrengthScoreWord: {
    position: 'absolute',
    top: 0,
    right: 0,
    textTransform: 'capitalize',
    fontSize: '12px !important',
    color: `${Colors.danger500} !important`,
  },
  warningBoxContainer: {
    display: 'flex',
    width: '90vw',
    justifyContent: 'center',
    border: `1px solid ${Colors.danger500}`,
    borderRadius: 10,
  },
  warningBoxTitle: {
    padding: '20px 0',
  },
  warningTitleItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 10,
  },
  warningTitleItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  warningItemText: {
    paddingLeft: 10,
    fontSize: 13,
  },
  eyeIcon: {
    paddingRight: 15,
    width: 20,
    height: 20,
  },
  sucessDescription: {
    margin: '20px 20px 0',
    border: `1px solid ${Colors.tourismBlue}`,
    backgroundColor: Colors.greenLight,
    padding: '10px 20px',
    borderRadius: 20,
  },
  sucessDescriptionText: {
    fontSize: 14,
  },
  textBold: {
    fontWeight: 'bold',
  },
  title: {
    color: Colors.tourismBlue,
    marginBottom: '9px',
    fontSize: 25,
    fontWeight: '700',
    marginTop: '80px',
    width: '50%',
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 15,
    textAlign: 'center',
    padding: '15px 35px',
    marginBottom: 30,
  },
  button: {
    marginTop: 30,
    width: '90%',
  },
  buttonBackToLogin: {
    marginTop: 15,
    width: '90%',
    borderRadius: 0,
    height: 46,
    borderWidth: 1,
    boxShadow: `2px 1px 5px ${Colors.gray94}`,
  },
  checkbox: {
    accentColor: Colors.tourismBlue,
    alignSelf: 'flex-start',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    marginLeft: '20px',
  },
  agreeContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  description: {
    fontSize: 12,
    alignSelf: 'center',
  },
  descriptionBold: {
    fontSize: 12,
    alignSelf: 'center',
    fontWeight: 'bold',
    color: Colors.tourismBlue,
    padding: '0px 2px 0px 2px',
  },
  checkBoxContainer: {
    marginTop: '18px',
    width: '100%',
  },
  greenCheckMark: {
    width: '30vw',
    margin: '45px 0',
  },
});
