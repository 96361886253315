import React, { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import PassCard from '../components/PassCard';
import DescriptionAddButton from '../components/DescriptionAddButton';
import MapPreview from '../components/MapPreview';
import About from '../components/AboutCard';
import { Grid, Typography } from '@mui/material';
import ThingsToDo from '../components/ThingsToDo';
import { Strings } from '../constants/Strings';
import { ReactComponent as UnboxIcon } from '../assets/images/unboxIcon.svg';
import { ReactComponent as ArrowGreyIcon } from '../assets/images/arrow-grey.svg';
import { useUserPassQuery } from '../services/apiSlice';
import { usefulLinks } from '../constants/UsefulLinks';
import { useLocationsListHomepageQuery } from '../services/apiSlice';
import AddPassBottomSheet from '../components/AddPassBottomSheet';
import { useNavigate } from 'react-router-dom';
import PassCardSkeleton from '../components/PassCardSkeleton';
import { useDispatch } from 'react-redux';
import { authenticationActions } from '../services/authSlice';
import { Colors } from '../constants/Colors';
import { Link } from 'react-router-dom';

const HomePageScreen = () => {
  const { classes, cx } = useStyles();
  const {
    data: userPassesData,
    isLoading: userPassesLoading,
    error: userPassesError,
    isError: isErrorUserPasses,
  } = useUserPassQuery();

  //@ts-ignore
  const errors = userPassesError?.data;
  const errorMessage = (errors?.error as string) || Strings.errorMessage;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isErrorUserPasses) {
      dispatch(authenticationActions.setError({ error: errorMessage, isError: isErrorUserPasses }));
    }
  }, [isErrorUserPasses]);
  const { data: locationsData, isLoading: areLocationsLoading, isError } = useLocationsListHomepageQuery();

  return (
    <>
      <div className={cx(classes.container, userPassesLoading && classes.textCenter)}>
        {userPassesLoading ? (
          <PassCardSkeleton />
        ) : (
          <PassCard data={userPassesData} isEmpty={!userPassesData?.length} />
        )}
      </div>
      {!!userPassesData?.length && (
        <>
          <AddPassBottomSheet onScanClick={() => navigate('/scan')} onMailClick={() => window.open('mailto:')} />
        </>
      )}

      <DescriptionAddButton />
      <Link className={classes.historyPass} to={'/history-pass'}>
        <Typography>{Strings.historyPass}</Typography>
        <ArrowGreyIcon className={classes.arrowHistoryPass} />
      </Link>
      <MapPreview />
      <ThingsToDo data={locationsData || []} isLoading={areLocationsLoading || isError} />
      <About />
      <Grid container xs={12} className={classes.poweredBy}>
        <Grid item className={classes.unboxLogo}>
          <Typography className={classes.poweredByText}>{Strings.poweredBy}</Typography>
          <UnboxIcon onClick={() => window.open(usefulLinks.unbox)} />
        </Grid>
      </Grid>
    </>
  );
};

export default HomePageScreen;

const useStyles = makeStyles()({
  arrowHistoryPass: {
    display: 'flex',
    alignItems: 'center',
  },
  historyPass: {
    backgroundImage: `linear-gradient(90deg, ${Colors.grayDa} 50%, ${Colors.darkBorderGrey} 100%)`,
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100vw',
    height: '48px',
    backgroundSize: 'cover',
    display: 'flex',
    textDecoration: 'none',
    p: {
      display: 'flex',
      alignItems: 'center',
      width: '90vw',
      paddingLeft: 20,
      color: Colors.gray47,
      fontSize: 14,
    },
  },
  container: {
    marginTop: 20,
  },
  poweredBy: {
    display: 'flex',
    justifyContent: 'center',
    margin: '30px 0',
  },
  poweredByText: {
    marginRight: 5,
    paddingTop: 2,
  },
  unboxLogo: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
  },
  textCenter: {
    textAlign: 'center',
  },
  addPassButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '25px',
  },
});
