import React from 'react';
import { makeStyles } from 'tss-react/mui';
import approvedGif from '../assets/images/approvedIcon.gif';
import { Typography } from '@mui/material';
import { Strings } from '../constants/Strings';

const PassAddedSuccessfully = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.image}>
        <img src={approvedGif} alt={'approved icon'} className={classes.gif} />
      </div>
      <div className={classes.bottomContainer}>
        <Typography className={classes.successful}>{Strings.readyToBeActivated}</Typography>
      </div>
    </div>
  );
};

export default PassAddedSuccessfully;

const useStyles = makeStyles()({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  image: {
    marginTop: '45px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  successful: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '23px',
    textAlign: 'center',
    width: '50%',
    alignSelf: 'center',
    alignContent: 'center',
  },
  gif: {
    width: '150px',
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    marginBottom: '100px',
    justifyContent: 'flex-end',
  },
});
