import React from 'react';
import { Typography, Grid, Skeleton } from '@mui/material';
import { Colors } from '../constants/Colors';
import { makeStyles } from 'tss-react/mui';
import { Link, useNavigate } from 'react-router-dom';
import { Strings } from '../constants/Strings';
import ThingsToDoCard from './ThingsTodoCard';
import { LocationHomepageType } from '../types/locationListTypes';

interface ThingsToDoProps {
  data: LocationHomepageType[];
  isLoading?: boolean;
}

const ThingsToDo = ({ data, isLoading }: ThingsToDoProps) => {
  const { classes, cx } = useStyles();
  const navigate = useNavigate();

  const renderItems = () => {
    if (isLoading) {
      return [1, 2, 3].map(item => (
        <div key={item} className={classes.skeletonContainer}>
          <Skeleton variant="rectangular" width={215} height={170} />
        </div>
      ));
    }

    return data.map((locationsData: LocationHomepageType, index: number) => (
      <ThingsToDoCard
        key={index}
        title={locationsData.title}
        type={Strings.voucher}
        image={locationsData.picture_path}
        onClick={() => navigate(`/attraction-details/${locationsData.id}`)}
        status={locationsData.description || locationsData.discount || ''}
      />
    ));
  };

  return (
    <Grid container className={classes.container}>
      <Grid container className={classes.titleThingsToDo}>
        <Grid item xs={6} className={classes.itemContainerText}>
          <Typography className={cx(classes.textWhite, classes.textBold)}>{Strings.thingsToDo}</Typography>
        </Grid>
        <Grid item xs={6} className={cx(classes.itemContainerText, classes.seeMoreItem)}>
          <Link to="/things-to-do" className={cx(classes.textWhite, classes.textDecorationNone)}>
            <Typography>{Strings.seeMore}</Typography>
          </Link>
        </Grid>
      </Grid>
      <Grid container xs={12} className={classes.containerCard}>
        <Grid container className={classes.slideCard}>
          {renderItems()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ThingsToDo;

const useStyles = makeStyles()({
  titleThingsToDo: {
    marginBottom: 20,
  },
  containerCard: {
    overflow: 'hidden',
    marginBottom: -120,
  },
  slideCard: {
    flexWrap: 'nowrap',
    flexDirection: 'row',
    paddingBottom: 20,
    overflow: 'auto',
  },
  container: {
    backgroundColor: Colors.greenDark,
    padding: '10px 20px',
    marginBottom: 150,
    borderBottom: `10px solid ${Colors.lightGrey}`,
    minHeight: 100,
  },
  textWhite: {
    color: Colors.white,
  },
  textBold: {
    fontWeight: 'bold',
  },
  itemContainerText: {
    display: 'flex',
    alignItems: 'center',
  },
  seeMoreItem: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  textDecorationNone: {
    textDecoration: 'none',
  },
  skeletonContainer: {
    width: '215px',
    height: '170px',
    backgroundColor: Colors.white,
    margin: '0px 5px 0px 5px',
  },
});
