import React, { useMemo } from 'react';
import { Typography, Grid } from '@mui/material';
import { Strings } from '../constants/Strings';
import { Colors } from '../constants/Colors';
import { makeStyles } from 'tss-react/mui';
import LogoTitle from './LogoTitle';
import { UserPassType } from '../types/userPassType';
import { getValidityDate, toMomentFormat } from '../utils/helpers';

interface PassCardActiveProps {
  card: UserPassType | undefined;
  hasSingleCard?: boolean;
  isActivationPageMode?: boolean;
}

const PassCardActive = ({ card, hasSingleCard, isActivationPageMode }: PassCardActiveProps) => {
  const { classes, cx } = useStyles();
  const calculateValidityDate = useMemo(
    () =>
      isActivationPageMode
        ? // eslint-disable-next-line
          toMomentFormat(card?.pass_expiration_date).format('DD/MM/YYYY')
        : getValidityDate(card?.activation_date, card?.validity).format('DD/MM/YYYY'),
    []
  );

  return (
    <Grid container xs={12} className={cx(classes.passCardContainer, hasSingleCard && classes.paddingSingleCard)}>
      <Grid container xs={12} className={classes.paddingCardContainerActive}>
        <Grid item xs={8}>
          <LogoTitle
            hasSubtitle={false}
            titleContainerClass={classes.logoTitleTextContainerClass}
            titleClass={classes.logoTitleTextClassActive}
            iconClass={classes.logoTitleIconClass}
          />
          <Typography className={classes.logoTitleSubTextActive}>{Strings.headerSubTitleUnlock}</Typography>
          <div className={classes.cardUserContainer}>
            <Typography className={cx(classes.cardUser, classes.colorBlack)}>{card?.pass_name || '-'}</Typography>
          </div>
        </Grid>
        <Grid item xs={4} className={classes.cardDays}>
          <div className={classes.cardDaysGrey}>
            <div className={cx(classes.cardGrey, classes.bgLightGrey)}>
              <Typography className={cx(classes.amount, classes.colorBlack)}>{card?.validity}</Typography>
              <Typography className={cx(classes.days, classes.colorBlack)}>{Strings.days}</Typography>
            </div>
          </div>
          <div className={classes.expiresOnContainer}>
            <Typography className={classes.expiresOn}>{`${Strings.expiresOn} ${calculateValidityDate}`}</Typography>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12} className={cx(classes.voucherPass, classes.bgColorDarkBlue, classes.blueGradientBg)}>
        <Typography className={classes.voucherPassTitle}>{Strings.fullAccessPass}</Typography>
      </Grid>
    </Grid>
  );
};

export default PassCardActive;

const useStyles = makeStyles()({
  blueGradientBg: {
    backgroundImage: `linear-gradient(90deg, ${Colors.darkBlue} 50%, ${Colors.lightRed} 100%)`,
  },
  passCardContainer: {
    margin: '0 20px',
    maxWidth: '90%',
    width: '90%',
  },
  logoTitleSubTextActive: {
    fontSize: 14,
    marginTop: 5,
    color: Colors.black,
    textAlign: 'left',
  },
  logoTitleTextContainerClass: {
    paddingLeft: 3,
  },
  logoTitleTextClassActive: {
    fontSize: 16,
    paddingTop: 0,
    lineHeight: '20px',
    color: Colors.black,
    '@media (max-width: 355px)': {
      fontSize: 14,
    },
  },
  logoTitleIconClass: {
    display: 'flex',
    alignItems: 'flex-start',
    height: 20,
    width: 20,
  },
  buttonAddTitle: {
    color: Colors.white,
    fontSize: 15,
  },
  paddingCardContainerActive: {
    minHeight: 128,
    padding: '20px 20px 10px 20px',
    backgroundColor: Colors.white,
    borderTop: `1px solid ${Colors.darkBorderGrey}`,
    borderLeft: `1px solid ${Colors.darkBorderGrey}`,
    borderRight: `1px solid ${Colors.darkBorderGrey}`,
    boxShadow: `5px 5px 5px ${Colors.lightGrey}`,
  },
  paddingSingleCard: {
    padding: '30px 0',
  },
  cardUserContainer: {
    height: 50,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    display: 'flex',
  },
  cardUser: {
    color: Colors.grayB3,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 155,
    textAlign: 'left',
    '@media (max-width: 360px)': {
      width: 145,
    },
  },
  colorBlack: {
    color: Colors.black,
  },
  bgColorBlack: {
    backgroundColor: Colors.black,
  },
  bgColorDarkBlue: {
    backgroundColor: Colors.darkBlue,
  },
  cardDays: {
    textAlign: 'right',
  },
  cardGrey: {
    textAlign: 'center',
    backgroundColor: Colors.gray94,
    display: 'inline-block',
    padding: '3px 10px',
  },
  bgLightGrey: {
    backgroundColor: Colors.lightGrey,
  },
  amount: {
    color: Colors.white,
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: '17px',
    padding: '5px 0',
  },
  cardDaysGrey: {
    marginBottom: 5,
  },
  days: {
    color: Colors.white,
    fontSize: 14,
  },
  voucherPass: {
    textAlign: 'center',
    backgroundColor: Colors.gray94,
    padding: '15px 0',
    boxShadow: `5px 5px 5px ${Colors.lightGrey}`,
  },
  voucherPassTitle: {
    fontSize: 14,
    color: Colors.white,
    textTransform: 'capitalize',
  },
  greeting: {
    fontSize: 25,
    color: Colors.redWelcome,
    fontWeight: 'bold',
    lineHeight: '25px',
    minHeight: 40,
  },
  passOf: {
    fontSize: 13,
    fontWeight: 'bold',
  },
  dayPass: {
    textAlign: 'right',
  },
  dayAmount: {
    fontSize: 16,
  },
  dayActive: {
    fontSize: 15,
    color: Colors.tourismBlue,
  },
  expiresOnContainer: {
    minHeight: 35,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    whiteSpace: 'nowrap',
    marginLeft: -37,
  },
  expiresOn: {
    fontSize: 13,
  },
  countDown: {
    color: Colors.tourismBlue,
    fontSize: 14,
  },
});
