import { createSlice } from '@reduxjs/toolkit';
import { TAX_PER_PASS } from '../constants/Options';
import { RootState } from '../store';
import { SentPassType } from '../types/passTypes';
import { api } from './apiSlice';

interface CheckoutDetailsTypes {
  hour: number;
  quantity: number;
}

type PassState = {
  checkoutDetails: CheckoutDetailsTypes;
  activePasses: SentPassType[];
};

const initialState = {
  checkoutDetails: {
    hour: 24,
    quantity: 1,
    tax: TAX_PER_PASS,
  },
  activePasses: []
};

const passSlice = createSlice({
  name: 'pass',
  initialState: initialState as PassState,
  reducers: {
    resetActivePasses: state => {
      state.activePasses = [];
    },
  },
  extraReducers: builder => {
    builder.addMatcher(api.endpoints.activateUserPass.matchFulfilled, (state, { payload }) => {
      state.activePasses = [...state.activePasses, payload]
    });
  },
});

export default passSlice.reducer;

export const passActions = passSlice.actions;
export const getActivePasses = (state: RootState) => state.passReducer.activePasses;
