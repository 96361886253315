import React, { useState } from 'react';
import BackHeader from '../components/BackHeader';
import { Colors } from '../constants/Colors';
import { Strings } from '../constants/Strings';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import Scanner from '../components/Scanner';

const ScanPage = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onResultScan = (text: string) => {
    setIsLoading(true);
    setTimeout(() => {
      navigate(`/add-pass/${text}`);
    }, 1000);
  };

  // @ts-ignore
  return (
    <div className={classes.container}>
      <BackHeader
        onBackPress={() => navigate(-1)}
        title={Strings.scanQRCode}
        backgroundColor={Colors.blue}
        contentColor={Colors.white}
      />

      <Scanner onCompleteScan={onResultScan} isLoading={isLoading} />
    </div>
  );
};

export default ScanPage;

const useStyles = makeStyles()({
  container: {
    position: 'relative',
  },
});
