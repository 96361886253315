import { Strings } from "../constants/Strings";
import moment from 'moment';

export const generateFormData = (object: Object) => {
  const formData = new FormData();

  for (const key in object) {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    formData.append(key, object[key]);
  }

  return formData;
};

export const passwordStrengthRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})", "i")

export const paginatePassNumber = (index: number | undefined, passCount: number | undefined) => {
  return Strings.passOneOfTwo.replace('_', String(index)).replace('_', String(passCount));
};

export const getValidityDate = (
  date: string | undefined | Date,
  validity: number | undefined,
): moment.Moment => moment(date).add(validity, 'days')

export const toMomentFormat = (date: string | undefined | Date): moment.Moment => moment(date)

export const hasExpiredValidity = (activation_date: Date | string | undefined, validity: number | undefined) => 
  moment().unix() >= getValidityDate(activation_date, validity).unix()

export const isMobile = () => {
    const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(navigator.userAgent);
}