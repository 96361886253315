import { Button, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Colors } from '../constants/Colors';
import { Strings } from '../constants/Strings';
import addNameIcon from '../assets/images/addNameIcon/addNameIcon.png';
import { ReactComponent as AntwerpLogoIcon } from '../assets/images/antwerpLogo.svg';

interface AddPassForProps {
  inputText: string;
  setInputText: React.Dispatch<React.SetStateAction<string>>;
  disabled: boolean;
  validity: string;
}

const AddPassFrom = ({ inputText, setInputText, disabled, validity }: AddPassForProps) => {
  const { classes } = useStyles();
  const [showButton, setShowButton] = useState<boolean>(true);

  return (
    <div>
      <div className={classes.parentContainer}>
        <div className={classes.childContainer}>
          <div className={classes.flexColumn}>
            <div className={classes.passTitle}>
              <AntwerpLogoIcon className={classes.image} />
              <Typography className={classes.passTitle}>{Strings.headerTitle}</Typography>
            </div>
            <Typography className={classes.unlockCity}>{Strings.unlockCity}</Typography>
            {showButton ? (
              <Button
                className={classes.addNameButton}
                onClick={() => {
                  setShowButton(false);
                }}
              >
                <img className={classes.addImage} src={addNameIcon} alt={'add name icon'} />
                {Strings.addName}
              </Button>
            ) : (
              <TextField
                className={classes.inputField}
                autoFocus
                InputProps={{ disableUnderline: true }}
                placeholder="Add name here.."
                id="standard-basic"
                label=""
                variant="standard"
                value={inputText}
                disabled={disabled}
                required
                onChange={(e: any) => {
                  setInputText(e.target.value as string);
                }}
              />
            )}
          </div>
          <div className={classes.daysContainer}>
            <Typography className={classes.number}>{validity}</Typography>
            <Typography className={classes.days}>{Strings.days}</Typography>
          </div>
        </div>
        <div className={classes.subRectangle}>{Strings.passType}</div>
      </div>
      <div className={classes.descriptionContainer}>
        {Strings.descriptionPass}
        <div>{Strings.asterixPass}</div>
      </div>
    </div>
  );
};

export default AddPassFrom;

const useStyles = makeStyles()({
  parentContainer: {
    backgroundColor: Colors.lightGrey,
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    marginTop: '50px',
  },
  childContainer: {
    backgroundColor: Colors.white,
    boxSizing: 'border-box',
    border: `1px solid ${Colors.darkBorderGrey}`,
    borderBottom: '0',
    padding: '15px',
    display: 'flex',
    flexDirection: 'row',
    margin: '31px 30px',
    marginBottom: '0px',
    justifyContent: 'space-between',
  },
  unlockCity: {
    fontWeight: '400',
    lineHeight: '23px',
    fontSize: '14px',
    color: Colors.darkGrey,
  },
  daysContainer: {
    backgroundColor: Colors.lightGrey,
    marginBottom: '55px',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    padding: '5px 10px',
  },
  number: {
    fontWeight: '700',
    fontSize: '23px',
    lineHeight: '23px',
    textAlign: 'center',
  },
  days: {
    fontWeight: '400',
    lineHeight: '23px',
    fontSize: '14px',
  },
  image: {
    width: '20px',
    height: '20px',
    alignSelf: 'center',
    marginRight: '5px',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  passTitle: {
    display: 'flex',
    fontWeight: '700',
    fontSize: '16px',
  },
  addNameButton: {
    display: 'flex',
    // backgroundColor: Colors.white,
    fontWeight: 'bold',
    fontSize: 20,
    border: `1px solid ${Colors.black}`,
    color: Colors.black,
    marginTop: '32px',
    alignItems: 'center ',
    justifyContent: 'flex-start',
  },
  subRectangle: {
    backgroundColor: Colors.tourismBlue,
    border: `1px solid ${Colors.darkBorderGrey}`,
    margin: '0px 29px 31px 30px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '400',
    fontSize: '14px',
    color: Colors.white,
  },
  descriptionContainer: {
    boxSizing: 'border-box',
    border: `1px solid ${Colors.darkBorderGrey}`,
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    fontWeight: '400px',
    fontSize: '14px',
    lineHeight: '23px',
    padding: '10px',
    margin: '25px 29px',
  },
  inputField: {
    marginTop: '25px',
    border: `1px solid ${Colors.darkBorderGrey}`,
    borderRadius: 8,
    paddingLeft: 8,
  },
  asterixDescription: {
    fontSize: '9px',
  },
  addImage: {
    width: '15px',
  },
});
