import React from 'react';
import { Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Colors } from "../constants/Colors";
interface BreadCrumbStepPropsType {
  step: number;
  title: string;
  disabled?: boolean;
}
const BreadCrumbStep = ({
  step,
  title,
  disabled,
}: BreadCrumbStepPropsType ) => {
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.breadCrumb, disabled && classes.disabled)}>
      <Typography className={classes.stepNumber}>{step}</Typography>
      <Typography className={classes.stepTitle}>{title}</Typography>
  </div>
  )
}

export default BreadCrumbStep;

const useStyles = makeStyles()({
  disabled: {
    '.MuiTypography-root': {
      color: Colors.grayDa,
      borderColor: Colors.grayDa,
      '&:before': {
        backgroundColor: Colors.grayDa,
      }
    }
  },
  stepNumber: {
    border: `1px solid ${Colors.blue}`,
    borderRadius: 40,
    width: 65,
    height: 65,
    fontSize: 23,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: Colors.blue,
    position: 'relative',
    cursor: 'pointer',
    '&:before': {
      content: '" "',
      backgroundColor: Colors.blue,
      opacity: 0.2,
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '100%',
      borderRadius: 40,
    }
  },
  stepTitle: {
    color: Colors.blue,
    fontSize: 15,
    marginTop: 8,
  },
  breadCrumb: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 90,
  },
});
