export const environment = {
  //@ts-ignore
  // eslint-disable-next-line max-len
  REACT_APP_FRIENDLY_CAPTCHA_SITEKEY: window?._env_?.REACT_APP_FRIENDLY_CAPTCHA_SITEKEY || process.env.REACT_APP_FRIENDLY_CAPTCHA_SITEKEY,
  //@ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  REACT_APP_STRIPE_PK: window?._env_?.REACT_APP_STRIPE_PK || process.env.REACT_APP_STRIPE_PK,
  //@ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  REACT_APP_BASE_URL: window?._env_?.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL
}