import { Skeleton } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Colors } from '../constants/Colors';
import { Strings } from '../constants/Strings';
import { UserPassType } from '../types/userPassType';
import PassCardActive from './PassCardActive';

interface AddPassForProps {
  card: UserPassType | undefined;
  isLoading?: boolean;
}

const ActivationPassForm = ({ card, isLoading }: AddPassForProps) => {
  const { classes } = useStyles();

  return (
    <>
      <div className={classes.parentContainer}>
        {isLoading ? (
          <div className={classes.skeletonContainer}>
            <Skeleton variant="rectangular" className={classes.skeleton} />
          </div>
        ) : (
          <PassCardActive card={card} hasSingleCard={true} isActivationPageMode />
        )}
      </div>
      <div className={classes.descriptionContainer}>
        <div className={classes.description}>
          {Strings.descriptionPass}
          <div>{Strings.asterixPass}</div>
        </div>
      </div>
    </>
  );
};

export default ActivationPassForm;

const useStyles = makeStyles()({
  skeletonContainer: {
    padding: '30px 20px',
  },
  skeleton: {
    height: '25vh',
    borderRadius: 5,
  },
  parentContainer: {
    backgroundColor: Colors.lightGrey,
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    marginTop: '50px',
  },
  childContainer: {
    backgroundColor: Colors.white,
    boxSizing: 'border-box',
    border: `1px solid ${Colors.darkBorderGrey}`,
    borderBottom: '0',
    borderRadius: '18px 18px 0px 0px',
    padding: '15px',
    display: 'flex',
    flexDirection: 'row',
    margin: '31px 30px',
    marginBottom: '0px',
    justifyContent: 'space-between',
  },
  daysContainer: {
    textAlign: 'center',
    backgroundColor: Colors.lightGrey,
    borderRadius: '8px',
    marginBottom: '45px',
    padding: '0px 9px',
  },
  unlockCity: {
    fontWeight: '400',
    lineHeight: '17px',
    fontSize: '10px',
    color: Colors.darkGrey,
  },
  number: {
    fontWeight: '700',
    fontSize: '25px',
  },
  days: {
    fontWeight: '400',
    lineHeight: '12px',
  },
  image: {
    width: '40px',
    alignSelf: 'center',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  passTitle: {
    display: 'flex',
    fontWeight: '700',
    fontSize: '16px',
  },
  addNameButton: {
    display: 'flex',
    backgroundColor: Colors.white,
    border: Colors.white,
    color: Colors.black,
    marginTop: '32px',
    alignItems: 'center ',
    justifyContent: 'flex-start',
  },
  subRectangle: {
    backgroundColor: Colors.tourismBlue,
    border: `1px solid ${Colors.darkBorderGrey}`,
    borderRadius: '0px 0px 18px 18px',
    margin: '0px 29px 12px 30px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '400',
    fontSize: '10px',
    color: Colors.white,
    textTransform: 'uppercase',
  },
  descriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    fontWeight: '400px',
    fontSize: '12px',
    lineHeight: '18px',
    margin: '25px 29px',
  },
  description: {
    boxSizing: 'border-box',
    border: `1px solid ${Colors.darkBorderGrey}`,
    borderRadius: '8px',
    padding: '20px 15px',
    textAlign: 'justify',
  },
  inputField: {
    marginTop: '25px',
  },
  asterixDescription: {
    fontSize: '9px',
  },
  addImage: {
    width: '15px',
  },
});
