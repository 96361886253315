import React from 'react';
import { InputBase, InputBaseProps, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Colors } from '../constants/Colors';

interface InputProps {
  label?: string;
  helperText?: string;
  isMandatory?: boolean;
  isSuccessHelperText?: boolean;
  isError?: boolean;
}

const Input = (props: InputBaseProps & InputProps) => {
  const { classes, cx } = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <Typography variant={'body1'}>{props.label}</Typography>
        {props.isMandatory && (
          <Typography variant={'body1'} className={classes.mandatory}>
            {'*'}
          </Typography>
        )}
      </div>
      <InputBase
        {...props}
        className={cx(
          classes.input,
          props.isSuccessHelperText && classes.greenBorderInput,
          props.isError && classes.redBorderInput
        )}
        size={'small'}
        fullWidth
      />
      <Typography variant={'body1'} className={cx(classes.error, props.isSuccessHelperText && classes.greenHelperText)}>
        {props.helperText}
      </Typography>
    </div>
  );
};

const useStyles = makeStyles({ name: { Input } })(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    margin: '5px 0px 5px 0px',
  },
  greenBorderInput: {
    border: `1px solid ${Colors.greenDark}`,
  },
  redBorderInput: {
    border: `1px solid ${Colors.danger500}`,
  },
  greenHelperText: {
    color: Colors.greenDark,
  },
  input: {
    border: `1px solid ${Colors.grayB3}`,
    backgroundColor: Colors.white,
    borderRadius: '5px',
    width: '100%',
  },
  error: {
    fontSize: 12,
    color: 'red',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  mandatory: {
    color: 'red',
  },
}));

export default Input;
