import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Grid, Typography } from '@mui/material';
import { Colors } from '../constants/Colors';
import { ReactComponent as ArrowBackIcon } from '../assets/images/arrowBack.svg';
import { Strings } from '../constants/Strings';
import { useNavigate } from 'react-router-dom';
import Header from './Header';

interface LogoTitleWithBackProps {
  title: string;
}
const LogoTitleWithBack = ({ title }: LogoTitleWithBackProps) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  return (
    <Grid container className={classes.titleContainer}>
      <Grid container xs={12} className={classes.titleContainerTop}>
        <Grid item xs={5} className={classes.arrowBackContainer}>
          <ArrowBackIcon className={classes.arrowBackIcon} onClick={() => navigate(-1)} />
        </Grid>
        <Grid item xs={6} className={classes.subTitleContainer}>
          <Typography className={classes.subTitleText}>{title}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Header />
      </Grid>
    </Grid>
  );
};

LogoTitleWithBack.defaultProps = {
  title: Strings.legal,
};

export default LogoTitleWithBack;

const useStyles = makeStyles()({
  titleContainer: {
    padding: '15px 20px 10px',
  },
  arrowBackContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  logoTitleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoTitleIconItemContainer: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
  logoTitleImage: {
    height: 23,
  },
  logoTitleText: {
    color: Colors.black,
    fontSize: 25,
    fontWeight: 'normal',
    lineHeight: '20px',
    paddingTop: 0,
  },
  arrowBackIcon: {
    width: 22,
    height: 25,
  },
  subTitleContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  subTitleText: {
    fontSize: 20,
    color: Colors.tourismBlue,
    fontWeight: 'bold',
  },
  titleContainerTop: {
    marginBottom: 40,
  },
});
