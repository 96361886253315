import React from 'react';
import { Typography, Grid } from '@mui/material';
import { Strings } from '../constants/Strings';
import { Colors } from '../constants/Colors';
import { makeStyles } from 'tss-react/mui';
import { hasExpiredValidity, paginatePassNumber } from '../utils/helpers';
import { UserPassType } from '../types/userPassType';
import CountDownValidityDate from './CountDownValidityDate';

interface PassCardHeaderProps {
  index: number;
  length?: number;
  data: UserPassType | undefined;
}

const PassCardHeader = ({ index, length, data }: PassCardHeaderProps) => {
  const { classes } = useStyles();

  const renderPassStatus = () => {
    if (data?.activation_date && hasExpiredValidity(data?.activation_date, data?.validity)) {
      return <Typography className={classes.expiredRedText}>{Strings.expired}</Typography>;
    }

    if (!data?.activation_date) {
      return <Typography className={classes.expiredRedText}>{Strings.inactive}</Typography>;
    }

    return <CountDownValidityDate activation_date={data?.activation_date} validity={data?.validity} />;
  };

  return (
    <Grid container xs={12} className={classes.passCardHeaderContainer}>
      <Grid item xs={6}>
        <Typography className={classes.greeting}>{Strings.welcome}</Typography>
        <Typography className={classes.passOf}>{paginatePassNumber(index + 1, length)}</Typography>
      </Grid>
      <Grid item xs={6} className={classes.dayPass}>
        <Typography className={classes.dayAmount}>
          {Strings.amountDayPass.replace('_', String(data?.validity))}
        </Typography>
        <div>{renderPassStatus()}</div>
      </Grid>
    </Grid>
  );
};

export default PassCardHeader;

const useStyles = makeStyles()({
  passCardHeaderContainer: {
    padding: '0 20px',
  },
  greeting: {
    fontSize: 25,
    color: Colors.redWelcome,
    fontWeight: 'bold',
    lineHeight: '25px',
    minHeight: 40,
  },
  passOf: {
    fontSize: 13,
    fontWeight: 'bold',
  },
  dayPass: {
    textAlign: 'right',
  },
  dayAmount: {
    fontSize: 16,
  },
  dayActive: {
    fontSize: 15,
    color: Colors.tourismBlue,
  },
  expiresOnContainer: {
    minHeight: 35,
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'flex-end',
    whiteSpace: 'nowrap',
    marginLeft: -37,
  },
  expiresOn: {
    fontSize: 13,
  },
  countDown: {
    color: Colors.tourismBlue,
    fontSize: 14,
  },
  expiredRedText: {
    color: Colors.red,
    fontSize: 14,
  },
});
