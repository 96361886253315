export type LinkProps = {
  text: string;
  link: string;
};
export const HeaderMenuDrawer: LinkProps[] = [
  {
    text: 'Home',
    link: '/home',
  },
  {
    text: 'Things To Do',
    link: '/things-to-do',
  },
  {
    text: 'History',
    link: '/history',
  },
  {
    text: 'Maps',
    link: '/maps',
  },
  {
    text: 'General',
    link: '/general',
  },
];

export const ProfileMenuList: LinkProps[] = [
  {
    text: 'General',
    link: '/general',
  },
  {
    text: 'FAQ',
    link: '/faq',
  },
  {
    text: 'Legal',
    link: '/legal',
  },
  {
    text: 'About Antwerp City Pass',
    link: '/about',
  },
];
