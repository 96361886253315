import React, { useEffect } from 'react';
import { Strings } from '../constants/Strings';
import BackHeader from '../components/BackHeader';
import { Colors } from '../constants/Colors';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useLocationDetailsQuery } from '../services/apiSlice';
import { useNavigate, useParams } from 'react-router-dom';
import BottomSheetScanVenue from '../components/BottomSheetScanVenue';
import { useDispatch } from 'react-redux';
import { authenticationActions } from '../services/authSlice';
import offStatus from '../assets/images/schedule-off.svg';
import onStatus from '../assets/images/schedule-on.svg';

const UsePassDetailsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: locationDetails, isLoading: areDetailsLoading, error, isError } = useLocationDetailsQuery(id as string);
  const dispatch = useDispatch();

  //@ts-ignore
  const errors = error?.data?.errors;
  const { classes, cx } = useStyles();
  const errorMessage = (errors?.error[0] || errors?.message[0] || Strings.errorMessage) as string;

  const isLoading = areDetailsLoading;

  useEffect(() => {
    if (isError) {
      dispatch(authenticationActions.setError({ isError, error: errorMessage }));
    }
  }, [isError]);

  if (isLoading) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <BackHeader
        onBackPress={() => navigate(-1)}
        title={Strings.thingsToDo}
        backgroundColor={Colors.greenDark}
        contentColor={Colors.white}
      />
      <Grid xs={12} container>
        <Box className={classes.image}>
          <img src={locationDetails?.picture_path || ''} alt={locationDetails?.title || ''} />
        </Box>
        <Box className={classes.paddingBox}>
          <Grid item xs={12} className={classes.greySubtitle}>
            <Typography>{locationDetails?.category_name}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.contentBox}>
            <Typography className={classes.titleBox}>{locationDetails?.title || ''}</Typography>
            <div className={classes.descriptionBox}>
              <Typography>{locationDetails?.description}</Typography>
            </div>
          </Grid>
          <Typography className={classes.titleBox}>{Strings.schedule}</Typography>
          <Grid container className={classes.scheduleContainer}>
            {!!locationDetails?.schedule?.length &&
              Object.keys(locationDetails?.schedule?.[0] || {}).map((schedule: string, index: number) => {
                const status: string = locationDetails?.schedule?.[0]?.[schedule] || '';
                return (
                  <Grid item xs={3.5} key={index} className={cx(Number(status) ? classes.onStatus : classes.offStatus)}>
                    <Typography>{schedule}</Typography>
                  </Grid>
                );
              })}
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                  window.open(locationDetails?.website, '_blank', 'noreferrer');
                }}
                className={classes.linkToProvider}
                disabled={!locationDetails?.website}
              >
                {Strings.findOutMore}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <BottomSheetScanVenue scanButtonClaass={classes.scanButtonBox} scanTitle={Strings.scan} />
            </Grid>
          </Grid>
          <div className={classes.footerBox}>{Strings.footerThingsToDoDetails}</div>
        </Box>
      </Grid>
    </>
  );
};

export default UsePassDetailsPage;

const useStyles = makeStyles()({
  scheduleContainer: {
    margin: '-10px 0px 15px -5px',
    justifyContent: 'space-between',
    '.MuiGrid-root': {
      display: 'flex',
      alignItems: 'center',
      borderRight: `1px solid ${Colors.grayDa}`,
      margin: '5px 0',
      justifyContent: 'flex-end',
      p: {
        paddingLeft: 7,
        flexGrow: 1,
        fontSize: 12,
      },
      '&:last-child': {
        border: 'none',
        '&:after': {
          backgroundPosition: '7px center',
        },
      },
      '&:after': {
        content: '" "',
        width: 30,
        height: 30,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      },
    },
  },
  onStatus: {
    '&:after': {
      backgroundImage: `url(${onStatus})`,
    },
  },
  offStatus: {
    '&:after': {
      backgroundImage: `url(${offStatus})`,
    },
  },
  scheduleTitle: {
    fontSize: 14,
  },
  footerBox: {
    fontSize: 12,
    margin: '20px 0',
  },
  scanButtonBox: {
    textAlign: 'center',
    borderRadius: '0px',
    height: '30px !important',
    backgroundColor: `${Colors.lightRed} !important`,
    fontSize: 12,
    width: '97% !important',
    padding: 0,
    margin: 0,
  },
  linkToProvider: {
    textAlign: 'center',
    borderRadius: '0px',
    height: 30,
    fontSize: 12,
    border: '1px solid',
    width: '97%',
    textTransform: 'capitalize',
    color: Colors.greenDark,
  },
  contentBox: {
    margin: '15px 0',
  },
  descriptionBox: {
    p: {
      fontSize: 12,
    },
  },
  paddingBox: {
    padding: '0 15px',
    width: '100%',
  },
  image: {
    display: 'inherit',
    marginBottom: 15,
    img: {
      minHeight: 180,
      width: '100vw',
      borderBottom: `1px solid ${Colors.grayDa}`,
    },
  },
  greySubtitle: {
    maxWidth: 130,
    backgroundColor: Colors.gray94,
    display: 'flex',
    justifyContent: 'center',
    p: {
      color: Colors.white,
      padding: '5px 0',
      fontSize: 15,
    },
  },
  titleBox: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 7,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '85vh',
  },
});
