import React, { useEffect } from 'react';
import BackHeader from '../components/BackHeader';
import { Colors } from '../constants/Colors';
import { Strings } from '../constants/Strings';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { useRedeemPassMutation } from '../services/apiSlice';
import { generateFormData } from '../utils/helpers';
import Scanner from '../components/Scanner';
import { useDispatch } from 'react-redux';
import { authenticationActions } from '../services/authSlice';

const LocationScanPage = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { id } = useParams();
  const [scanLocation, { data, isLoading, isSuccess, isError, error }] = useRedeemPassMutation();
  const dispatch = useDispatch();
  //@ts-ignore
  const errors = error?.data;
  const errorMessage = (errors?.error as string) || (errors?.message as string) || Strings.errorMessage;
  useEffect(() => {
    if (isSuccess) {
      navigate(`/attraction-redeemed/${data?.transaction_id}`);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      dispatch(authenticationActions.setError({ isError, error: errorMessage }));
    }
  }, [isError]);

  const handleScan = (text: string) => {
    const values = {
      pass_id: id,
      location_id: text,
    };
    const locationData = generateFormData(values as object);
    scanLocation(locationData);
  };

  return (
    <>
      <div className={classes.container}>
        <BackHeader
          onBackPress={() => navigate(-1)}
          title={Strings.scanQRCode}
          backgroundColor={Colors.blue}
          contentColor={Colors.white}
        />
        <Scanner onCompleteScan={handleScan} isLoading={isLoading} isError={isError} />
      </div>
    </>
  );
};

export default LocationScanPage;

const useStyles = makeStyles()({
  container: {
    position: 'relative',
  },
});
