import React, { useEffect, useState } from 'react';

import { Typography, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import AddPassForm from '../components/AddPassForm';
import PassAddedSuccessfully from '../components/PassAddedSuccessfully';
import Header from '../components/Header';

import { Strings } from '../constants/Strings';
import { Colors } from '../constants/Colors';
import { useNavigate, useParams } from 'react-router-dom';
import { useAddPassMutation } from '../services/apiSlice';
import { SentPassType } from '../types/passTypes';
import { useDispatch } from 'react-redux';
import { authenticationActions } from '../services/authSlice';

const AddPassPage = () => {
  const [inputText, setInputText] = useState<string>('');
  const [addPass, { isSuccess, isLoading, error, isError }] = useAddPassMutation();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { id, validity } = useParams();
  const dispatch = useDispatch();

  //@ts-ignore
  const errorMessage = error?.data.message as string;

  useEffect(() => {
    if (isError) {
      dispatch(authenticationActions.setError({ error: errorMessage, isError }));
    }
  }, [isError]);

  const sendPass = () => {
    const data: SentPassType = {
      pass_id: `${id}/${validity}` || '',
      pass_name: inputText,
    };
    addPass(data);
  };

  const onButtonClick = () => {
    if (!isSuccess) {
      sendPass();
      return;
    }
    navigate('/home');
  };

  return (
    <>
      <div className={classes.root}>
        <Header hasBackButton={!isSuccess} onBackClick={() => navigate(-1)} />
        <Typography className={classes.title}>{isSuccess ? Strings.passAddedTitle : Strings.addPassTitle}</Typography>
        <div className={classes.container}>
          {isSuccess ? (
            <PassAddedSuccessfully />
          ) : (
            <AddPassForm
              disabled={isLoading}
              inputText={inputText}
              setInputText={setInputText}
              validity={validity || '0'}
            />
          )}
        </div>
        <div className={classes.bottomContainer}>
          <Button
            className={classes.addPassButton}
            variant="contained"
            disabled={!inputText.length || isLoading}
            onClick={onButtonClick}
          >
            {isSuccess ? Strings.continue : Strings.add}
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddPassPage;

const useStyles = makeStyles()({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    alignItems: 'center',
    overflow: 'scroll',
  },
  title: {
    color: Colors.tourismBlue,
    textAlign: 'center',
    fontSize: 25,
    fontWeight: '700',
    marginTop: '50px',
  },
  addPassButton: {
    width: '85vw',
    borderRadius: '0px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '30px',
  },
  container: { display: 'flex', height: '100%' },
  bottomContainer: { display: 'flex', flex: 1, alignItems: 'flex-end' },
});
