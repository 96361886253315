import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { WidgetInstance } from "friendly-challenge";
import { environment } from '../config/environment';
interface FriendlyCaptchaWidgetPropsType {
  sendCallbackSolution: (solution: any) => void;
}
const FriendlyCaptchaWidget = ({
  sendCallbackSolution
}: FriendlyCaptchaWidgetPropsType) => {
  const { classes, cx } = useStyles();

  const [widget, setWidget] = useState<any>(null);
  const captchaWidgetRef = useRef<HTMLInputElement>(null)
  const [solution, setSolution] = useState<any>('')

  useEffect(()=> {
    const el: any = captchaWidgetRef?.current
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const widgetState = new WidgetInstance(el, {
      doneCallback(value) {
          setSolution({
            solution: value
          })
      },
    })
    setWidget(widgetState)
  }, [])

  if (widget) {
    console.log(widget, solution)
  }

  useEffect(()=> {
    if (solution) {
      sendCallbackSolution(solution)
    }
  }, [solution])

  return (
    <div ref={captchaWidgetRef}
      className={cx(classes.widgetRef, "frc-captcha")}
      data-sitekey={
        environment.REACT_APP_FRIENDLY_CAPTCHA_SITEKEY
    }/>
  );
};

export default FriendlyCaptchaWidget;

const useStyles = makeStyles()({
  widgetRef: {
    display: 'flex',
    marginTop: 35,
    overflow: 'scroll',
  },
});
