import React from 'react';

import { Colors } from '../constants/Colors';
import { makeStyles } from 'tss-react/mui';
import { ReactComponent } from 'tss-react/tools/ReactComponent';

interface ComponentProps {
  isSelected: boolean;
  IconComponent: ReactComponent<any>;
  onClick: () => void;
}

const MapControlButton = ({ isSelected, IconComponent, onClick }: ComponentProps) => {
  const { classes } = useStyles({ isSelected });
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className={classes.container} onClick={onClick}>
      <div className={classes.subContainer}>
        <IconComponent fill={isSelected ? Colors.white : Colors.tourismBlue} />
      </div>
    </div>
  );
};

MapControlButton.defaultProps = {
  isSelected: false,
};

const useStyles = makeStyles<{ isSelected: boolean }>()((_, { isSelected }) => ({
  container: {
    display: 'flex',
    height: '45px',
    width: '45px',
    borderRadius: '50%',
    backgroundColor: Colors.white,
    border: `1px solid ${Colors.tourismBlue}`,
    padding: '2px',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '5px',
  },
  subContainer: {
    display: 'flex',
    width: '95%',
    height: '95%',
    borderRadius: '50%',
    backgroundColor: isSelected ? Colors.tourismBlue : Colors.white,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default MapControlButton;
