import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { api } from './apiSlice';

type AuthState = {
  authToken: string | null | undefined;
  userEmail: string | null | undefined;
  isError: boolean;
  error: string;
  isSuccess: boolean;
  success: string;
};

const initialState = {
  authToken: localStorage.getItem('authToken') || null,
  userEmail: localStorage.getItem('userEmail') || null,
  isError: false,
  error: '',
  isSuccess: false,
  success: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState as AuthState,
  reducers: {
    resetToken: state => {
      state.authToken = null;
      state.userEmail = null;
    },
    setSuccess: (state, { payload }) => {
      state.isSuccess = payload.isSuccess;
      state.success = payload.success;
    },
    resetSuccess: state => {
      state.isSuccess = initialState.isSuccess;
      state.success = initialState.success;
    },
    setError: (state, { payload }) => {
      state.isError = payload.isError;
      state.error = payload.error;
    },
    resetError: state => {
      state.isError = initialState.isError;
      state.error = initialState.error;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(api.endpoints.login.matchFulfilled, (state, { payload }) => {
      localStorage.setItem('authToken', payload?.token);
      localStorage.setItem('userEmail', payload?.user?.email);
      state.authToken = payload.token;
      state.userEmail = payload.user?.email;
    });
  },
});

export default authSlice.reducer;

export const authenticationActions = authSlice.actions;
export const selectToken = (state: RootState) => state.authReducer.authToken;
export const selectUserEmail = (state: RootState) => state.authReducer.userEmail;
export const getIsError = (state: RootState) => state.authReducer.isError;
export const getError = (state: RootState) => state.authReducer.error;
export const getIsSuccess = (state: RootState) => state.authReducer.isSuccess;
export const getSuccess = (state: RootState) => state.authReducer.success;
