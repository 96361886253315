import React, { useEffect } from 'react';
import { Strings } from '../constants/Strings';
import { Colors } from '../constants/Colors';
import ThingsToDoDetails from './ThingsToDoDetails';
import { makeStyles } from 'tss-react/mui';
import { useLocationDetailsQuery, useUserPassDetailQuery } from '../services/apiSlice';
import { CircularProgress, Typography } from '@mui/material';
import { ReactComponent as CheckMarkWhiteIcon } from '../assets/images/checkmark-white.svg';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { authenticationActions } from '../services/authSlice';

interface VenueDetailProps {
  passId: string | undefined;
  locationId: string | undefined;
  redeemedAt: Date | string | undefined;
}

const VenueDetail = ({ passId, locationId, redeemedAt }: VenueDetailProps) => {
  const { classes, cx } = useStyles();
  const {
    data,
    isLoading,
    isError: isErrorLocationDetail,
    error: errorLocationDetail,
  } = useLocationDetailsQuery(locationId);
  const {
    data: passDetailData,
    isLoading: passLoadingData,
    isError: isErrorUserPassDetail,
    error: errorUserPassDetail,
  } = useUserPassDetailQuery(passId);
  const dispatch = useDispatch();

  //@ts-ignore
  const errors = errorLocationDetail?.data || errorUserPassDetail?.data;
  const errorMessage = (errors?.error as string) || (errors?.message as string) || Strings.errorMessage;

  useEffect(() => {
    if (isErrorLocationDetail || isErrorUserPassDetail) {
      dispatch(
        authenticationActions.setError({ error: errorMessage, isError: isErrorLocationDetail || isErrorUserPassDetail })
      );
    }
  }, [isErrorLocationDetail, isErrorUserPassDetail]);

  return (
    <>
      {isLoading || passLoadingData ? (
        <div className={classes.loadingCenter}>
          <CircularProgress />
        </div>
      ) : (
        <ThingsToDoDetails
          title={data?.title}
          subtitle={data?.category_name}
          type={Strings.voucher}
          imageProp={data?.picture_path}
        >
          <div className={classes.textRedeem}>{`${Strings.ticketRedeemed} ${moment(redeemedAt).format(
            'DD/MM/YYYY'
          )} at ${moment(redeemedAt).format('HH:mm')}`}</div>
          <div className={cx(classes.textCenter, classes.flexBottom)}>
            <CheckMarkWhiteIcon />
            <Typography className={cx(classes.textRedeem, classes.alignEnd)}>{passDetailData?.pass_name}</Typography>
          </div>
        </ThingsToDoDetails>
      )}
    </>
  );
};

export default VenueDetail;

const useStyles = makeStyles()({
  textRedeem: {
    color: Colors.white,
    textAlign: 'center',
    padding: '20px 10px',
    fontSize: '20px',
    display: 'flex',
    flex: 1,
  },
  loadingCenter: {
    textAlign: 'center',
    marginTop: 30,
  },
  textCenter: {
    textAlign: 'center',
  },
  flexBottom: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 2,
  },
  alignEnd: {
    alignItems: 'flex-end',
  },
});
