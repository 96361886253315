import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Colors } from '../constants/Colors';
import { List } from '@mui/material';
import { FaqType } from '../types/faqTypes';
import AboutCollapseItem from './AboutCollapseItem';

const AboutListItem = ({ question, items }: FaqType) => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.mainTitle}>{question}</div>
      <List className={classes.listContainer}>
        {items.map((item, index) => (
          <AboutCollapseItem {...item} key={index} />
        ))}
      </List>
    </div>
  );
};
export default AboutListItem;

const useStyles = makeStyles()({
  container: {
    marginTop: 30,
    marginBottom: 25,
    '&:first-child': {
      marginTop: 20,
    },
  },
  listContainer: {
    paddingLeft: 10,
  },
  mainTitle: {
    color: Colors.tourismBlue,
    fontWeight: 'bold',
    paddingLeft: 15,
    marginBottom: 20,
  },
});
