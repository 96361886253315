import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectToken } from '../services/authSlice';

const PublicRoutes = () => {
  const token = useSelector(selectToken);
  const location = useLocation();
  const redirection = location?.state?.referrer || '/home';
  console.log({ location })
  return !token ? <Outlet /> : <Navigate to={redirection} replace />;
};
export default PublicRoutes;
