/* eslint-disable max-len */
// eslint-disable-next-line max-len
export const legalData: string[] = ['Unless otherwise indicated, the discounts apply to the standard adult rate. The discounts and\
benefits cannot be combined with other offers. Products and services listed in the Antwerp\
City Pass cannot be redeemed for cash. • All information is provided as known to the City of\
Antwerp at the time it was included in the guide, subject to changes and input errors.',
'The complete list of regulations is available at the following website:\
visit.antwerpen.be/en/info/antwerp-city-pass-terms-conditions.',
'Visit Antwerpen selected all the offers with great care, but during the year the selection of\
offers can change. Visit Antwerpen cannot be held responsible for changes. For the most\
recent and updated offers, visit https://visit.antwerpen.be/en/antwerp-city-pass-en',
]