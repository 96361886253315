import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { LoginUserType } from '../types/userTypes';
import { RootState } from '../store';
import { authenticationActions } from './authSlice';
import { LocationHomepageType, LocationListTypes } from '../types/locationListTypes';
import { MapLocationType } from '../types/mapTypes';
import { LocationDetails } from '../types/locationDetailsTypes';
import { UserPassType } from '../types/userPassType';
import { ActivationPassType, SentPassType, GeneratePassType, PaymentIntentPostType, GeneratePassByPIType } from '../types/passTypes';
import { TransactionType } from '../types/transactionType';
import { environment } from '../config/environment';

const baseQuery = fetchBaseQuery({
  //@ts-ignore
  baseUrl: environment.REACT_APP_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const state = getState() as RootState;
    const authToken = state.authReducer.authToken;
    headers.set('authorization', `Bearer ${authToken}`);

    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    localStorage.removeItem('authToken');
    api.dispatch(authenticationActions.resetToken());
  }

  return result;
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({
    register: builder.mutation<string, FormData>({
      query: user => ({
        url: '/register',
        method: 'POST',
        body: user,
      }),
    }),
    login: builder.mutation<LoginUserType, FormData>({
      query: user => ({
        url: '/login',
        method: 'POST',
        body: user,
      }),
    }),
    logout: builder.mutation<LoginUserType, FormData>({
      query: data => ({
        url: '/logout',
        method: 'POST',
        body: data,
      }),
    }),
    mapLocationsList: builder.query<MapLocationType[], void>({
      query: () => ({
        url: '/locations-list-map',
        method: 'GET',
      }),
    }),
    locationList: builder.mutation<LocationListTypes, string>({
      query: searchTerm => ({
        url: `/locations-list?search=${searchTerm}`,
        method: 'GET',
      }),
    }),
    test: builder.query<void, void>({
      query: () => ({
        url: '/test',
        method: 'GET',
      }),
    }),
    forgotPassword: builder.mutation<any, FormData>({
      query: data => ({
        url: '/forgot-password',
        method: 'POST',
        body: data,
      }),
    }),
    resetPassword: builder.mutation<any, FormData>({
      query: data => ({
        url: '/reset-password',
        method: 'POST',
        body: data,
      }),
    }),
    addPass: builder.mutation<void, SentPassType>({
      query: data => ({
        url: '/add-pass',
        method: 'POST',
        body: data,
      }),
    }),
    locationDetails: builder.query<LocationDetails, string | undefined>({
      query: id => ({
        url: `/location/${id}`,
      }),
    }),
    activateUserPass: builder.mutation<ActivationPassType, FormData>({
      query: data => ({
        url: '/activate-pass',
        method: 'POST',
        body: data,
      }),
    }),
    userPass: builder.query<UserPassType[], void>({
      query: () => ({
        url: '/user-passes',
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
    }),
    locationsListHomepage: builder.query<LocationHomepageType[], void>({
      query: () => ({
        url: '/locations-homepage-list',
        method: 'GET',
      }),
    }),
    activePasses: builder.query<SentPassType[], void>({
      query: () => ({
        url: '/get-active-passes',
        method: 'GET',
      }),
    }),
    redeemPass: builder.mutation<{ message: string, transaction_id: string }, FormData>({
      query: data => ({
        url: '/redeem-pass',
        method: 'POST',
        body: data,
      }),
    }),
    redeemTransactionsDetail: builder.query<TransactionType, string | undefined>({
      query: transaction_id => ({
        url: `/transaction-details/${transaction_id}`,
        method: 'GET',
      }),
    }),
    userPassDetail: builder.query<ActivationPassType, string | undefined>({
      query: pass_id => ({
        url: `/pass/${pass_id}`,
        method: 'GET',
      }),
    }),
    getTransactionHistory: builder.query<void, void>({
      query: () => ({
        url: `/transactions-history`,
        method: 'GET',
      }),
    }),
    generatePassesAfterPayment: builder.mutation<PaymentIntentPostType, GeneratePassType>({
      query: data => ({
        url: '/generate-passes-after-payment',
        method: 'POST',
        body: data,
      }),
    }),
    confirmPaymentIntent: builder.mutation<void, GeneratePassByPIType>({
      query: data => ({
        url: '/generate-passes-by-payment-intent',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useGetTransactionHistoryQuery,
  useRegisterMutation,
  useLoginMutation,
  useLogoutMutation,
  useTestQuery,
  useForgotPasswordMutation,
  useMapLocationsListQuery,
  useLocationListMutation,
  useResetPasswordMutation,
  useLocationDetailsQuery,
  useUserPassQuery,
  useActivateUserPassMutation,
  useAddPassMutation,
  useLocationsListHomepageQuery,
  useActivePassesQuery,
  useRedeemPassMutation,
  useUserPassDetailQuery,
  useRedeemTransactionsDetailQuery,
  useGeneratePassesAfterPaymentMutation,
  useConfirmPaymentIntentMutation,
} = api;
