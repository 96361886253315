import React from 'react';
import { Grid, useMediaQuery, AppBar, Toolbar } from '@mui/material';
import { Strings } from '../constants/Strings';
import { Colors } from '../constants/Colors';
import { makeStyles } from 'tss-react/mui';
import LogoTitle from './LogoTitle';
import DrawerMenu from './DrawerMenu';
import BottomSheetScanVenue from './BottomSheetScanVenue';
import { ReactComponent as AntwerpLogoIcon } from '../assets/images/antwerpLogo.svg';

const HeaderMenu = () => {
  const { classes, cx } = useStyles();
  const isPhone = useMediaQuery('(max-width: 500px)');
  return (
    <>
      <Grid container xs={12} className={cx(classes.container)}>
        {
          !isPhone && <><AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <AntwerpLogoIcon />
          </Toolbar>
        </AppBar>
          </>
        }
        {
          isPhone && <>
        <Grid item xs={8}>
          <LogoTitle
            subtitle={Strings.headerSubTitle}
            iconClass={classes.logoTitleIconClass}
            titleClass={classes.logoTitleClass}
            subtitleClass={classes.logoTitleSubtitleClass}
          />
        </Grid>
        <Grid item xs={4} className={classes.scanButtonContainer}>
          <BottomSheetScanVenue />
          <DrawerMenu />
        </Grid>
        <div className={classes.divider} />
          </>
        }
      </Grid>
    </>
  );
};

export default HeaderMenu;

const useStyles = makeStyles()({
  appBar: {
    '.MuiToolbar-root': {
      margin: '0 65px',
      paddingBottom: 5,
      paddingTop: 5,
      svg: {
        width: 50,
        height: 50,
      }
    }
  },
  leftIcon: {
    width: 65,
    display: 'flex',
    '@media (max-width: 490px)': {
      width: 45,
    },
    '@media (max-width: 365px)': {
      width: 30,
    },
  },
  container: {
    backgroundColor: Colors.white,
    '.MuiAppBar-root': {
      backgroundColor: Colors.black,
    }
  },
  logoTitleClass: {
    color: Colors.black,
    lineHeight: '20px',
  },
  logoTitleSubtitleClass: {
    color: Colors.darkGrey,
  },
  logoTitleIconClass: {
    width: 70,
    height: 69,
    '@media (max-width: 327px)': {
      width: 55,
    },
    '@media (max-width: 312px)': {
      width: 45,
    },
  },
  scanTitle: {
    fontSize: 14,
    color: Colors.white,
    display: 'inline-block',
    verticalAlign: 'top',
  },
  hamburger: {
    float: 'right',
  },
  hamburgerIconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: -5,
  },
  scanButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: 20,
  },
  divider: {
    position: 'absolute',
    top: 66,
    right: 0,
    height: 1,
    width: window.innerWidth - 70,
    borderBottom: 'solid',
    borderColor: `${Colors.lowGrey}80`,
  },
});
