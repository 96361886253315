export const faqData = [
  {
    question: 'faq',
    items: [{
      question: 'How long can I use my AntwerpCity Pass?',
      answer: 'The duration of your City Pass (24, 48 or 72 hours) is stated on the card. \
      This period only starts once the City Pass is activated, not when you purchase it. \
      That means you can buy your City Pass on Friday evening and start using it Saturday morning.<br/><br/>\
      Please note that you must activate your AntwerpCity Pass within one year of the purchase date.'
    },{
      question: 'Is it possible to visit a site more than once?',
      answer: 'You can use your Pass to enter an attraction for free or at a discount once per site, and of\
      course only during opening hours. Please note that if it is less than half an hour until\
      closing time, you will not be allowed to enter. Also be sure to check whether an\
      attraction is closed on certain days.'
    },{
      question: 'Can I use my City Pass to take the underground tram?',
      answer: 'Yes. Your City Pass is valid for the full range of De Lijn’s public transport in Antwerp, so\
      you can use it in the bus, tram or underground .Your Antwerp City Pass is your ticket.\
      There is no need for you to scan or show anything to use De Lijn public transport, except\
      in the event of a ticket inspection.'
    },{
      question: 'Are museums in Antwerp open every day?',
      answer: 'No, most museums will be closed on certain days. Check the website of the museum you\
      wish to visit for the correct opening hours.'
    },{
      question: 'Can I get a refund if I never used my Antwerp City Pass?',
      answer: 'No, the Antwerp City Pass is non-refundable.'
    },{
      question: 'Can I extend the validity of my Antwerp City Pass?',
      answer: 'No, the duration of your City Pass cannot be extended.'
    },{
      question: 'Do I need to book museum visits in advance?',
      answer: 'Yes, you must go to the museum’s website to book a time slot. Select Antwerp City Pass\
      when you make your booking. Then scan the QR code when you visit the museum.'
    },]
  }
]