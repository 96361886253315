import { Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { Strings } from '../constants/Strings';
import { makeStyles } from 'tss-react/mui';
import PrimaryButton from './PrimaryButton';

interface AddPassBottomSheetProps {
  onMailClick: () => void;
  onScanClick: () => void;
}

const AddPassBottomSheet = ({ onMailClick, onScanClick }: AddPassBottomSheetProps) => {
  const { classes } = useStyles();
  const [showBottomSheet, setShowBottomSheet] = useState<boolean>(false);
  return (
    <>
      <div className={classes.addPassButton}>
        <PrimaryButton onClick={() => setShowBottomSheet(true)} />
      </div>
      <BottomSheet open={showBottomSheet} onDismiss={() => setShowBottomSheet(false)}>
        <div className={classes.bottomSheetContainer}>
          <Typography variant={'body2'}>{Strings.addPassMethod}</Typography>
          <div className={classes.buttonsContainer}>
            <Button className={classes.bottomSheetButton} variant={'contained'} onClick={onMailClick}>
              {Strings.viaEmail}
            </Button>
            <Button className={classes.bottomSheetButton} variant={'contained'} onClick={onScanClick}>
              {Strings.viaQr}
            </Button>
          </div>
        </div>
      </BottomSheet>
    </>
  );
};

export default AddPassBottomSheet;

const useStyles = makeStyles()({
  bottomSheetContainer: {
    marginTop: '40px',
    padding: '0px 16px 0px 16px',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '24px 0px 35px',
    justifyContent: 'space-between',
  },
  bottomSheetButton: {
    width: '45%',
  },
  addPassButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '25px',
  },
});
