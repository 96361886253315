import React, { useEffect } from 'react';

import { Typography, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import ActivationPassForm from '../components/ActivationPassForm';
import greenCheckMark from '../assets/images/24-approved-checked-outline-green.gif';
import Header from '../components/Header';

import { Strings } from '../constants/Strings';
import { Colors } from '../constants/Colors';
import { useNavigate, useParams } from 'react-router-dom';

import { useActivateUserPassMutation, useUserPassDetailQuery } from '../services/apiSlice';
import { generateFormData } from '../utils/helpers';
import CountDownValidityDate from '../components/CountDownValidityDate';
import { useDispatch } from 'react-redux';
import { authenticationActions } from '../services/authSlice';

const ActivationPassPage = () => {
  const [activatePass, { data: activatedPassData, isSuccess, isLoading, isError, error }] =
    useActivateUserPassMutation();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data: userPassCardData, isLoading: areUserPassDetailLoading } = useUserPassDetailQuery(id);

  const onButtonClick = () => {
    const passJSON = {
      pass_id: id,
    };
    const passFormData = generateFormData(passJSON);
    activatePass(passFormData);
  };
  //@ts-ignore
  const errors = error?.data;
  const errorMessage = (errors?.pass_id?.[0] as string) || (errors?.message as string) || Strings.errorMessage;

  useEffect(() => {
    if (isError) {
      dispatch(authenticationActions.setError({ error: errorMessage, isError }));
    }
  }, [isError]);

  const renderActivationText = () => {
    let buttonText = Strings.activate;
    if (isLoading) {
      buttonText = Strings.activating;
    }

    if (isSuccess) {
      buttonText = Strings.continue;
    }

    return buttonText;
  };

  return (
    <div className={classes.root}>
      <Header hasBackButton onBackClick={() => navigate('/home')} />
      <Typography className={classes.title}>{isSuccess ? Strings.passActive : Strings.passActivationTitle}</Typography>
      {isSuccess ? (
        <>
          <div className={classes.iconContainer}>
            <img src={greenCheckMark} alt={'check mark'} className={classes.greenCheckMark} />
          </div>
          <div className={classes.timeDescription}>
            <Typography className={classes.subtitle}>{Strings.passReadyToUse}</Typography>
            <Typography className={classes.subtitle}>
              {Strings.timeLeft}{' '}
              <CountDownValidityDate
                validity={activatedPassData?.validity}
                activation_date={activatedPassData?.activation_date}
                countDownClass={classes.countDownSuccessTimer}
              />
            </Typography>
          </div>
        </>
      ) : (
        <ActivationPassForm isLoading={areUserPassDetailLoading} card={userPassCardData} />
      )}

      <Button
        className={classes.addPassButton}
        variant="contained"
        disabled={isLoading}
        onClick={!isSuccess ? onButtonClick : () => navigate('/home')}
      >
        {renderActivationText()}
      </Button>
    </div>
  );
};

export default ActivationPassPage;

const useStyles = makeStyles()({
  countDownSuccessTimer: {
    color: Colors.black,
    fontSize: 15,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 20,
  },
  successContainer: {
    textAlign: 'center',
    minHeight: '60vh',
  },
  title: {
    color: Colors.tourismBlue,
    textAlign: 'center',
    fontSize: 25,
    fontWeight: '700',
    marginTop: '50px',
  },
  addPassButton: {
    width: '85vw',
    borderRadius: '0px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '30px',
  },
  greenCheckMark: {
    width: '30vw',
    margin: '45px 0',
  },
  subtitle: {
    fontSize: 15,
    marginBottom: 10,
  },
  timeDescription: {
    textAlign: 'center',
    width: 170,
    marginBottom: 40,
  },
  iconContainer: {
    minHeight: '45vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '@media (min-height: 740px)': {
      minHeight: '50vh',
    },
    '@media (min-height: 844px) and (max-height: 896px)': {
      minHeight: '55vh',
    },
    '@media (min-height: 915px)': {
      minHeight: '60vh',
    },
    '@media (min-height: 1024px)': {
      minHeight: '65vh',
    },
  },
});
