import React, { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { List, ListItem, Typography, Grid, CircularProgress } from '@mui/material';
import BackHeader from '../components/BackHeader';
import { Strings } from '../constants/Strings';
import { Colors } from '../constants/Colors';
import { useNavigate } from 'react-router-dom';
import { useGetTransactionHistoryQuery } from '../services/apiSlice';
import { TransactionHistoryType } from '../types/transactionType';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { authenticationActions } from '../services/authSlice';

const HistoryPage = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data, isLoading, isError, error, refetch } = useGetTransactionHistoryQuery();

  //@ts-ignore
  const errors = error?.data;
  const errorMessage = (errors?.error as string) || (errors?.message as string) || Strings.errorMessage;

  useEffect(() => {
    if (isError) {
      dispatch(authenticationActions.setError({ isError, error: errorMessage }));
    }
  }, [isError]);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <BackHeader
        onBackPress={() => navigate(-1)}
        title={Strings.history}
        backgroundColor={Colors.white}
        contentColor={Colors.tourismBlue}
      />
      <div className={classes.historyContainer}>
        <List className={classes.listItemParent}>
          {isLoading && (
            <div className={classes.alignCenterLoadingBar}>
              <CircularProgress />
            </div>
          )}
          {Object.keys(data || {}).length === 0 && !isLoading && (
            <div className={classes.empty}>{Strings.noRecord}</div>
          )}
          {Object.keys(data || {}).length !== 0 &&
            Object.keys(data || {}).map((date: string, idx: number) => {
              const item: TransactionHistoryType[] = data?.[date] || [];
              return (
                <ListItem key={idx}>
                  <Grid container>
                    <Grid item xs={12} className={classes.date}>
                      <Typography>
                        {moment(date).calendar({
                          sameDay: '[ ]',
                          nextDay: `[${Strings.tomorrow}]`,
                          nextWeek: 'dddd',
                          lastDay: `[${Strings.yesterday}]`,
                          lastWeek: `[${Strings.last}] dddd`,
                          sameElse: 'DD/MM/YYYY',
                        })}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                      <List className={classes.listItemTrx}>
                        {!!item.length &&
                          item.map((trx: TransactionHistoryType, index: number) => (
                            <ListItem key={index}>
                              <Grid container>
                                <Grid item xs={12} className={classes.locationName}>
                                  <Typography>{trx.location_name}</Typography>
                                </Grid>
                                <Grid item container xs={12} className={classes.trxDate}>
                                  <Grid item xs={7}>
                                    <Typography>{trx.pass_name}</Typography>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography>
                                      {moment(new Date(trx?.creation_date), 'YYYY-MM-DD hh:mm:ss').format(
                                        'hh:mm a, MM-DD-YYYY'
                                      )}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </ListItem>
                          ))}
                      </List>
                    </Grid>
                  </Grid>
                </ListItem>
              );
            })}
        </List>
      </div>
    </>
  );
};

export default HistoryPage;

const useStyles = makeStyles()({
  empty: {
    borderTop: `1px solid ${Colors.grayDa}`,
    textAlign: 'center',
    paddingTop: 30,
  },
  listItemParent: {
    '.MuiListItem-root': {
      padding: 0,
    },
  },
  listItemTrx: {
    '.MuiListItem-root': {
      padding: '15px 15px 15px 20px',
      borderBottom: `1px solid ${Colors.grayDa}`,
    },
  },
  alignCenterLoadingBar: {
    textAlign: 'center',
  },
  historyContainer: {
    marginBottom: 30,
  },
  date: {
    borderBottom: `1px solid ${Colors.grayDa}`,
    borderTop: `1px solid ${Colors.grayDa}`,
    padding: '25px 15px 10px 20px',
    p: {
      fontSize: 20,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  locationName: {
    padding: 0,
    color: Colors.black,
    p: {
      fontSize: 17,
      whiteSpace: 'nowrap',
      lineHeight: '35px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  trxDate: {
    '.MuiGrid-item': {
      display: 'flex',
      alignItems: 'center',
      p: {
        fontSize: 15,
      },
      ':last-of-type': {
        justifyContent: 'flex-end',
        p: {
          fontSize: 12,
        },
      },
    },
  },
  content: {
    '.MuiList-root': {
      padding: 0,
    },
  },
});
