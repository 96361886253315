import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Colors } from '../constants/Colors';
import LogoTitleWithBack from '../components/LogoTitleWithBack';
import { legalData as mockLegalData } from '../mocks/legalData';

const LegalPage = () => {
  const { classes } = useStyles();
  return (
    <>
      <LogoTitleWithBack />
      <div className={classes.legalContent}>
        <ul className={classes.itemContainer}>
          {mockLegalData.map((e, idx) => (
            <li key={idx} className={classes.item}>
              {e}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default LegalPage;

const useStyles = makeStyles()({
  container: {
    padding: '0 20px',
    marginTop: 20,
  },
  colorTourismRed: {
    backgroundColor: `${Colors.lightRed} !important`,
  },
  legalContent: {
    backgroundColor: Colors.lightGrey,
    padding: '40px 20px',
    marginTop: 70,
    color: Colors.black,
    height: '60vh',
  },
  itemContainer: {
    margin: 0,
    paddingLeft: 20,
  },
  item: {
    display: 'list-item',
    textAlign: 'left',
    marginBottom: 15,
  },
});
