import React, { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Colors } from '../constants/Colors';
import { Typography, Grid, Box, List, ListItem, ListItemButton, ListItemText, Button } from '@mui/material';
import { ProfileMenuList, LinkProps } from '../constants/Menu';
import PassCard from '../components/PassCard';
import { Strings } from '../constants/Strings';
import packageInfo from '../../package.json';
import { Link, useNavigate } from 'react-router-dom';
import { generateFormData } from '../utils/helpers';
import { useLogoutMutation } from '../services/apiSlice';
import { authenticationActions, selectToken } from '../services/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useUserPassQuery } from '../services/apiSlice';
import PassCardSkeleton from '../components/PassCardSkeleton';
import { passActions } from '../services/passSlice';

const GeneralPage = () => {
  const { classes } = useStyles();
  const [logout, { isSuccess, isLoading, isError, error }] = useLogoutMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const {
    data: userPassesData,
    isLoading: userPassesLoading,
    error: userPassesError,
    isError: isErrorUserPasses,
  } = useUserPassQuery();

  useEffect(() => {
    if (isSuccess) {
      removeToken();
    }
  }, [isSuccess]);

  //@ts-ignore
  const errorData = error?.data || userPassesError?.data;
  const errorMessage =
    (errorData?.errors?.token[0] as string) ||
    (errorData?.error as string) ||
    (errorData as string) ||
    Strings.errorMessage;

  useEffect(() => {
    if (isError || isErrorUserPasses) {
      //@ts-ignore
      dispatch(authenticationActions.setError({ error: errorMessage, isError: isError || isErrorUserPasses }));
    }
  }, [isError, isErrorUserPasses]);

  const removeToken = () => {
    localStorage.removeItem('authToken');
    dispatch(authenticationActions.resetToken());
    dispatch(passActions.resetActivePasses());
    navigate('/');
  };

  const onLogoutPress = () => {
    const tokenFormData = generateFormData({ token: token });
    logout(tokenFormData);
  };

  return (
    <>
      <div className={classes.passCardContainer}>
        {userPassesLoading ? (
          <PassCardSkeleton isGeneralPage />
        ) : (
          <PassCard data={userPassesData} isEmpty={!userPassesData?.length} noHeader={true} />
        )}
      </div>
      <Box>
        <List>
          {ProfileMenuList.map(({ text, link }: LinkProps, index) => (
            <Link to={link} key={index} className={classes.linkContainer}>
              <ListItem disablePadding className={classes.containerList}>
                <ListItemButton className={classes.listItemButton}>
                  <ListItemText primary={text} classes={{ primary: index === 0 ? classes.fontBold : '' }} />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>
      </Box>
      <Grid container xs={12}>
        <Grid item xs={12} className={classes.logoutButtonContainer}>
          <Button variant="outlined" disabled={isLoading} className={classes.logoutButton} onClick={onLogoutPress}>
            <Typography>{Strings.logout}</Typography>
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.versionContainer}>
          <Typography className={classes.versionText}>
            {Strings.version} {packageInfo.version}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default GeneralPage;

const useStyles = makeStyles()({
  versionContainer: {
    padding: '20px 25px 0px',
    marginBottom: 30,
  },
  versionText: {
    color: Colors.greyVersion,
    textTransform: 'capitalize',
  },
  logoutButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '50px auto 35px',
  },
  logoutButton: {
    borderColor: Colors.tourismBlue,
    borderWidth: 1,
    color: Colors.tourismBlue,
    width: '90vw',
    borderRadius: 0,
    ':hover': {
      borderColor: Colors.tourismBlue,
    },
  },
  colorTourismRed: {
    backgroundColor: `${Colors.lightRed} !important`,
  },
  containerList: {
    borderBottom: `1px solid ${Colors.lightGrey}`,
  },
  fontBold: {
    fontWeight: 'bold',
  },
  listItemButton: {
    padding: '10px 20px',
  },
  textCenter: {
    textAlign: 'center',
  },
  passCardContainer: {
    marginTop: 30,
  },
  linkContainer: {
    textDecoration: 'none',
    color: Colors.black,
  },
});
