import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Colors } from '../constants/Colors';
import { Typography } from '@mui/material';
import { Strings } from '../constants/Strings';

interface ThingsToDoDetailsProps {
  title: string | undefined;
  subtitle: string | undefined;
  type: string | undefined;
  imageProp: string | undefined;
  children: React.ReactNode;
}

const ThingsToDoDetails = ({ title, subtitle, type, imageProp, children }: ThingsToDoDetailsProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <img className={classes.image} src={imageProp} alt="fomu" />
        <div className={classes.titleContainer}>
          <Typography className={classes.title}>{title}</Typography>
          <div className={classes.subTitleContainer}>
            <Typography className={classes.subTitle}>{subtitle}</Typography>
            <Typography className={classes.type}> {type}</Typography>
          </div>
        </div>
      </div>
      <div className={classes.content}>{children}</div>
      <div className={classes.footer}>{Strings.footerThingsToDoDetails}</div>
    </div>
  );
};

export default ThingsToDoDetails;

const useStyles = makeStyles()({
  container: {
    width: '90vw',
    backgroundColor: Colors.white,
    border: `1px solid ${Colors.grayB3}`,
    boxShadow: `4px 4px 0px ${Colors.lightGrey}`,
    margin: 'auto',
    marginTop: '15px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px',
  },
  titleContainer: {
    marginLeft: '8px',
    width: '100%',
  },
  title: {
    fontWeight: '600',
    fontSize: '16px',
  },
  subTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  subTitle: {
    fontWeight: '400',
    fontSize: '14px',
    color: Colors.grayB3,
  },
  type: {
    fontSize: '12px',
    color: Colors.darkGrey,
  },
  content: {
    backgroundColor: Colors.greenDark,
    padding: '10px',
    height: '275px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  footer: {
    padding: '20px',
    fontSize: '12px',
    lineHeight: '17px',
  },
  image: {
    width: '52px',
    height: '52px',
    objectFit: 'cover',
  },
});
