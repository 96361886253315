import React, { useState } from 'react';
import PassCardEmpty from './PassCardEmpty';
import { UserPassType } from '../types/userPassType';
import PassCardInactive from './PassCardInactive';
import PassCardActive from './PassCardActive';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import PassCardHeader from './PassCardHeader';
import { hasExpiredValidity } from '../utils/helpers';

interface PassCardProps {
  isEmpty: boolean;
  data: UserPassType[];
  noHeader?: boolean;
}

const PassCard = ({ isEmpty, data, noHeader }: PassCardProps) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const onSlideChange = (index: number) => {
    setActiveIndex(index);
  };
  return (
    <>
      {!isEmpty ? (
        <>
          {!noHeader && <PassCardHeader index={activeIndex} length={data?.length || 0} data={data[activeIndex]} />}
          <Carousel
            infiniteLoop
            onChange={onSlideChange}
            showIndicators={false}
            showThumbs={false}
            showStatus={false}
            showArrows={false}
          >
            {data?.map((item, index) =>
              !item.activation_date || hasExpiredValidity(item.activation_date, item?.validity) ? (
                <PassCardInactive card={item} key={index} />
              ) : (
                <PassCardActive card={item} key={index} />
              )
            )}
          </Carousel>
        </>
      ) : (
        <PassCardEmpty />
      )}
    </>
  );
};

PassCard.defaultProps = {
  isEmpty: false,
  data: [],
};

export default PassCard;
