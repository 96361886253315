import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { authenticationActions, getError, getIsError, getIsSuccess, getSuccess } from '../services/authSlice';

const ToastMessage = () => {
  const error = useSelector(getError);
  const isError = useSelector(getIsError);
  const success = useSelector(getSuccess);
  const isSuccess = useSelector(getIsSuccess);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isError) {
      toast.error(error, {
        onClose: () => {
          dispatch(authenticationActions.resetError());
        },
      });
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(success, {
        onClose: () => {
          dispatch(authenticationActions.resetSuccess());
        },
      });
    }
  }, [isSuccess]);

  return (
    <ToastContainer
      position="top-center"
      autoClose={10000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
  );
};

export default ToastMessage;
