import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './store';
import { BrowserRouter } from 'react-router-dom';
import { RootRouter } from './routers/RootRouter';
import { theme } from './themes/theme';
import { ThemeProvider } from '@mui/material';
import ToastMessage from './components/ToastMessage';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <RootRouter />
        <ToastMessage />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>
);

reportWebVitals();
