import React from 'react';
import { ReactComponent as AntwerpLogoIcon } from '../assets/images/antwerpLogo.svg';
import { Typography, Grid } from '@mui/material';
import { Strings } from '../constants/Strings';
import { makeStyles } from 'tss-react/mui';
import { Colors } from '../constants/Colors';

interface LogoTitleProps {
  hasSubtitle: boolean;
  containerClass?: string;
  iconClass?: string;
  titleContainerClass?: string;
  titleClass?: string;
  subtitle?: string;
  subtitleClass?: string;
}

const LogoTitle = ({
  hasSubtitle,
  containerClass,
  iconClass,
  titleClass,
  subtitle,
  subtitleClass,
  titleContainerClass,
}: LogoTitleProps) => {
  const { classes, cx } = useStyles();
  return (
    <Grid container xs={12} className={containerClass}>
      <AntwerpLogoIcon className={cx(classes.leftIcon, iconClass)} />
      <div className={cx(classes.titleContainer, titleContainerClass)}>
        <Typography className={cx(classes.leftTitle, titleClass)}>{Strings.headerTitle}</Typography>
        {hasSubtitle && <Typography className={cx(classes.leftSubTitle, subtitleClass)}>{subtitle}</Typography>}
      </div>
    </Grid>
  );
};

LogoTitle.defaultProps = {
  hasSubtitle: true,
  subtitle: Strings.headerSubTitleUnlock,
  isWhiteHeaderIcon: false,
};

export default LogoTitle;

const useStyles = makeStyles()({
  titleContainer: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingLeft: 10,
  },
  leftIcon: {
    width: 65,
    display: 'flex',
    '@media (max-width: 490px)': {
      width: 45,
    },
    '@media (max-width: 365px)': {
      width: 30,
    },
  },
  leftTitle: {
    color: Colors.grayB3,
    fontWeight: 'bold',
    fontSize: 17,
    lineHeight: '5px',
    '@media (max-width: 450px)': {
      fontSize: 15,
    },
    '@media (max-width: 370px)': {
      fontSize: 13,
    },
  },
  leftSubTitle: {
    color: Colors.grayB3,
    fontSize: 12,
  },
});
