import { createTheme } from '@mui/material';
import { Colors } from '../constants/Colors';

export const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        textColorPrimary: Colors.tourismBlue,
        root: {
          margin: '0px 20px',
          textTransform: 'none',
          fontSize: '16px',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: `solid 1px ${Colors.greyBorder}`,
        },
        indicator: {
          backgroundColor: Colors.tourismBlue,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: Colors.blue,
          borderRadius: 0,
          textTransform: 'none',
          height: '46px',
        },
        outlined: {
          borderColor: Colors.tourismBlue,
          borderRadius: 30,
          border: 'solid',
          textTransform: 'none',
          color: Colors.tourismBlue,
          height: '36px',
          ':hover': {
            borderColor: Colors.tourismBlue,
          },
        },
        text: {
          color: Colors.white,
          textTransform: 'none',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            color: Colors.black,
            fontSize: '12px',
          },
          fontSize: '12px',
          padding: '5px 8px 5px 8px',
          height: '30px',
        },
        multiline: {
          padding: '5px 8px 5px 8px',
        },
      },
    },
  },
  typography: {
    body1: {
      fontSize: 16,
      fontWeight: '400',
    },
    body2: {
      fontSize: 14,
      fontWeight: '400',
    },
  },
});
