import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Colors } from '../constants/Colors';
import { Typography } from '@mui/material';
import { Strings } from '../constants/Strings';
import { ReactComponent as HeaderLogo } from '../assets/images/headerLogo.svg';
import { ReactComponent as BackIcon } from '../assets/images/backIcon.svg';

interface HeaderProps {
  hasBackButton?: boolean;
  onBackClick?: () => void;
  containerClass?: string;
  noLogo?: boolean;
}

const Header = ({ onBackClick, hasBackButton, containerClass, noLogo }: HeaderProps) => {
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.container, containerClass)}>
      {hasBackButton && <BackIcon className={classes.backIcon} onClick={onBackClick} fill={Colors.tourismBlue} />}
      {!noLogo && <HeaderLogo />}
      <Typography className={classes.title}>{Strings.headerTitle}</Typography>
    </div>
  );
};

export default Header;

const useStyles = makeStyles()({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '56px',
    backgroundColor: Colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  title: {
    fontWeight: 700,
    fontSize: 20,
    marginLeft: 5,
  },
  backIcon: {
    position: 'absolute',
    left: '24px',
    width: '20px',
  },
});
