import React, { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { List, CircularProgress, ListItem } from '@mui/material';
import BackHeader from '../components/BackHeader';
import { Strings } from '../constants/Strings';
import { Colors } from '../constants/Colors';
import { useNavigate } from 'react-router-dom';
import { useUserPassQuery } from '../services/apiSlice';
import { useDispatch } from 'react-redux';
import { authenticationActions } from '../services/authSlice';
import PassCardInactive from '../components/PassCardInactive';
import PassCardActive from '../components/PassCardActive';
import { hasExpiredValidity } from '../utils/helpers';
import { UserPassType } from '../types/userPassType';

const HistoryPass = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data, isLoading, isError, error, refetch } = useUserPassQuery();

  //@ts-ignore
  const errors = error?.data;
  const errorMessage = (errors?.error as string) || (errors?.message as string) || Strings.errorMessage;

  useEffect(() => {
    if (isError) {
      dispatch(authenticationActions.setError({ isError, error: errorMessage }));
    }
  }, [isError]);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <BackHeader
        onBackPress={() => navigate(-1)}
        title={Strings.historyPass}
        backgroundColor={Colors.white}
        contentColor={Colors.tourismBlue}
      />
      <div className={classes.historyContainer}>
        <List className={classes.listItemParent}>
          {isLoading && (
            <div className={classes.alignCenterLoadingBar}>
              <CircularProgress />
            </div>
          )}

          {!!(data || []).length &&
            data?.map((card: UserPassType, index: number) =>
              !card.activation_date || hasExpiredValidity(card.activation_date, card?.validity) ? (
                <ListItem key={index}>
                  <PassCardInactive card={card} />
                </ListItem>
              ) : (
                <ListItem key={index}>
                  <PassCardActive card={card} />
                </ListItem>
              )
            )}
        </List>
      </div>
    </>
  );
};

export default HistoryPass;

const useStyles = makeStyles()({
  empty: {
    borderTop: `1px solid ${Colors.grayDa}`,
    textAlign: 'center',
    paddingTop: 30,
  },
  listItemParent: {
    '.MuiListItem-root': {
      marginBottom: 20,
      padding: 0,
    },
  },
  alignCenterLoadingBar: {
    textAlign: 'center',
  },
  historyContainer: {
    marginBottom: 30,
  },
  content: {
    '.MuiList-root': {
      padding: 0,
    },
  },
});
