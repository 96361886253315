import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectToken } from '../services/authSlice';

const PrivateRoutesNoHeader = () => {
  const token = useSelector(selectToken);

  return token ? <Outlet /> : <Navigate to="/" replace />;
};
export default PrivateRoutesNoHeader;
