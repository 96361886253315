import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Colors } from '../constants/Colors';
import LogoTitleWithBack from '../components/LogoTitleWithBack';
import { aboutData, aboutWhatisAntwerpData } from '../mocks/aboutData';
import { Strings } from '../constants/Strings';
import packageInfo from '../../package.json';
import { Typography, Grid } from '@mui/material';
import { ReactComponent as LinkedinIcon } from '../assets/images/LinkedinIcon.svg';
import { ReactComponent as InstagramIcon } from '../assets/images/InstagramIcon.svg';
import { ReactComponent as FacebookIcon } from '../assets/images/facebookIcon.svg';
import AboutListItem from '../components/AboutLisItem';
import { usefulLinks } from '../constants/UsefulLinks';

const AboutPage = () => {
  const { classes, cx } = useStyles();
  return (
    <>
      <LogoTitleWithBack title={Strings.about} />
      <div className={classes.content}>
        <div className={classes.whatIsAntwerpContainer}>
          <div className={classes.whatIsAntwerpTitle}>{Strings.whatIsAntwerp}</div>
          <Typography className={classes.whatIsAntwerpContent}>{Strings.theAntwerpIs}</Typography>
        </div>

        <ul className={classes.itemContainer}>
          {aboutWhatisAntwerpData.map((e, idx) => (
            <li key={idx} className={classes.item}>
              {e}
            </li>
          ))}
        </ul>
        {aboutData.map((data, index) => (
          <AboutListItem {...data} key={index} />
        ))}

        <Grid container className={classes.footerContainer}>
          <Typography className={classes.whatIsAntwerpTitle}>{Strings.unboxUniverse}</Typography>
          <Typography className={classes.whatIsAntwerpContent}>{Strings.aboutFooterDescription}</Typography>
          <Grid item xs={12} className={classes.iconContainer}>
            <Grid item xs={6}>
              <a href={usefulLinks.linkedin} target="_blank" rel="noreferrer">
                <LinkedinIcon className={classes.socialIcon} />
              </a>
              <a href={usefulLinks.facebook} target="_blank" rel="noreferrer">
                <FacebookIcon className={cx(classes.socialIcon, classes.fbIcon)} />
              </a>
              <a href={usefulLinks.instagram} target="_blank" rel="noreferrer">
                <InstagramIcon className={classes.socialIcon} />
              </a>
            </Grid>
          </Grid>
          <a href={usefulLinks.visitAntwerpHomeLink} target="_blank" rel="noreferrer">
            <Typography className={classes.linkVisit}>{Strings.visitwantwerpenLink}</Typography>
          </a>

          <Grid item xs={12} className={classes.versionContainer}>
            <Typography className={classes.versionText}>
              {Strings.version} {packageInfo.version}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default AboutPage;

const useStyles = makeStyles()({
  iconContainer: {
    margin: '20px 0',
  },
  socialIcon: {
    width: 25,
    height: 25,
    marginRight: 15,
    '@media (max-width: 340px)': {
      width: 15,
      height: 15,
    },
  },
  fbIcon: {
    marginLeft: -5,
  },
  linkVisit: {
    textDecoration: 'underline',
    color: Colors.black,
  },
  footerContainer: {
    paddingLeft: 10,
  },
  whatIsAntwerpContainer: {
    paddingTop: 10,
    paddingLeft: 15,
  },
  whatIsAntwerpTitle: {
    color: Colors.tourismBlue,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  whatIsAntwerpContent: {
    color: Colors.black,
  },
  versionContainer: {
    paddingLeft: 10,
    marginBottom: 30,
    marginTop: 30,
  },
  versionText: {
    color: Colors.greyVersion,
    textTransform: 'capitalize',
  },
  container: {
    padding: '0 20px',
    marginTop: 20,
  },
  colorTourismRed: {
    backgroundColor: `${Colors.lightRed} !important`,
  },
  content: {
    backgroundColor: Colors.white,
    padding: 20,
    color: Colors.black,
  },
  itemContainer: {
    paddingLeft: 15,
    marginTop: 10,
  },
  item: {
    display: 'list-item',
    textAlign: 'left',
    marginBottom: 15,
  },
});
