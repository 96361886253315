import React from 'react';
import { Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import BreadCrumbStep from "./BreadcrumbStep";
import { Colors } from '../constants/Colors';

interface BreadCrumbsPropsType {
  items: string[];
  active: number;
  containerStyle?: string;
  size?: number;
  lineSize?: number;
}
const BreadCrumbs = ({
  items,
  active,
  containerStyle,
  size,
  lineSize,
}: BreadCrumbsPropsType) => {
  const { classes, cx } = useStyles();
  return (
    <Grid container className={cx(classes.breadCrumbContainer, containerStyle)}>
      {
        items.map((page: string, index: number) => (
          <React.Fragment key={index}>
            <Grid item xs={size ? size : 2}>
              <BreadCrumbStep step={index+1} title={page} disabled={active < index}/>
            </Grid>
            {
              index %1 === 0 && index !== items.length-1 &&
              <Grid item xs={lineSize ? lineSize : 3}>
                <div className={classes.divider}/>
              </Grid>
            }
          </React.Fragment>
        ))
      }
  </Grid>
  )
}
export default BreadCrumbs;
const useStyles = makeStyles()({
  breadCrumbContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '75%',
    margin: '0 auto',
    paddingTop: 40,
    '.MuiGrid-item': {
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
      ':nth-child(even)': {
        height: 70,
        padding: '0 15px',
      }
    },

    // if the screen mobile
    '@media (max-width: 600px)': {
      display: 'none',
    }
  },
  divider: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 1,
    width: window.innerWidth - 70,
    borderBottom: 'solid',
    borderColor: `${Colors.lowGrey}80`,
  },
});